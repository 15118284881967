import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import LanguageSwitcher from '../../LanguageSwitcher';
import { toggleNavCollapsed } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import SearchBar from '../../SearchBar';
import useStyles from './AppHeader.style';
import HeaderMessages from '../../HeaderMessages';
import Notifications from '../../Notifications';
import AppLogo from '../../../../shared/components/AppLogo';
import clsx from 'clsx';
import { onJWTAuthSignout } from '../../../../redux/actions/JWTAuth';
import { useAuthUser } from '@crema/utility/AppHooks';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@crema';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import { downProfileFile } from 'pages/mainPages/FreightForwarder/Admin/Quotation/redux/action';
import { Button, Popover } from '@material-ui/core';

const AppHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { themeMode } = useContext(AppContext);
  const { profile } = useSelector((state) => state.auth);
  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);
  const openDrawer = useSelector(({ settings }) => settings.openDrawer);

  const user = useAuthUser();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  useEffect(() => {
    dispatch(downProfileFile(profile?.imageUrl));
    setProfileImageUrl(profile?.imageUrl);
  }, [profile?.imageUrl]);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const [logoutMenuOpen, setLogoutMenuOpen] = React.useState(null);
  const isLogoutMenuOpen = Boolean(logoutMenuOpen);
  function handleLogoutMenuOpen(event) {
    setLogoutMenuOpen(event.currentTarget);
  }

  function handleLogoutMenuClose() {
    setLogoutMenuOpen(null);
  }

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };

  const getUserAvatar = () => {
    if (profile?.firstName) {
      return profile?.firstName.trim().charAt(0).toUpperCase();
    }
    if (profile?.email) {
      return profile?.email.charAt(0).toUpperCase();
    }
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className={classes.menuItemRoot}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: '500',
            fontSize: '16px',
            marginRight: '10px',
            marginLeft: '14px',
            height: 70,
          }}
        >
          {profileImageUrl ? (
            <Avatar className={classes.profilePicMob} src={profileImageUrl} />
          ) : (
            <Avatar className={classes.profilePicMob}>{getUserAvatar()}</Avatar>
          )}
          {`${profile?.firstName} ${profile?.lastName}`}
          <Box
            ml={3}
            className={classes.pointer}
            color={themeMode === 'light' ? '#313541' : 'white'}
          >
            <ExpandMoreIcon onClick={handleClick} />
            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => history.push('/freight-forwarder/admin/profile')}>
                My account
              </MenuItem>
            </Menu> */}
          </Box>
        </Box>
      </MenuItem>

      {/* <MenuItem className={classes.menuItemRoot}>
        <HeaderMessages />
      </MenuItem> */}
      <MenuItem className={classes.menuItemRoot}>
        <Notifications />
      </MenuItem>
      {/* <LanguageSwitcher /> */}
      <MenuItem className={classes.menuItemRoot} style={{ cursor: 'pointer' }} onClick={() => dispatch(onJWTAuthSignout(history))}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: '500',
            fontSize: '16px',
            height: 70,
            marginRight: '10px',
            marginLeft: '8px',
          }}
        // onClick={() => dispatch(onJWTAuthSignout(history))}
        >
          <IconButton>
            <ExitToAppIcon size="small"
            // onClick={() => dispatch(onJWTAuthSignout(history))}
            />
          </IconButton>
          <Box style={{ marginLeft: '5px' }}
          // onClick={() => dispatch(onJWTAuthSignout(history))}
          >
            <span
              style={{
                color: '#424242',
                fontSize: '16px',
                fontFamily: 'Poppins',
                fontWeight: 400,
              }}
            >
              Logout
            </span>
          </Box>
        </Box>
      </MenuItem>
    </Menu>
  );

  //----- Desktop Logout Menu -----//
  const renderLogoutMenu = (
    <Menu
      anchorEl={logoutMenuOpen}
      //anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      //id={mobileMenuId}
      keepMounted
      //transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isLogoutMenuOpen}
      onClose={handleLogoutMenuClose}
      style={{ top: '55px', right: '10px' }}
    >
      <MenuItem className={classes.menuItemRoot}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: '500',
            fontSize: '16px',
            height: 35,
            marginRight: '15px',
          }}
          onClick={() => dispatch(onJWTAuthSignout(history))}
        >
          <IconButton>
            <ExitToAppIcon size="small" onClick={() => dispatch(onJWTAuthSignout(history))} />
          </IconButton>
          <Box style={{ marginLeft: '5px' }} onClick={() => dispatch(onJWTAuthSignout(history))}>
            <span
              style={{
                color: '#424242',
                fontSize: '16px',
                fontFamily: 'Poppins',
                fontWeight: 400,
              }}
            >
              Logout
            </span>
          </Box>
        </Box>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar color="inherit" className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          {/*----- Toggle Drawer Icon -----*/}
          <IconButton
            style={{ marginLeft: '-20px' }}
            color="inherit"
            aria-label="open drawer"
            onClick={() => handleToggleDrawer()}
            edge="start"
            className={clsx(classes.menuButton, navCollapsed && classes.hide)}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>

          {/*----- App Logo -----*/}
          <AppLogo />

          <Box className={classes.grow} />
          {/* <SearchBar borderLight placeholder="Search…" /> */}

          <Box className={classes.sectionDesktop}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                fontWeight: '500',
                fontSize: '16px',
                // marginRight: '10px',
                // justifyContent: 'space-between',
                marginTop: '18px',
                marginBottom: '5px',
                maxWidth: '100px',
                maxHeight: '35px',
                minWidth: '100px',
                minHeight: '35px',
              }}
            >
              Beta 2.0.1
            </Box>
            {/*----- Feedback Button -----*/}
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                fontWeight: '500',
                fontSize: '10px',
                marginRight: '10px',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              <Button
                style={{
                  maxWidth: '200px',
                  maxHeight: '40px',
                  minWidth: '140px',
                  minHeight: '40px',
                  textTransform: 'none',
                  color: '#FFFFFF',
                  fontSize: '16px',
                  backgroundColor: '#0C6AC9',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  if (profile?.role === 'Client (Consignee/Shipper)') {
                    history.push('/shipper/addfeedback');
                  } else if (profile?.role === 'Client (Freight Forwarder)') {
                    history.push('/freight-forwarder/Client/addfeedback');
                  } else if (profile?.role === 'FF Admin') {
                    history.push('/freight-forwarder/admin/addfeedback');
                  } else if (profile?.role === 'FF Agent') {
                    history.push('/freight-forwarder/agent/addfeedback');
                  } else if (profile?.role === 'Super Admin') {
                    history.push('/super-admin/feedback/view');
                  }
                  else if (profile?.role === 'Airline Admin') {
                    history.push('/airline-admin/feedback/addfeedback');
                  }
                  else if (profile?.role === 'Airline Agent') {
                    history.push('/airline-agent/feedback/addfeedback');
                  }
                }}
                variant="contained"
                className={classes.createButton}
              >
                <img style={{ marginRight: '10px' }} src='/assets/images/sideMenu/header-feedback.png' />
                Feedback
              </Button>
            </Box>

            {/*----- Notifications Icon Button -----*/}
            <Notifications />

            {/*----- Profile -----*/}
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                fontWeight: '500',
                fontSize: '16px',
                marginRight: '10px',
              }}
            >
              {profileImageUrl ? (
                <Avatar className={classes.profilePic} src={profileImageUrl} />
              ) : (
                <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
              )}
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <p style={{ color: '#1B1D1F', fontSize: '16px', fontWeight: '500', margin: 0 }}>{`${profile?.firstName || ''} ${profile?.lastName || ''
                    }`}</p>
                </Box>
                <Box style={{ color: '#858A8C', fontSize: '14px', fontWeight: '400' }}>
                  <p style={{ margin: 0 }}>{`${profile?.companyName || ''}`}</p>
                </Box>
                <Box style={{ color: '#858A8C', fontSize: '14px', fontWeight: '400' }}>
                  <p style={{ margin: 0 }}>{`${profile?.role || ''}`}</p>
                </Box>
              </Box>

              <Box
                ml={3}
                className={classes.pointer}
                color={themeMode === 'light' ? '#313541' : 'white'}
              >
              </Box>
            </Box>

            {/*----- Desktop Logout -----*/}
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                fontWeight: '500',
                fontSize: '16px',
              }}
              // onClick={handleLogoutMenuOpen}
              onClick={() => dispatch(onJWTAuthSignout(history))}
            >
              <Tooltip title="Logout" arrow>
                <IconButton className={clsx(classes.notiBtn, 'notiBtn')}>
                  <ExitToAppIcon size="small" onClick={() => dispatch(onJWTAuthSignout(history))} />
                  {/* <KeyboardArrowDownIcon size="small" className={clsx(classes.Icon, 'Icon')} /> */}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/*----- Mobile Logout -----*/}
          <Box className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar >
      {renderMobileMenu}
      {renderLogoutMenu}
    </>
  );
};
export default AppHeader;
