import React, { lazy } from 'react';

export const airlineAgentRouteConfig = [
  {
    auth: ['ROLE_AIRLINE_AGENT'],
    routes: [
      // MY PROFILE ROUTES
      {
        path: '/airline/agent/profile/update',
        component: lazy(() => import('pages/mainPages/Airline/Agent/Profile/UserProfile/UserProfile.js')),
      },
      // HISTORY
      {
        path: '/airline/agent/history/list',
        component: lazy(() => import('pages/mainPages/Airline/Agent/History/HistoryList')),
      },
      {
        path: '/airline/agent/history/view/:airrateID',
        component: lazy(() => import('pages/mainPages/Airline/Agent/SpotRate/Update/historyViewIndex')),
      },
      //General Rate
      {
        path: '/airline/agent/general-rate/create',
        component: lazy(() => import('pages/mainPages/Airline/Agent/GeneralRate/Create')),
      },
      {
        path: '/airline/agent/general-rate/upload-rates',
        component: lazy(() => import('pages/mainPages/Airline/Agent/GeneralRate/UploadRates/index')),
      },
      {
        path: '/airline/agent/general-rate/list/view',
        component: lazy(() => import('pages/mainPages/Airline/Agent/GeneralRate/List')),
      },
      {
        path: '/airline/agent/general-rate/edit/:airfare_id',
        component: lazy(() => import('pages/mainPages/Airline/Agent/GeneralRate/Update')),
      },

      //Priority Rate
      {
        path: '/airline/agent/priority-rate/create',
        component: lazy(() => import('pages/mainPages/Airline/Agent/PriorityRate/Create')),
      },
      {
        path: '/airline/agent/priority-rate/upload-rates',
        component: lazy(() => import('pages/mainPages/Airline/Agent/PriorityRate/UploadRates/index')),
      },
      {
        path: '/airline/agent/priority-rate/list/view',
        component: lazy(() => import('pages/mainPages/Airline/Agent/PriorityRate/List')),
      },
      {
        path: '/airline/agent/priority-rate/edit/:airfare_id',
        component: lazy(() => import('pages/mainPages/Airline/Agent/PriorityRate/Update')),
      },

      //Spot Rate
      {
        path: '/airline/agent/spot-rate/create',
        component: lazy(() => import('pages/mainPages/Airline/Agent/SpotRate/Create')),
      },
      {
        path: '/airline/agent/spot-rate/upload-rates',
        component: lazy(() => import('pages/mainPages/Airline/Agent/SpotRate/UploadRates/index')),
      },
      {
        path: '/airline/agent/spot-rate/list/view',
        component: lazy(() => import('pages/mainPages/Airline/Agent/SpotRate/List')),
      },
      {
        path: '/airline/agent/spot-rate/edit/:airfare_id',
        component: lazy(() => import('pages/mainPages/Airline/Agent/SpotRate/Update')),
      },

      // Weight Range
      {
        path: '/airline/agent/wieght-range/create',
        component: lazy(() => import('pages/mainPages/Airline/Agent/WeightRange/Create')),
      },
      {
        path: '/airline/agent/wieght-range/update',
        component: lazy(() => import('pages/mainPages/Airline/Agent/WeightRange/Update')),
      },

      // Sur Charge
      {
        path: '/airline/agent/sur-charge/create',
        component: lazy(() => import('pages/mainPages/Airline/Agent/SurCharge/Create')),
      },
      {
        path: '/airline/agent/sur-charge/update',
        component: lazy(() => import('pages/mainPages/Airline/Agent/SurCharge/Update')),
      },

      // Notification
      {
        path: '/airline/agent/notification',
        component: React.lazy(() =>
          import('pages/mainPages/Airline/Agent/Notification/Notification'),
        ),
      },
       //FeedBacklist
       {
        path: '/airline-agent/feedback/view',
        component: React.lazy(() => import('pages/mainPages/Airline/Agent/Feedback/FeedbackList')),
      },
      {
        path: '/airline-agent/feedback/addfeedback',
        component: React.lazy(() => import('pages/mainPages/Airline/Agent/Feedback/Addfeedback')),
      },
      {
        path: '/airline-agent/feedback/feedbacklist/view/:id',
        component: React.lazy(() =>
          import('pages/mainPages/Airline/Agent/Feedback/FeedbackListView'),
        ),
      },

      //Tutorial View
      {
        path: '/airline/agent/tutorial/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Tutorial/ViewTutorial'),
        ),
      },
    ],
  },
];
