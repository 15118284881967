export const ffAdminNavConfig = [
  // New Quotes
  {
    id: 'quotationManagement',
    title: 'Quotes',
    messageId: 'sidebar.quotationManagement',
    //type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/quotes-active.png', '/assets/images/sideMenu/quotes.png'],
    role: ['ROLE_FFADMIN'],
    url: [
      '/freight-forwarder/admin/quotation/create',
      '/freight-forwarder/admin/quotation/manual/view',
      '/freight-forwarder/admin/quotation/list/view',
      '/freight-forwarder/admin/quotation/analysis/list/view',
      '/freight-forwarder/admin/quotation/inquerier/list/view',
      '/freight-forwarder/admin/quotation/price',
      '/freight-forwarder/admin/quotation/Airfreight-quote/price',
      '/freight-forwarder/admin/quotation/manual/details',
      '/freight-forwarder/admin/quotation/details/',
      '/freight-forwarder/admin/quotation/update/',
      '/freight-forwarder/admin/quotationanlyst/quotaionlist/view',
      '/freight-forwarder/admin/quote-analyst/quotation/details',
    ]
    // children: [
    // {
    //   id: 'quotationCreate',
    //   title: 'New Quote',
    //   messageId: 'sidebar.quotationManagement.quotationCreate',
    //   type: 'item', 
    //   url: '/freight-forwarder/admin/quotation/create',
    //   icon: '/assets/images/sideMenu/sub-menu.png',
    // },
    //   {
    //     id: 'quotationList',
    //     title: 'My Quotes',
    //     messageId: 'sidebar.quotationManagement.quotationList',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/quotation/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'quotationAnalysis',
    //     title: 'Quote Analysis',
    //     messageId: 'sidebar.quotationManagement.quotationAnalysis',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/quotation/analysis/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'quoteInquerier',
    //     title: 'Quote Inquerier',
    //     messageId: 'sidebar.quotationManagement.quoteInquerier',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/quotation/inquerier/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'manualQuote',
    //     title: 'Manual Quote',
    //     messageId: 'sidebar.quotationManagement.manualQuote',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/quotation/manual/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Incoming Quotes
  {
    id: 'incomingQuotesManagement',
    title: 'Incoming Quotes',
    messageId: 'sidebar.incomingQuotesManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/incoming-quotes-active.png', '/assets/images/sideMenu/incoming-quotes.png'],
    url: ['/freight-forwarder/admin/quotation/shippers/incoming/quote/list/view',
      '/freight-forwarder/admin/quotation/shippers/requests/list/view',
      '/freight-forwarder/admin/quotations/list/view',
      '/freight-forwarder/admin/incoming-quotes/quotation/details',
      '/freight-forwarder/admin/quotation/shippers/incoming/quote/details/view/',
      '/freight-forwarder/admin/shipperRequest/quotation/price',
      '/freight-forwarder/admin/shipperRequest/Airfreight/price',
      '/freight-forwarder/admin/quotation/shippers/incoming/quote/details/manualquotation/',
      '/freight-forwarder/admin/quotation/shippers/incoming-quotes-analysis/quote/details/manualquotation/'
    ],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'quoteAnalysis',
    //     title: 'Client Quotes - Analysis',
    //     messageId: 'sidebar.incomingQuotesManagement.quoteAnalysis',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/quotation/shippers/requests/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'incomingQuote',
    //     title: 'Client Quotes - Incoming',
    //     messageId: 'sidebar.incomingQuotesManagement.incomingQuote',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/quotation/shippers/incoming/quote/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Flight Information
  {
    id: 'flightScheduleManagement',
    title: 'Flight Information',
    messageId: 'sidebar.flightScheduleManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/flight-information-active.png', '/assets/images/sideMenu/flight-information.png'],
    url: ['/freight-forwarder/admin/flight/schedule'],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'flightScheduleSearch',
    //     title: 'Flight Schedule',
    //     messageId: 'sidebar.flightScheduleManagement.flightScheduleSearch',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/flight/schedule',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Tracking
  {
    id: 'trackingManagement',
    title: 'Tracking',
    messageId: 'sidebar.trackingManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/tracking-active.png', '/assets/images/sideMenu/tracking.png'],
    url: ['/freight-forwarder/admin/tracking'],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'tracking',
    //     title: 'Tracking',
    //     messageId: 'sidebar.trackingManagement.tracking',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/tracking',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Margins
  {
    id: 'marginsManagement',
    title: 'Margins',
    messageId: 'sidebar.marginsManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/margin-active.png', '/assets/images/sideMenu/margin.png'],
    url: ['/freight-forwarder/admin/economics/list/view',
      '/freight-forwarder/admin/trucking-ranges/create',
      '/freight-forwarder/admin/airline-ranges/create',
      '/freight-forwarder/admin/preset/margin/setup'],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'marginsViewByAgent',
    //     title: 'Margins View By Agent',
    //     messageId: 'sidebar.marginsManagement.marginsViewByAgent',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/economics/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'createMarginsTrucking',
    //     title: 'Trucking Margins Setup',
    //     messageId: 'sidebar.marginsRanges.createTruckingMarginsSetup',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/trucking-ranges/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'createAirfreightMarginsSetup',
    //     title: 'Airfreight Margins Setup',
    //     messageId: 'sidebar.marginsRanges.createAirfreightMarginsSetup',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/airline-ranges/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Client
  {
    id: 'shipperManagement',
    title: 'Clients',
    messageId: 'sidebar.shipperManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/client-active.png', '/assets/images/sideMenu/client.png'],
    // url: [
    //   '/freight-forwarder/admin/Client/List',
    //   '/freight-forwarder/admin/shippers/add',
    // ],
    url: ['/freight-forwarder/admin/shippers/requests/list/view',
      '/freight-forwarder/admin/Client/List',
      '/freight-forwarder/admin/shippers/add',
      '/freight-forwarder/client/Clientlists/freightforwarder/actionkey/details',
      '/freight-forwarder/admin/key-management/unassign-key/shipper-reassign',
      '/freight-forwarder/client/clientlist/freightforwarder/actionkey/details',
      '/freight-forwarder/admin/add/reassignfromClientList',
      '/freight-forwarder/admin/client/clientlist/assign-key/add-shipper/',
      '/freight-forwarder/admin/client/clientlist/key-management/checkout_payment',
      '/freight-forwarder/admin/client/clientlist/payment/paymentsuccess',
      '/freight-forwarder/admin/shippers/message/list/view',
    ],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'addshipper',
    //     title: 'Send Request',
    //     messageId: 'sidebar.shipperManagement.addshipper',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/shippers/add',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'shipperRequest',
    //     title: 'Received Request List',
    //     messageId: 'sidebar.shipperManagement.shipperRequest',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/shippers/requests/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Agents
  {
    id: 'agentsManagement',
    title: 'Agents',
    messageId: 'sidebar.agentsManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/agents-active.png', '/assets/images/sideMenu/agents.png'],
    url: ['/freight-forwarder/admin/agents/add',
      '/freight-forwarder/admin/agents/list/view',
      '/freight-forwarder/admin/agents/unassign-key/shipper-reassign',
      '/freight-forwarder/admin/add/reassign/',
      '/freight-forwarder/admin/agent/agentslists/freightforwarder/actionkey/details',
      '/freight-forwarder/admin/agent/list/assign-key/reassign-key',
      '/freight-forwarder/admin/agent-list/checkout_payment',
      '/freight-forwarder/admin/agent/agent-list/payment/paymentsuccess',
    ],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'addAgents',
    //     title: 'Create',
    //     messageId: 'sidebar.agentsManagement.addAgent',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/agents/add',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'agents',
    //     title: 'List',
    //     messageId: 'sidebar.agentsManagement.agents',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/agents/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Warehouse
  {
    id: 'wareHouseManagement',
    title: 'Warehouse',
    messageId: 'sidebar.wareHouseManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/warehouse-active.png', '/assets/images/sideMenu/warehouse.png'],
    url: ['/freight-forwarder/admin/warehouse/create',
      '/freight-forwarder/admin/warehouse/list/view',
      '/freight-forwarder/admin/warehouse/edit/'],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'addWareHouse',
    //     title: 'Create',
    //     messageId: 'sidebar.wareHouseManagement.addWareHouse',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/warehouse/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'wareHouseList',
    //     title: 'List',
    //     messageId: 'sidebar.wareHouseManagement.wareHouseList',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/warehouse/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Address Book
  {
    id: 'addressManagement',
    title: 'Address Book',
    messageId: 'sidebar.addressManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/address-book-active.png', '/assets/images/sideMenu/address-book.png'],
    url: ['/freight-forwarder/admin/directory/address/new',
      '/freight-forwarder/admin/directory/address/list/view',
      '/freight-forwarder/admin/directory/address/edit/'],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'customerList',
    //     title: 'Address List',
    //     messageId: 'sidebar.addressManagement.addressList',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/directory/address/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'addAddress',
    //     title: 'Add Address',
    //     messageId: 'sidebar.addressManagement.addAddress',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/directory/address/new',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Account Management
  {
    id: 'adminAccountManagement',
    title: 'Account Management',
    messageId: 'sidebar.adminAccountManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/account-management-active.png', '/assets/images/sideMenu/account-management.png'],
    url: ['/freight-forwarder/admin/subscriptions',
      '/freight-forwarder/admin/approveAddonsRequest',
      '/freight-forwarder/admin/addonsrequest/details',
      '/freight-forwarder/key-management/freightforwarder/view',
      '/freight-forwarder/key-management/freightforwarder/details',
      '/freight-forwarder/key-management/freightforwarder/actionkey/details',
      '/freight-forwarder/admin/key-management/checkout_payment',
      '/freight-forwarder/admin/key-management/payment-success',
      '/freight-forwarder/admin/key-management/add/reassign/',
      '/freight-forwarder/admin/key-management/unassign-key/reassign-key/',
      '/freight-forwarder/transaction-list/freightforwarder/view',
      '/freight-forwarder/transaction/details/view',
      '/freight-forwarder/admin/checkout_payment',
      '/freight-forwarder/admin/key-management/unassign-key/add-shipper/',
      '/freight-forwarder/admin/key-management/unassign-key/shipper-reassign',
      '/freight-forwarder/admin/subscription/payment',
      '/freight-forwarders/admin/subscription/payment/success',
      '/freight-forwarder/admin/add/reassignfromkeymanagement/',
      '/freight-forwarder/key-management/assign/admin'
    ],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'subscriptionDetails',
    //     title: 'Subscription Details',
    //     messageId: 'sidebar.adminAccountManagement.subscriptionDetails',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/subscriptions',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'approveAddonsRequest',
    //     title: 'Approved Addons Request',
    //     messageId: 'sidebar.adminAccountManagement.approveAddonsRequest',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/approveAddonsRequest',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'keyManagement',
    //     title: 'Key Management',
    //     messageId: 'sidebar.adminAccountManagement.keyManagement',
    //     type: 'item',
    //     url: '/freight-forwarder/key-management/freightforwarder/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'transaction',
    //     title: 'Transaction Details',
    //     messageId: 'sidebar.adminAccountManagement.transaction',
    //     type: 'item',
    //     url: '/freight-forwarder/transaction-list/freightforwarder/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },
  {
    id: 'priorityRate',
    title: 'Contract Rates',
    messageId: 'sidebar.priorityRate',
    type: 'item',
    icon: ['/assets/images/sideMenu/contract-rate-active.png', '/assets/images/sideMenu/contract-rate.png'],
    role: ['ROLE_FFADMIN'],
    url: ['/freightForwarder/admin/contract-rate/manual-rate',
      '/freightForwarder/admin/contract-rate/create',
      // '/freightForwarder/admin/contract-rate/upload-rates',
      '/freightForwarder/admin/contract-rate/list/view',
      '/freightForwarder/admin/contract-rate/edit/',
      '/freightForwarder/admin/contract-rate/manual-rate-list/view',
      '/freightForwarder/admin/contract-rate/update-manual-rate',
      '/freightForwarder/admin/contract-rate/update-surcharge'
    ],
    // children: [
    //   {
    //     id: 'createPriorityRates',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.createPriorityRates',
    //     type: 'item',
    //     url: '/airline/admin/contract-rate/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'priorityRates',
    //     title: 'List',
    //     messageId: 'sidebar.airline.priorityRates',
    //     type: 'item',
    //     url: '/airline/admin/contract-rate/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },
  // Statistics
  {
    id: 'statistics',
    title: 'Statistics',
    messageId: 'sidebar.statistics',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/statistics-active.png', '/assets/images/sideMenu/statistics.png'],
    url: ['/freight-forwarder/admin/statistics'],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'statistics',
    //     title: 'Statistics',
    //     messageId: 'sidebar.statistics.statistics',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/statistics',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Settings
  {
    id: 'profileManagement',
    title: 'Settings',
    messageId: 'sidebar.profile',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/settings-active.png', '/assets/images/sideMenu/settings.png'],
    url: ['/freight-forwarder/admin/profile/update',
      '/freight-forwarder/admin/profile/disclaimer/setting',
      '/freight-forwarder/admin/profile/pdf/design',
      '/freight-forwarder/admin/profile/quote/email/msg/setting',
      '/freight-forwarder/admin/profile/measurements/system',
      '/freight-forwarder/admin/profile/freight/forwarder/airport',
      '/freight-forwarder/admin/profile/manual/setting'
    ],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'profile',
    //     title: 'View Profile',
    //     messageId: 'sidebar.adminProfile.update',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/profile/update',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'disclaimerSetting',
    //     title: 'Disclaimer Settings',
    //     messageId: 'sidebar.adminProfile.disclaimerSetting',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/profile/disclaimer/setting',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'pdfDesign',
    //     title: 'PDF Design',
    //     messageId: 'sidebar.adminProfile.pdfDesign',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/profile/pdf/design',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'quoteEmailMsgSetting',
    //     title: 'Quote Email Message',
    //     messageId: 'sidebar.adminProfile.quoteEmailMsgSetting',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/profile/quote/email/msg/setting',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Documents
  // {
  //   id: 'documentsManagement',
  //   title: 'Documents',
  //   messageId: 'sidebar.documentsManagement',
  //   type: 'collapse',
  //   //type: 'item',
  //   icon: '/assets/images/sideMenu/documents.png',
  //   role: ['ROLE_FFADMIN'],
  //   url: '/freight-forwarder/admin/notification',

  //   //children: [],
  // },

  // Notifications
  {
    id: 'notification',
    title: 'Notifications',
    messageId: 'sidebar.notification',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/notification-active.png', '/assets/images/sideMenu/notification.png'],
    url: ['/freight-forwarder/admin/notification'],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'notification',
    //     title: 'Notifications',
    //     messageId: 'sidebar.notification',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/notification',
    //     icon: '/assets/images/sideMenu/sub-menu.png',

    //   },
    // ],
  },

  // Feedback
  {
    id: 'feedBackManagement',
    title: 'Feedback',
    messageId: 'sidebar.feedBackManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/feedback-active.png', '/assets/images/sideMenu/feedback.png'],
    url: ['/freight-forwarder/admin/addfeedback',
      '/freight-forwarder/admin/feedback/view',
      '/freight-forwarder/admin/feedback/feedbacklist/view/'],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'feedback',
    //     title: 'Feedback',
    //     messageId: 'sidebar.feedBackManagement.feedback',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/feedback/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // About
  {
    id: 'aboutManagement',
    title: 'About',
    messageId: 'sidebar.aboutManagement',
    // type: 'collapse',
    type: 'item',
    icon: ['/assets/images/sideMenu/about-active.png', '/assets/images/sideMenu/about.png'],
    url: ['/freight-forwarder/admin/about/view'],
    role: ['ROLE_FFADMIN'],
    // children: [
    //   {
    //     id: 'about',
    //     title: 'About',
    //     messageId: 'sidebar.aboutManagement.about',
    //     type: 'item',
    //     url: '/freight-forwarder/admin/about/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },


  // Tutorial
  {
    id: 'ytTutorial',
    title: 'Tutorial',
    messageId: 'sidebar.ytTutorial',
    type: 'item',
    icon: ['/assets/images/sideMenu/youtube-active.png', '/assets/images/sideMenu/youtube.png'],
    url: ['/freight-forwarder/admin/tutorial/view'],
    role: ['ROLE_FFADMIN'],
  },


];
