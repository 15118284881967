import React, { useEffect, useRef, useState } from 'react';
import { Grid, Button, TextField, Typography, IconButton, InputAdornment } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import './ChatMessageModal.css';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@mui/icons-material/Send';
import { baseUrlFormat } from '@crema/services/auth/jwt-auth/jwt-api';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Loader } from '@crema';
import { format, parse } from 'date-fns';

const ChatMessageModal = ({
    chatMsgReply,
    handleChatMsgReplyClose,
    // getMessageData,
    companyId,
    companyData,
    role,
    companyName,
    roomId,
    chatGroupId,
    clientID,
}) => {
    const dispatch = useDispatch();

    const [loaderStatus, setLoaderStatus] = useState('');

    const { profile } = useSelector((state) => state.auth);

    const [messageData, setMessageData] = React.useState([]);

    const [typedMessageData, setTypedMessageData] = React.useState('');

    const [timeZoneSign, setTimeZoneSign] = React.useState();

    useEffect(() => {
        if (timeZoneSign) {
            getMessageData(timeZoneSign);
        }
    }, [timeZoneSign]);

    useEffect(() => {
        getSystemTimezoneOffset();
    }, []);

    // ------------------- Submit Message Chat Data ------------------- //
    async function submitMessageData() {

        // let post_data =
        //     role === 'ffAdmin'
        //         ? {
        //             "email": companyData,
        //             "msg": typedMessageData ? typedMessageData : '',
        //             // "sender": companyId,
        //             // "receiver": profile?.companyId,
        //             "sender": profile?.companyId,
        //             "receiver": companyId,
        //             'adminId': profile?.id,
        //             'clientId': companyId,
        //             'companyId': profile?.companyId,
        //         }
        //         :
        //         role === 'client' ? {
        //             "email": companyData,
        //             "msg": typedMessageData ? typedMessageData : '',
        //             // "sender": companyId,
        //             // "receiver": profile?.id,
        //             "sender": profile?.id,
        //             "receiver": companyId,

        //             'adminId': profile?.id,
        //             'clientId': companyId,
        //             'companyId': profile?.companyId,

        //         }
        //             : {
        //                 "email": companyData,
        //                 "msg": typedMessageData ? typedMessageData : '',
        //                 // "sender": companyId,
        //                 // "receiver": profile?.id,
        //                 "sender": profile?.id,
        //                 "receiver": companyId,
        //             }
        //     ;

        let post_data = {
            "msg": typedMessageData ? typedMessageData : '',
            "roomId": roomId,
            // 'companyId': role === `client` ? profile?.companyId : companyId,
            'companyId': role === `client` ? companyId : profile?.companyId,
            "clientId": clientID,
        };

        //url -> http://localhost:8080/api/send/msg
        // method -> POST
        // request -> {
        //     "msg": "isecond time msg ",
        //     "companyId": 12,
        //     "clientId":177,
        //     "roomId": "1872180635"
        // }

        // response -> 
        // {
        //     "description": "Message send successfully",
        //     "roomId": 1872180635
        // }

        try {
            setLoaderStatus('loading');

            const res = axios({
                url: `${baseUrlFormat}/send/msg`, //your url
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                data: {
                    ...post_data,
                },
            })
                .then((response) => {
                    if (response?.status === 200) {
                        setTypedMessageData('');
                    }
                    getMessageData(timeZoneSign, response?.data?.roomId);
                    setLoaderStatus('');
                })
                .catch(function (error1) {
                    if (error1?.response?.data?.title != undefined) {
                        toast.error(error1?.response?.data?.title, { autoClose: 1500 });
                    } else {
                        toast.error('Internal Server Error');
                    }
                    setLoaderStatus('');
                });
        } catch (error) {
            toast.error('Internal Server Error');
            setLoaderStatus('');
        }
    }

    // ------------------- Message Chat Data ------------------- //
    async function getMessageData(timeZoneSign, resRoomID) {

        let roomID = roomId ? roomId : '';
        let resROOMID = resRoomID ? resRoomID : '';

        try {
            if (timeZoneSign) {
                const res = axios({
                    // url: role === 'ffAdmin' ?
                    //     `${baseUrlFormat}/retrieved/msg?currentUserId=${profile?.companyId}&receiverId=${companyId}&GMT=${timeZoneSign}&chatGroupId=${chatGroupId}`
                    //     : `${baseUrlFormat}/retrieved/msg?currentUserId=${profile?.id}&receiverId=${companyId}&GMT=${timeZoneSign}&chatGroupId=${chatGroupId}`
                    // ,   resRoomID

                    url: roomId
                        ? role === 'ffAdmin' ?
                            `${baseUrlFormat}/retrieved/msg?GMT=${timeZoneSign}&roomId=${roomID}`
                            : `${baseUrlFormat}/retrieved/msg?GMT=${timeZoneSign}&roomId=${roomID}`
                        : role === 'ffAdmin' ?
                            `${baseUrlFormat}/retrieved/msg?GMT=${timeZoneSign}&roomId=${resROOMID}`
                            : `${baseUrlFormat}/retrieved/msg?GMT=${timeZoneSign}&roomId=${resROOMID}`
                    ,

                    // url: roomId
                    //     ? role === 'ffAdmin' ?
                    //         `${baseUrlFormat}/retrieved/msg?GMT=${timeZoneSign}&roomId=${roomId}`
                    //         : `${baseUrlFormat}/retrieved/msg?GMT=${timeZoneSign}&roomId=${roomId}`
                    //     : role === 'ffAdmin' ?
                    //         `${baseUrlFormat}/retrieved/msg?GMT=${timeZoneSign}&roomId=${resRoomID}`
                    //         : `${baseUrlFormat}/retrieved/msg?GMT=${timeZoneSign}&roomId=${resRoomID}`
                    // ,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                    .then((response) => {
                        if (response?.status === 200) {
                            setMessageData(response?.data);
                        }
                    })
                    .catch(function (error1) {
                        if (roomId) {
                            if (error1?.response?.data?.title != undefined) {
                                toast.error(error1?.response?.data?.title, { autoClose: 1500 });
                            } else {
                                toast.error('Internal Server Error');
                            }
                        }
                    });
            }
        } catch (error) {
            toast.error('Internal Server Error');
        }
    }

    // ------------------- Date Format ------------------- //
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    // ------------------- Time Format ------------------- //
    // const formatTime = (timeString) => {
    //     const time = timeString.split(':');
    //     return `${time[0]}:${time[1]}`;
    // };

    const formatTime = (timeString) => {
        // Parse the time string without the milliseconds part
        const time = parse(timeString.split('.')[0], 'HH:mm:ss', new Date());

        // Extract hours, minutes, and period
        let hours = format(time, 'h');
        const minutes = format(time, 'mm');
        const period = format(time, 'a').toLowerCase(); // Lowercase 'am'/'pm'

        // Handling special cases
        if (hours === '12' && period === 'am') {
            hours = '0'; // Midnight case
        }

        return `${hours}:${minutes} ${period}`;
    };
    // const formatTime = (timeString) => {
    //     // Parse the time string without the milliseconds part
    //     const time = parse(timeString.split('.')[0], 'HH:mm:ss', new Date());

    //     // Extract hours, minutes, and period
    //     const hours = format(time, 'HH');
    //     const minutes = format(time, 'mm');
    //     const period = hours === '00' ? 'AM' : format(time, 'a');

    //     // Return custom formatted time
    //     return `${hours}:${minutes} ${period}`;
    // };

    // const formatTime = (timeString) => {
    //     const time = parse(timeString, 'HH:mm:ss.SSSSSS', new Date());
    //     return format(time, 'hh:mm a');
    // };

    // ------------------- Get Time Zone Offset from Local System ------------------- //
    const getSystemTimezoneOffset = () => {
        // Create a new Date object for the current time
        const now = new Date();

        // Get the time zone offset in minutes and invert the sign (to align with the GMT offset convention)
        const offsetMinutes = -now.getTimezoneOffset();

        // Calculate absolute hours and remaining minutes
        const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
        const offsetRemainingMinutes = Math.abs(offsetMinutes) % 60;

        // Determine the sign of the offset
        // const sign = offsetMinutes >= 0 ? '+' : '-';
        const sign = offsetMinutes >= 0 ? '%2B' : '%2D';

        // Format the offset as GMT+0530 or GMT-0530
        // const formattedOffset = `GMT${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetRemainingMinutes).padStart(2, '0')}`;
        const formattedOffset = `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetRemainingMinutes).padStart(2, '0')}`;

        setTimeZoneSign(formattedOffset);

        return formattedOffset;
    };

    // console.log(getSystemTimezoneOffset());



    // ------------------- Time Format ------------------- //
    // const formatTime = (timeString) => {
    //     const [hours, minutes] = timeString.split(':').map(Number);
    //     const period = hours >= 12 ? 'PM' : 'AM';
    //     const formattedHours = hours % 12 || 12;
    //     return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
    // };

    // const formatTime = (timeString) => {
    //     const [hours, minutes] = timeString.split(':').map(Number);
    //     const period = hours >= 12 ? 'PM' : 'AM';
    //     const formattedHours = hours % 12 || 12;
    //     return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period} US time`;
    // };




    // const formatUSTime = (timeString, timeZone = 'America/New_York') => {
    //     const [hours, minutes] = timeString.split(':').map(Number);

    //     // Create a Date object with the given time
    //     const date = new Date();
    //     date.setHours(hours);
    //     date.setMinutes(minutes);

    //     // Convert the Date object to the specified time zone
    //     const options = {
    //         hour: 'numeric',
    //         minute: 'numeric',
    //         hour12: true,
    //         timeZone,
    //         timeZoneName: 'short'
    //     };
    //     const formattedTime = new Intl.DateTimeFormat('en-US', options).format(date);

    //     // return `${formattedTime} US time`;
    //     return `${formattedTime}`;
    // };



    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messageData]);

    return (
        <Dialog
            open={chatMsgReply}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            PaperProps={{
                style: {
                    overflowX: 'hidden',
                    borderRadius: "10px",
                }
            }}
        >
            <Grid container spacing={1} className="header-shadow">
                {/* ------------- Email ------------- */}
                <Grid item xs={10} md={10} sm={10} className="pl-10">
                    {/* <Typography className='chat-msg-label'>{"Chat Message"}</Typography> */}
                    {/* <Typography className='email-label'>{"abcd@gmail.com"}</Typography> */}
                    <Typography className='chat-msg-label'>{
                        role === 'ffAdmin'
                            ? companyName
                            : companyName
                    }</Typography>

                    <Typography className='email-label'>{
                        role === 'ffAdmin'
                            ? companyData
                            : companyData
                    }</Typography>

                </Grid>
                {/* ------------- Email ------------- */}
                <Grid item xs={2} md={2} sm={2} className="ta-end-ac-center">
                    <IconButton
                        style={{ height: 30, width: 30, color: "#FFFFFF" }}
                        aria-label="close"
                        onClick={handleChatMsgReplyClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <DialogContent className="content-bg"
                ref={chatContainerRef}
            >
                <Grid className="chat-msg-modal">
                    <Formik
                        validateOnChange={true}
                        enableReinitialize
                    // initialValues={initialValues}
                    // onSubmit={async (data, { setSubmitting }) => {}}
                    >
                        {({ isSubmitting, resetForm, setFieldValue, errors, touched }) => (
                            <Form noValidate autoComplete="off" className="wh-500">
                                <Grid container spacing={1}>
                                    {messageData && messageData.length > 0 &&
                                        messageData.map((v, index) => (
                                            <React.Fragment key={index}>
                                                {/*----- Month Date Year -----*/}
                                                {v && v.message && v.message.length > 0 &&
                                                    <div className="date-container">
                                                        <Typography variant="caption" className="date-text">
                                                            {formatDate(v.date)}
                                                        </Typography>
                                                    </div>
                                                }

                                                {/*----- User Name and Left Msg and Right Msg and Time -----*/}
                                                {v && v?.message && v?.message.length > 0 &&
                                                    v?.message.map((e, messageIndex) => (
                                                        <Grid className="grid-container-col">
                                                            {/* <Grid className='grid-col-item-left'>
                                                                {e?.sender != profile?.id &&
                                                                    <Grid className="user-profile-border">
                                                                        <img className="user-profile-img" src="/assets/images/dashboard/user.png" alt="User" />
                                                                    </Grid>
                                                                }
                                                            </Grid> */}
                                                            <Grid className='grid-col-item-left'>
                                                                {e?.sender !== profile?.id
                                                                    &&
                                                                    <Grid
                                                                        // ROLE_FFCLIENT -> Client (FF)

                                                                        // ROLE_CONSIGNEE -> Client (C/S)

                                                                        // ROLE_FFADMIN -> FF Admin

                                                                        // ROLE_FFAGENT�->�FF�Agent
                                                                        style={{
                                                                            backgroundColor: e?.senderRole === 'FF Admin' ? `#009688`
                                                                                : e?.senderRole === 'FF Agent' ? `#E53935`
                                                                                    : e?.senderRole === 'Client (C/S)' ? `#0C6AC9`
                                                                                        : `#2E8B57`,
                                                                            // /* display: flex; 
                                                                            // align-items: center;
                                                                            // justify-content: center;
                                                                            // width: 40px;
                                                                            // height: 40px;
                                                                            // font-size: 24px;
                                                                            // color: #FFFFFF;
                                                                            // border-radius: 50%; */
                                                                        }}
                                                                        className="user-profile-letter">
                                                                        {e?.senderName ? e?.senderName.charAt(0) : ''}
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                            {/* <Grid className='grid-col-item-left'>
                                                                {e?.sender !== profile?.id
                                                                    &&
                                                                    // ? (
                                                                    //     <Grid className="user-profile-border">
                                                                    //         <img className="user-profile-img" src="/assets/images/dashboard/user.png" alt="User" />
                                                                    //     </Grid>
                                                                    // ) : (
                                                                    <Grid className="user-profile-letter">
                                                                        {'I'}
                                                                    </Grid>
                                                                    // )
                                                                }
                                                            </Grid> */}

                                                            <Grid
                                                                className={
                                                                    role === 'ffAdmin'
                                                                        ? e?.sender === profile?.id ? 'right-chat-bg' : 'left-chat-bg'
                                                                        : e?.sender === profile?.id ? 'right-chat-bg' : 'left-chat-bg'
                                                                }
                                                                key={messageIndex}
                                                            >
                                                                <Grid container>
                                                                    <Typography
                                                                        className={
                                                                            role === 'ffAdmin'
                                                                                ? e?.sender === profile?.id ? 'right-chat-user' : "left-chat-user"
                                                                                : e?.sender === profile?.id ? 'right-chat-user' : "left-chat-user"
                                                                        }
                                                                        style={{ whiteSpace: 'pre-wrap' }}
                                                                    >
                                                                        {/* {e?.sender != profile?.id && e?.senderName + ' (' + e?.senderRole + ')'} */}
                                                                        {e?.sender != profile?.id && e?.senderName}
                                                                    </Typography>

                                                                    <Typography
                                                                        className={
                                                                            role === 'ffAdmin'
                                                                                ? e?.sender === profile?.id ? 'right-chat-senderRole' : "left-chat-senderRole"
                                                                                : e?.sender === profile?.id ? 'right-chat-senderRole' : "left-chat-senderRole"
                                                                        }
                                                                        style={{ whiteSpace: 'pre-wrap' }}
                                                                    >
                                                                        {/* {e?.sender != profile?.id && e?.senderName + ' (' + e?.senderRole + ')'} */}
                                                                        {e?.sender != profile?.id && '(' + e?.senderRole + ')'}
                                                                    </Typography>
                                                                </Grid>

                                                                <Typography
                                                                    className={
                                                                        role === 'ffAdmin'
                                                                            ? e?.sender === profile?.id ? 'right-chat-msg' : "left-chat-msg"
                                                                            : e?.sender === profile?.id ? 'right-chat-msg' : "left-chat-msg"
                                                                    }
                                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                                >
                                                                    {e?.msg}
                                                                </Typography>

                                                                <Typography
                                                                    className={
                                                                        role === 'ffAdmin'
                                                                            ? e?.sender === profile?.id ? 'right-chat-time' : "left-chat-time"
                                                                            : e?.sender === profile?.id ? 'right-chat-time' : "left-chat-time"
                                                                    }
                                                                >
                                                                    {e.time ? formatTime(e.time) : ''}
                                                                </Typography>
                                                            </Grid>
                                                            {/* <Grid className='grid-col-item-right'>
                                                                {e?.sender === profile?.id &&
                                                                    <Grid className="user-profile-border">
                                                                        <img className="user-profile-img" src="/assets/images/dashboard/testi_img.png" alt="User" />
                                                                    </Grid>
                                                                } 
                                                            </Grid> */}
                                                        </Grid>
                                                    ))
                                                }
                                            </React.Fragment>
                                        ))
                                    }

                                    {/* {messageData && messageData.length > 0 &&
                                        messageData.map((e, index) => (
                                            <>
                                                < Grid
                                                    item xs={12} md={12} sm={12}
                                                    className={
                                                        role === 'ffAdmin'
                                                            ? e?.side === profile?.companyId ? "left-chat-bg" : 'right-chat-bg'
                                                            : e?.side === profile?.id ? "left-chat-bg" : 'right-chat-bg'
                                                    }
                                                    key={index}
                                                >
                                                    <Typography className={
                                                        role === 'ffAdmin'
                                                            ? e?.side === profile?.companyId ? "left-chat-msg" : 'right-chat-msg'
                                                            : e?.side === profile?.id ? "left-chat-msg" : 'right-chat-msg'
                                                    }
                                                    >{e.msg}</Typography>
                                                </Grid>
                                            </>
                                        ))
                                    } */}
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </DialogContent>
            {/* ------------- Type a message and Send Buttons ------------- */}
            <DialogActions className="msg-action">
                <Grid
                    container
                    xs={12}
                    sm={12}
                    spacing={1}
                >
                    <TextField
                        placeholder="Type a message..."
                        fullWidth
                        variant="standard"
                        multiline
                        maxRows={5}
                        value={typedMessageData}
                        onChange={(e) => {
                            setTypedMessageData(e?.target?.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{ height: 30, width: 30, cursor: "pointer", color: "#0C6AC9" }}
                                        aria-label="close"
                                        onClick={(e) => {
                                            submitMessageData();
                                        }}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottom: 'none',
                            },
                            '& .MuiInput-underline:hover:before': {
                                borderBottom: 'none',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none',
                            },
                        }}
                    />
                </Grid>
            </DialogActions>

            {/*----- Loader -----*/}
            {loaderStatus === 'loading' && <Loader />}
        </Dialog >
    );
};

export default ChatMessageModal;