
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import rawCountries from 'shared/FormControls/RawCountries';
import moment from 'moment';

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: 'https://via.placeholder.com/150',
};

export const defaultSuperAdmin = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'super_admin',
  photoURL: 'https://via.placeholder.com/150',
};

export const perPage = 500;

// export const lbsValue=2.20462262185;
export const lbsValue = 2.2;


export const initialUrl = '/shipper/quotation/create';
// export const initialUrl ='/super-admin/freight-forwarder/admins/list/view'

export const packgingNames = [
  'Envelope',
  'Pak',
  'Pallet',
  'Skid',
  'Bag',
  'Bale',
  'Barrel',
  'Basket',
  'Bin',
  'Box',
  'Bounch',
  'Bundle',
  'Cabinet',
  'Can',
  'Carboy',
  'Carrier',
  'Carton',
  'Case',
  'Cask',
  'Container',
  'Crate',
  'Cylinder',
  'Drum',
  'Jerrican',
  'Loose',
  'Other',
  'Package',
  'Pail',
  'Pieces',
  'Pipe Line',
  'Reel',
  'Roll',
  'Spool',
  'Tank',
  'Totes',
  'Tube',
  'Unit',
  'Van Pack',
  'Wrapped',
];

export const editQuote = {
  greenColor: '#91d7a2',
  purpleColor: '#c491f0',
  salmonColor: '#ffc6af',
  pinkColor: '#ffb2ff',
  lightBlueColor1: '#cee1f4',
  lightBlueColor2: '#b6d2ee',
  lightBlueColor3: '#9dc3e9',
  lightBlueColor4: '#85b4e4',
  lightBlueColor5: '#77a2cd',
};

export const textStyle = {
  fontSize: '16px',
  color: '#495057',
  fontFamily: 'Poppins,sans-serif',
  paddingTop:'15px'

}


export const handleNegativeValue = (event) => {
  const allowedCharacters = /[0-9.,]/;
  const inputChar = String.fromCharCode(event.keyCode || event.which);

  // if (event?.key === '-' || event?.key === '+' || !allowedCharacters.test(inputChar)) {
  if (event.key === '-' || !allowedCharacters.test(inputChar)) {
    event.preventDefault();
  }
};

export const handleNegativeValueAllowPlusAndMinu = (event) => {
  const allowedCharacters = /[0-9.,-]/;
  const inputChar = String.fromCharCode(event.keyCode || event.which);

  if (event?.key === '-0' || !allowedCharacters.test(inputChar)) {
    event.preventDefault();
  }
};

export const handleAmountFormat = (value) => {
  return parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2 });
};

export const toastDelay=4500;

export const hazamatClass = [
  '1.1',
  '1.1G',
  '1.2',
  '1.2G',
  '1.3',
  '1.3G',
  '1.4',
  '1.4B',
  '1.4C',
  '14G',
  '1.4S',
  '1.5',
  '1.6',
  '2.1',
  '2.2',
  '2.2(5.1)',
  '2.3',
  '3',
  '3(6.1)',
  '3(8)',
  '4.1',
  '4.2',
  '4.3',
  '4.3(3)(8)',
  '4.3(8)(3)',
  '5.2',
  '5.1(8)',
  '5.1',
  '6.1',
  '6.1(3)',
  '6.1(5.1)',
  '6.1(8)',
  '6.1(8)(3)',
  '6.2',
  '8',
  '8(3)',
  '8(5.1)',
  '8(6.1)',
  '9',
];

export const typeOfGoodNames = [
  'General Cargo', 
  'Dangerous Goods', 
  'Express Cargo',
  'Temperature Controlled Cargo',
  'Perishable Cargo',
  'Oversized Cargo',
];

export const chooseUnitNames = [
  'Imperial / Metric', 
  'Imperial', 
  'Metric',
];

export const packingGroupNames = [
  'I', 
  'II', 
  'III',
  'None',
];

export const chooseShipper = ['Known Shipper', 'Unknown Shipper'];
export const stackableNames = ['Yes', 'No'];
export const hazmatNames = ['Yes', 'No'];
export const screenedNames = ['Yes', 'No'];
export const turnableNames = ['Yes', 'No'];
export const frieghtNames = ['Yes', 'No'];
export const chargeableNames = ['Yes', 'No'];


// Function to get the dialing code for a given country code
export const getDialingCodeFromCountryCode = (countryCode) => {
  let conutryDialCode = '';
      
  rawCountries.forEach(item => {
    if (countryCode === item?.[2]) {
      conutryDialCode = item?.[3] ? `+${item?.[3]}`  : '';
    }
  })
  return conutryDialCode;
};



// // Function to format the phone number with the dialing code
export const formatPhoneNumberWithDialingCode = (mobileNumber) => {
  // const dialingCode = getDialingCodeFromCountryCode(countryCode);
  // console.log(mobileNumber,'mobilenumber')
  const phoneNumberWithDialingCode = `+${mobileNumber}`;
  const formattedPhoneNumber = formatPhoneNumberIntl(phoneNumberWithDialingCode);
  return formattedPhoneNumber;
};

export function formattedDate(date){
  return moment(date, 'MM-DD-YYYY HH:mm:ss').format('YYYY-MM-DD');
}
export function convertValueintoInternationalSystem (value){
  if (Math.abs(value) >= 1e12) {
    return (value / 1e12).toFixed(2) + 'T'; // Trillions
} else if (Math.abs(value) >= 1e9) {
    return (value / 1e9).toFixed(2) + 'B'; // Billions
}
else if (Math.abs(value) >= 1e6) {
    return (value / 1e6).toFixed(2) + 'M'; // Millions
}
else if (Math.abs(value) >= 1e3) {
    return formatCurrency(value / 1e3) + 'K'; // Thousands
}
else {
    return formatCurrency(value); // Fallback for smaller values
}

}

export   const formatTotalAmount = (amount) => {
  if (!amount || amount === "0") return 0; // Handle empty or zero values

  const numericValue = parseFloat(amount.replace(/[^0-9.]+/g, '')); // Extract numeric value

  if (amount.endsWith('M')) {
    return numericValue * 1e6; // Convert millions
  } else if (amount.endsWith('K')) {
    return numericValue * 1e3; // Convert thousands
  } else {
    return numericValue; // Return the number itself
  }
};

export  const formatCurrency  = (num) => {
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const roles = ['All', 'Assigned', 'Unassigned', 'Used', 'Unused', 'Active', 'Suspended', 'Expired'];
export const status=['All','Active','Inactive','Subscribed','Not Subscribe',]


export const checkingGreaterValue = (data) => {
  // Check if truckingRangeList is a valid array (not an empty string or null)
  if (!Array.isArray(data?.truckingRangeList) || data?.truckingRangeList.length === 0) {
    return false; // Or you can return true or some other value depending on your business logic
  }

  return data.truckingRangeList.some((item) => {
    if (item?.selectedTrucking === 'dollar' && item?.truckingMinMarginDollar > item?.truckingMarginDollar) {
      return true;
    } else if (item?.selectedTrucking === 'percentage' && item?.truckingMinMarginPercentage > item?.truckingMarginPercentage) {
      return true;
    } else {
      return false;
    }
  });
}
export const handleModalTrigger=(height, chooseUnit, i, triggeredIndexesRef, setShowModal)=>{

  let heightThreshold;
      if (chooseUnit === 'Imperial' || chooseUnit === 'Imperial / Metric') {
        heightThreshold = 63; // 63 inches for Imperial
      } else if (chooseUnit === 'Metric') {
        heightThreshold = 160; // 160 cm for Metric
      }

      const shouldTriggerModal = height >= heightThreshold;

      if (shouldTriggerModal && !triggeredIndexesRef.current[i]) {
        console.log(`Height greater than ${heightThreshold}, opening modal for item index:`, i);
        triggeredIndexesRef.current[i] = true;
        setShowModal(true);  
      
    }

      if (!shouldTriggerModal && triggeredIndexesRef.current[i]) {
        triggeredIndexesRef.current[i] = false;  // Reset the flag
      }

}
export const statisticColors=['#0A8FDC', '#49BD65', '#9E49E6', '#3A3849', '#ffa07a', '#DC143C'];
// export const tempUnitNames=['-25C°','+2C°/+8C°','+15C°/+25C°']
export const tempUnitNames = ['-25\u00b0C', '+2\u00b0C/+8\u00b0C', '+15\u00b0C/+25\u00b0C'];

export function extractLocation(address) {
  // Split the address by commas
  let parts = address.split(',');

  // Check if there are at least 3 parts (city, country, and some extra info)
  if (parts.length >= 3) {
      // Return the city and country part, which are the second and third parts
      return parts[1].trim() + ',' + parts[2].trim();
  } else {
      // If the format is not as expected, return a fallback or empty string
      return " ";
  }
}