import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import { navConfig } from 'navConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import { useSelector, useDispatch } from 'react-redux';
import { notificationMenuCountData } from 'redux/actions/Notification';

const Navigation = () => {
  const [navConfiguration, setNavconfiguration] = useState([]);

  useEffect(() => {
    setNavconfiguration(navConfig)
  }, [])

  const role = useSelector((state) => state.auth.user.role);

  const { profile } = useSelector((state) => state.auth);

  const { notificationMenuData } = useSelector((state) => state.notifications);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(notificationMenuCountData(true));
  }, [])

  const indexNoFlag = navConfiguration.findIndex(item => item.role &&
    item.role.includes(profile?.role == 'Super Admin' ? 'ROLE_SUPERADMIN' :
      profile?.role == 'FF Admin' ? 'ROLE_FFADMIN' :
        profile?.role == 'FF Agent' ? 'ROLE_FFAGENT' :
          profile?.role == 'Airline Admin' ? 'ROLE_AIRLINE_ADMIN' :
            profile?.role == 'Airline Agent' ? 'ROLE_AIRLINE_AGENT' :
              profile?.role == 'Client (Freight Forwarder)' ? 'ROLE_FFCLIENT' :
                profile?.role == 'Client (Consignee/Shipper)' ? 'ROLE_CONSIGNEE' :
                  profile?.role == 'Data Entity' ? 'ROLE_DATAENTRY' :
                    'ROLE_TRUCKING_ADMIN'
    ));

  return (
    <List style={{ paddingBottom: '150px' }}>
      {navConfiguration?.map((item, index) => {
        for (let roleInRoute of item.role) {
          if (roleInRoute === role) {
            return (
              <React.Fragment key={item.id} >
                {item.type === 'group' && <VerticalNavGroup item={item} level={0} />}

                {item.type === 'collapse' && <VerticalCollapse item={item} level={0} navConfiguration={navConfiguration} setNavconfiguration={setNavconfiguration} />}

                {item.type === 'item' && <VerticalItem item={item} level={0}
                  index={index}
                  profile={profile}
                  notificationMenuData={notificationMenuData}
                  indexNoFlag={indexNoFlag}
                />}

              </React.Fragment>
            );
          }
        }
      })}
    </List>
  );
};

export default Navigation;
