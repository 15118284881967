import React, { lazy } from 'react';

export const superAdminRouteConfig = [
  {
    auth: ['ROLE_SUPERADMIN'],
    routes: [
      //	FREIGHT FORWARDER ADMINS
      {
        path: '/super-admin/freight-forwarder/admin/new',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Admin/ExistingCompany'),
        ),
      },
      // {
      //   path: '/super-admin/freight-forwarder/admins/list/view',
      //   component: lazy(() =>
      //     import('pages/mainPages/SuperAdmin/FreightForwarder/Admin/AdminList'),
      //   ),
      // },
      {
        path: '/super-admin/freight-forwarder/admins/update/:userId',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Admin/UpdateCompany'),
        ),
      },
      {
        path: '/super-admin/freight-forwarder/admins/enrolled/:companyId',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Admin/AdminsEnrolled'),
        ),
      },
      // FREIGHT FORWARDER AGENTS
      // {
      //   path: '/super-admin/freight-forwarder/agents/list/view',
      //   component: lazy(() => import('pages/mainPages/SuperAdmin/FreightForwarder/Agents')),
      // },

      //	FREIGHT FORWARDER COMPANIES
      {
        path: '/super-admin/freight-forwarder/company/new',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Companies/NewCompany/NewCompany'),
        ),
      },

      //	FREIGHT FORWARDER COMPANIES DETAILS
      {
        path: '/super-admin/freight-forwarder/company-details/:companyId',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Companies/CompanyDetails/CompanyDetail'),
        ),
      },
      // FREIGHT FORWARDER QUOTATIONS
      {
        path: '/super-admin/freight-forwarder/quotations/list/view',
        component: lazy(() => import('pages/mainPages/SuperAdmin/FreightForwarder/Quotations')),
      },
      {
        path: '/super-admin/freight-forwarder/Feedback/list/view',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Feedback/FeedbackDetails/FeedbackDetail')),
      },

      {
        path: '/super-admin/freight-forwarder/groupbycompany/list/:id/:filterNum',
        component: lazy(() => import('pages/mainPages/SuperAdmin/FreightForwarder/Quotations/Adminquotationlist')),
      },
      {
        path: '/SuperAdmin/FreightForwarder/quotation/goods/details/view/:id',
        component: lazy(() => import('pages/mainPages/SuperAdmin/FreightForwarder/Quotations/GoodsAdminDetails')
        ),
      },
      {
        path: '/SuperAdmin/FreightForwarder/quotation/details/:id',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Quotations/AdminQuotationDetail')
        ),

      },
      {
        path: '/super-admin/freight-forwarder/companies/approved-companies',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Companies/CompanyList/ApprovedCompanies'),
        ),
      },
      {
        path: '/super-admin/freight-forwarder/companies/companydetails',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Companies/Statistics/Statistics'),

          // import('pages/mainPages/FreightForwarder/Admin/Statistics/Statistics'),
        ),
      },
      {
        path: '/super-admin/freight-forwarder/companies/unapproved-companies',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Companies/CompanyList/UnapprovedCompanies'),
        ),
      },
      {
        path: '/super-admin/freight-forwarder/companies/add-new-company',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Companies/NewCompany/NewCompany'),
        ),
      },
      {
        path: '/super-admin/airline/admins-enrolled/:id',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Admin/AdminsEnrolled'),
        ),
      },
      //	AccountManagement
      {
        path: '/super-admin/admins/account-management/package/new',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/AccountManagement/AddPackage/AddPackage'),
        ),
      },
      {
        path: '/super-admin/admins/account-management/packages/list/view',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/AccountManagement/PackageList/PackageList'),
        ),
      },
      {
        path: '/super-admin/admins/account-management/package/edit/:id',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/AccountManagement/UpdatePackage/UpdatePackage'),
        ),
      },
      {
        path: '/super-admin/admins/account-management/transition-details',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/AccountManagement/TransitionDetails/index'),
        ),
      },
      // {
      //   path: '/super-admin/admins/account-management/addons/request/list/view',
      //   component: lazy(() =>
      //     import('pages/mainPages/SuperAdmin/AccountManagement/index'),
      //   ),
      // },
      {
        path: '/super-admin/admins/account-management/approve/addons/request/list/view',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/AccountManagement/AddonsRequest/ApproveAddonsRequest'),
        ),
      },

      {
        path: '/super-admin/admins/account-management/addons/request/details',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/AccountManagement/AddonsRequest/AddonsDetails'),
        ),
      },

      // FREIGHT FORWARDER ADMINS AND AGENTS ENROLLED
      {
        path: '/super-admin/freight-forwarder/admin-enrolled/:companyName/:companyId',
        component: lazy(() => import('pages/mainPages/SuperAdmin/FreightForwarder/Admin/AdminList')),
      },
      {
        path: '/super-admin/freight-forwarder/agents-enrolled/:companyId',
        component: lazy(() => import('pages/mainPages/SuperAdmin/FreightForwarder/Agents/index')),
      },


      // AIRLINE ADMINS
      {
        path: '/super-admin/airline/admin/new',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Admins/ExistingCompany')),
      },
      {
        path: '/super-admin/airline/admins/list/view',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Admins/AdminList')),
      },
      {
        path: '/super-admin/airline/admins/enrolled/:companyId',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Admins/AdminsEnrolled')),
      },

      // AIRLINE SURCHARGES
      {
        path: '/super-admin/airline/surcharges/surcharge',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Surcharges/Surcharge')),
      },

      // AIRLINE AGENTS
      // {
      //   path: '/super-admin/airline/agents/list/view',
      //   component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Agents')),
      // },

      //AIRLINE COMPANIES
      {
        path: '/super-admin/airline/company/new',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/Airline/Companies/NewCompany/NewCompany'),
        ),
      },
      //AIRLINE SURCHARGE
      {
        path: '/super-admin/airline/admin/sur-charge/create',
        component: lazy(() => import('pages/mainPages/Airline/Admin/SurCharge/Create')),
      },

      //Airline edit Surcharge
      {
        path: '/super-admin/airline/admin/general-rate/surcharge',
        component: lazy(() => import('pages/mainPages/Airline/Admin/GeneralRate/Surcharge')),
      },
      {
        path: '/super-admin/airline/companies/list/view',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/CompanyList'),
        ),
      },
      {
        path: '/super-admin/airline/companies/approved-airlines',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/ApprovedCompanies'),
        ),
      },
      {
        path: '/super-admin/airline/companies/localdb-airlines',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/LocaldbCompanies'),
        ),
      },
      {
        path: '/super-admin/airline/localdb/airline/:id',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/LocalindividualAirratesDetails')),
      },
      {
        path: '/super-admin/airline/localdbairfares/details/update',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/Localdbupdate/UpdateAirfareRatesDetailsInSA')),
      },
      {
        path: '/super-admin/airline/companies/unapproved-airlines',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/UnapprovedCompanies'),
        ),
      },
      {
        path: '/super-admin/airline/companies/add-new-airline',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/Airline/Companies/NewCompany/NewCompany'),
        ),
      },
      {
        path: '/super-admin/airline/airfare',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Airfare')),
      },
      {
        path: '/super-admin/airline/airfares/abc/:id',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/AirfareRatesDetailsInSA')),
      },
      {
        path: '/super-admin/airline/airfares/details/update',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/Update/UpdateAirfareRatesDetailsInSA')),
      },
      {
        path: '/super-admin/airline/companies/update/surcharges',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/UpdateSurcharges')),
      },
      {
        path: '/super-admin/airline/companies/update/localdb/surcharges',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/UpdateLocalDbSurcharges')),
      },

      //SHIPPER
      {
        path: '/super-admin/shippers/list/view',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Shippers')),
      },

      // {
      // 	path: "/super-admin/airline/general-rate/:airfare_id/range-details/view",
      // 	component: lazy(() => import("pages/mainPages/Airline/Admin/GeneralRate/ListGeneralRanges")),
      // },

      // {
      // 	path: "/super-admin/airline/priority-rate/:airfare_id/range-details/view",
      // 	component: lazy(() => import("pages/mainPages/Airline/Admin/PriorityRate/ListPriorityRanges")),
      // },
      // {
      // 	path: "/super-admin/airline/spot-rate/:airfare_id/range-details/view",
      // 	component: lazy(() => import("pages/mainPages/Airline/Admin/SpotRate/ListSpotRanges")),
      // },
      // QUOTATION ROUTES
      // {
      //   path: "/super-admin/quotation/create",
      //   component: lazy(() => import("pages/mainPages/SuperAdmin/Quotation/QuotationCreate")),
      // },
      // {
      //   path: "/super-admin/quotation/list/view",
      //   component: lazy(() => import("pages/mainPages/SuperAdmin/Quotation/QuotationList")),
      // },
      // {
      //   path: "/super-admin/quotation/output",
      //   component: lazy(() => import("pages/mainPages/SuperAdmin/Quotation/QuoteOutput")),
      // },
      // {
      //   path: "/super-admin/quotation/details/:id",
      //   component: lazy(() => import("pages/mainPages/SuperAdmin/Quotation/QuotationDetails")),
      // },
      // {
      //   path: "/super-admin/quotation/goods/details/:id",
      //   component: lazy(() => import("pages/mainPages/SuperAdmin/Quotation/GoodsQuotationDetails")),
      // },

      // Quotation Shippers
      // {
      //   path: "/super-admin/quotation/shippers/requests/list/view",
      //   component: lazy(() => import("pages/mainPages/SuperAdmin/Quotation/ShipperRequest/ShipperRequestList")),
      // },
      // {
      //   path: "/super-admin/quotation/shippers/request/details/:id",
      //   component: lazy(() => import("pages/mainPages/SuperAdmin/Quotation/ShipperRequest/ShipperRequestDetails")),
      // },
      // {
      //   path: "/super-admin/quotation/shippers/request/goods/details/:id",
      //   component: lazy(() => import("pages/mainPages/SuperAdmin/Quotation/ShipperRequest/GoodShipperRequestDetails")),
      // },

      //Directory ROUTES
      // {
      //   path: "/super-admin/directory/address/new",
      //   component: React.lazy(() => import("pages/mainPages/SuperAdmin/Address/AddressAdd")),
      // },
      // {
      //   path: "/super-admin/directory/address/list/view",
      //   component: React.lazy(() => import("pages/mainPages/SuperAdmin/Address/AddressList")),
      // },
      // {
      //   path: "/super-admin/directory/address/edit/:id",
      //   component: React.lazy(() => import("pages/mainPages/SuperAdmin/Address/AddressEdit")),
      // },

      //	SUPER ADMIN STATISTICS
      // {
      //   path: '/super-admin/statistics',
      //   component: lazy(() => import('pages/mainPages/SuperAdmin/Statistics/Statistics')),
      // },
      {
        path: '/super-admin/statistics/main-dashboard',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Statistics/MainDashboard/index')),
      },
      {
        path: '/super-admin/statistics/quote-dashboard',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Statistics/QuoteDashboard/index')),
      },
      {
        path: '/super-admin/statistics/license-dashboard',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Statistics/LicenseDashboard/index')),
      },
      {
        path: '/super-admin/statistics/account-dashboard',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Statistics/AccountDashboard/index')),
      },

      // //	SUPER ADMIN FORWARDERS
      // {
      //   path: "/super-admin/forwarders",
      //   component: lazy(() =>
      //     import("pages/mainPages/SuperAdmin/Statistics/Forwarders/index")
      //   ),
      // },

      // //	SUPER ADMIN SHIPPESRS
      // {
      //   path: "/super-admin/shippers",
      //   component: lazy(() =>
      //     import("pages/mainPages/SuperAdmin/Statistics/Shippers/index")
      //   ),
      // },

      //	SUPER ADMIN QUOTATIONS
      {
        path: '/super-admin/quotations',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Statistics/Quotations/index')),
      },

      //	SUPER ADMIN QUOTATIONS DETAILS
      {
        path: '/super-admin/quotations-details',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/Statistics/Quotations/QuotationDetails'),
        ),
      },

      //	SUPER ADMIN FF Agent
      {
        path: '/super-admin/freightforwarderagent',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/Statistics/FreightForwarderAgent/index'),
        ),
      },

      //	SUPER ADMIN Airline
      {
        path: '/super-admin/airline',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Statistics/Airline/index')),
      },

      //	SUPER ADMIN Airline Agent
      {
        path: '/super-admin/airlineagent',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Statistics/AirlineAgents/index')),
      },

      // Notification
      {
        path: '/super-admin/notification',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/Notification/Notification')),
      },

      // Airport
      {
        path: '/super-admin/admins/airport-management/create/new',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/Airport/Create')),
      },
      {
        path: '/super-admin/admins/airport-management/edit/:airportId',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/Airport/Update')),
      },
      {
        path: '/super-admin/admins/airport-management/list/view',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/Airport/List')),
      },
      {
        path: '/super-admin/airport/list/view',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/Airport/List')),
      },
      {
        path: '/super-admin/admins/airport/airport-list',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/Airport/List')),
      },

      //Feedback List
      {
        path: '/super-admin/feedback/view',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/Feedback/FeedbackList')),
      },

      // TRUCKING
      {
        path: '/super-admin/tracking',
        component: lazy(() => import('pages/mainPages/SuperAdmin/Tracking')),
      },

      //FreightForwardeQuotationList
      {
        path: '/super-admin/freight-forwarde/quotations/list/view',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/FreightForwarder/Quotations/QuoteList/QuotationsList')),
      },
      {
        path: '/super-admin/freight-forwarder/quotation/manual/details',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/ManualQuote/ManualQuoteDetails/index'),
        ),
      },

      //FreightForwardeQuotationDetails
      {
        path: '/super-admin/freight-forwarder/quotation/details',
        component: lazy(() =>
          import('pages/mainPages/SuperAdmin/FreightForwarder/Quotations/QuoteList/PreviousQuote/QuickQuoteOutPut'),

        ),
      },

      //KeyGeneratorFreightForwardeKey
      {
        path: '/super-admin/key-generator/freight-forwarde-key/list/view',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/KeyGenerator/FreightForwarderKeys')),
      },

      //KeyGeneratorAirlineKeys
      {
        path: '/super-admin/key-generator/airline-key/list/view',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/KeyGenerator/AirlineKeys')),
      },

      //Freight Forwarder
      {
        path: '/super-admin/key-generator/freightforwarder/view',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/KeyGenerator/FreightForwarder')),
      },

      //Freight Forwarder Details
      {
        path: '/super-admin/key-generator/freightforwarder/details',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/KeyGenerator/FreightForwarderDetails')),
      },
      {
        path: '/super-admin/key-management/freightforwarder/actionkey/details',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/KeyGenerator/ActionKeyDetails')),
      },

      //Reassign Tab
      {
        path: '/super-admin/key-generator/reassign',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/KeyGenerator/Reassign')),
      },

      {
        path: '/super-admin/key-generator/freightforwarderkey-reassign',
        component: React.lazy(() => import('pages/mainPages/SuperAdmin/KeyGenerator/FreightForwarderKeyReassign')),
      },

      //Tutorial View
      {
        path: '/super-admin/tutorial/addtutorial',
        component: React.lazy(() =>
          import('pages/mainPages/SuperAdmin/Tutorial/ViewTutorial'),
        ),
      },

      //client reassign 
      {
        path: '/super-admin/key-generator/client/add/reassign/:reassignId',
        component: lazy(() => import('pages/mainPages/SuperAdmin/KeyGenerator/AssignReassign')),

      }
    ],
  },
];
