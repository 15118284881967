const init_state = {
      pieChartData:{},
      companyDetail: [],
      subscriptionDetails:[],
      barChartData:[],
      balanceData:{},
      addonsBarsChart:[],
      addonsLineChart:[],
      addonPiechart:{},
      cumlativeChartData:[],
      fullCumlativeFullChart:[],
      superadminCount:{},
      countLoader:false,
      countChart:[],
      cummalativeFullChart:[],
      cummaltiveBarChart:[],
      activesKeyChart:[]

    };
    
    export const AccountManagementReducer = (state = init_state, { type, payload }) => {
      switch (type) {
        case `RETRIEVE_SUBSCRIPTION_DATA`:
          return {
            ...state,
            pieChartData: {...payload},
          };

          case `RETRIEVE_COUNT_FOR_SUPERADMIN`:
            return {
              ...state,
              countLoader:false,
              superadminCount: {...payload},

            };
            case `RETRIEVE_COUNT_FOR_SUPERADMIN_REQ`:
              return {
                ...state,
                countLoader:true,
              };

              case `RETRIEVE_COUNT_FOR_SUPERADMIN_FAIL`:
            return {
              ...state,
              countLoader:false,
            };

            case'RETRIEVE_COUNT_CHART_DATA_FOR_SUPERADMIN':
            
            return {
              ...state,
              countChart:[...payload?.chart]
            }
            case 'RETRIEVE_ACTIVESKEYS_FOR_SUPERADMIN':
              return{
                ...state,
                activesKeyChart:[...payload?.chart]
              }

        case "SUBSCRIPTION_DETAILS":
          return{
            ...state,
            subscriptionDetails:[...payload]
          }
        case `RETRIEVE_COMPANY_LIST`:
          return {
            ...state,
            companyDetail: [...payload],
          };
        case 'BAR_CHART_DATA':
          return{
            ...state,
            barChartData:[...payload]
          }
          case `RETRIEVE_BALANCE_DETAILS`:
          return {
            ...state,
            balanceData: {...payload},
          };
        case 'ADD_CUMLATIVE__LINE_CHART_DATA':
          return{
            ...state,
            cumlativeChartData:[...payload?.chart]
          }
        case 'ADD_ONS_LINE_CHART_DATA':
          return{
            ...state,
            addonsLineChart:[...payload?.chart],
            addonPiechart:{...payload?.total}
          }
          case 'ADD_ONS_BAR_CHART_DATA':
            return{
              ...state,
              addonsBarsChart:[...payload?.chart],
            }

            case 'ADD_FULL_CUMLATIVE__LINE_CHART_DATA':
              return{
                ...state,
                fullCumlativeFullChart:[...payload?.chart]
              }

            case 'ADD_FULL_CUMLATIVE__LINE_CHART_DATA_SUPERADMIN':
              return{
                ...state,
                cummalativeFullChart:[...payload?.chart]
              }
              case 'ADD_ONS_BAR_CHART_DATA_SUPERADMIN':
                return{
                  ...state,
                  cummaltiveBarChart:[...payload?.chart],
                }
  

        default:
          return {
            ...state,
          };
      }
    };