import {
  POST_FFADMIN_QUOTATIONS_PRICE_REQ,
  POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
  POST_FFADMIN_QUOTATIONS_PRICE_FAIL,
  GET_FFADMIN_QUOTATIONS_REQ,
  GET_FFADMIN_QUOTATIONS_SUCCESS,
  GET_FFADMIN_QUOTATIONS_FAIL,
  GET_FFADMIN_QUOTATION_REQ,
  GET_FFADMIN_QUOTATION_SUCCESS,
  GET_FFADMIN_QUOTATION_FAIL,
  CREATE_FFADMIN_QUOTATION_REQ,
  CREATE_FFADMIN_QUOTATION_SUCCESS,
  CREATE_FFADMIN_QUOTATION_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS_REQ,
  GET_FFADMIN_SEARCH_ADDRESS_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS2_REQ,
  GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS2_FAIL,
  GET_REQUEST_QUOTE_REQ,
  GET_REQUEST_QUOTE_SUCCESS,
  GET_REQUEST_QUOTE_FAIL,
  GET_SHIP_FROM_AIRPORT_REQ,
  GET_SHIP_FROM_AIRPORT_SUCCESS,
  GET_SHIP_FROM_AIRPORT_FAIL,
  GET_SHIP_TO_AIRPORT_REQ,
  GET_SHIP_TO_AIRPORT_SUCCESS,
  GET_SHIP_TO_AIRPORT_FAIL,
  GET_SEARCH_BY_ZIP_REQ,
  GET_SEARCH_BY_ZIP_SUCCESS,
  GET_SEARCH_BY_ZIP_FAIL,
  GET_COUNTRY_REQ,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_SHIP_TO_QUICK_AIRPORT_REQ,
  GET_SHIP_TO_QUICK_AIRPORT_SUCCESS,
  GET_SHIP_TO_QUICK_AIRPORT_FAIL,
  CHECK_QUOTE_AVAILABLE_REQ,
  CHECK_QUOTE_AVAILABLE_SUCCESS,
  CHECK_QUOTE_AVAILABLE_FAIL,
  SET_QUOTATION_DATA,
  SEND_QUOTE_BY_EMAIL_REQ,
  SEND_QUOTE_BY_EMAIL_SUCCESS,
  SEND_QUOTE_BY_EMAIL_FAIL,
  GET_AIRPORT_DEPATURE_LIST,
  STORE_RADIO_OPTION,
  GET_EMPTY_COUNTRY_LIST,
  POST_FFADMIN_QUOTATION_PDF_REQ,
  POST_FFADMIN_QUOTATION_PDF_SUCCESS,
  POST_FFADMIN_QUOTATION_PDF_FAIL,
  GET_UPDATED_QUOTATION_LIST,
  GET_SHIPPER_LIST_REQ,
  GET_SHIPPER_LIST_SUCCESS,
  GET_SHIPPER_LIST_FAIL,
  SAVE_QUOTATION_DATA,
  SAVE_CHECKBOX_DATA,
  EDIT_SAVE_QUOTATION,
  EDIT_QUOTATION_REQ,
  EDIT_QUOTATION_SUCCESS,
  EDIT_QUOTATION_FAIL,
  SET_PREVIOUS_STATE,
  SET_UPDATE_PREVIOUS_STATE,
  SET_QUOTATION_UPDATE_UNIT,
  SET_SHIPPER_QUOTATION_REQ,
  SET_SHIPPER_QUOTATION_SUCCESS,
  SET_SHIPPER_QUOTATION_FAIL,
  GET_TRUKING_DATA,
  GET_RECALCULATE_VALUE,
  GET_DELIVERY_ZIPCODE_REQ,
  GET_DELIVERY_ZIPCODE_SUCCESS,
  GET_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATE_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
  GET_UPDATE_DELIVERY_ZIPCODE_REQ,
  GET_UPDATED_PICKUP_ZIPCODE_REQ,
  GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
  GET_UPDATED_PICKUP_ZIPCODE_FAIL,
  GET_UNASSIGNED_LIST_REQ,
  GET_UNASSIGNED_LIST_SUCCESS,
  GET_UNASSIGNED_LIST_FAIL,
  GET_ASSIGNED_LIST_REQ,
  GET_ASSIGNED_LIST_SUCCESS,
  GET_ASSIGNED_LIST_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_REQ,
  SET_CREATE_SHIPPER_QUOTATION_SUCCESS,
  SET_CREATE_SHIPPER_QUOTATION_FAIL,
  SET_INCOMING_SHIPPER_QUOTATION_REQ,
  SET_INCOMING_SHIPPER_QUOTATION_SUCCESS,
  SET_INCOMING_SHIPPER_QUOTATION_FAIL,
  GET_USER_KEY_MANAGEMENT_SUCCESS,
  GET_KEY_COUNT_SUCCESS,
  GET_KEY_USER_ROLE_SELECT_REQ,
  GET_KEY_USER_ROLE_SELECT_SUCCESS,
  GET_KEY_USER_ROLE_SELECT_FAIL,
  GET_NO_0F_QUOTATION,
  GET_KEY_MANAGEMENT_ASSIGNED_KEY_FAIL,
  GET_KEY_MANAGEMENT_ASSIGNED_KEY_REQ,
  GET_KEY_MANAGEMENT_UNASSIGNED_KEY_REQ,
  GET_KEY_MANAGEMENT_UNASSIGNED_KEY_SUCCESS,
  GET_KEY_MANAGEMENT_UNASSIGNED_KEY_FAIL,
  GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS,
  GET_INCOMING_COUNT,
  GET_CLIENT_QUOTATION,
  GET_INCOMING_LIST_FAIL,
  GET_INCOMING_LIST_REQ,
  GET_INCOMING_LIST_SUCCESS,
  GET_INCOMING_QUOTATIONS_DETAIL_REQ,
  GET_INCOMING_QUOTATIONS_DETAIL_FAIL,
  GET_INCOMING_QUOTATIONS_DETAIL_SUCCESS,
  GET_QUOTEANALYS_QUOTAION_LIST_REQ,
  GET_QUOTEANALYS_QUOTAION_LIST_SUCCESS,
  GET_QUOTEANALYS_QUOTAION_LIST_FAIL,
  GET_QUOTEANALYST_QUOTATION_DETAILS_REQ,
  GET_QUOTEANALYST_QUOTATION_DETAILS_SUCCESS,
  GET_QUOTEANALYST_QUOTATION_DETAILS_FAIL,
  GET_ANALYST_USER_FILTER_VALUE,
  GET_ADMIN_USER_PRFOILE,
  DOWNLOAD_STATUS_OF_IMAGE_REQ,
  DOWNLOAD_STATUS_OF_IMAGE_SUCCES,
  DOWNLOAD_STATUS_OF_IMAGE_FAIL,
  GET_FEED_BACK_LIST_REQ,
  GET_FEEDBACK_LIST_SUCCESS,
  GET_FEEDBACL_LIST_FAIL,
  GET_DISCLAIMER_DATA,
  RESET_STATE,
  GET_FFADMIN_REQ_QUOTEFORMODEL_LIST,
  GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
  GET_FFADMIN_FAIL_QUOTEFORMODEL_LIST,
  GET_SAVE_AS_NEW_QUOTATION,
  UPDATE_QUOTATION_RESULT_DATA,
  GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ,
  GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS,
  GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL,
  GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_REQ,
  GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_SUCCESS,
  GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_FAIL,
  GET_DEPARTURE_ZIPCODE,
  UPDATE_VALUE_FOR_STATUS,
  GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME_SUCCESS,
  GET_EMPTY_QUOTATIONAIRLINE_NAME,
  GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME,
  GET_ABORT_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME,
  SET_VERSION_NUMBER,
  GET_VERSION_ID_DATA,
  GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_REQ,
  GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_SUCCESS,
  GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_FAIL,
  GET_AIRLINE_LIST,
  GET_AIRLINE_LIST1,
  GET_MANUAL_QUOTATION_REQ,
  GET_MANUAL_QUOTATION_SUCCESS,
  GET_MANUAL_QUOTATION_FAIL,
  SAVE_MANUAL_QUOTATION_REQ,
  SHARE_MANUAL_QUOTATION_REQ,
  PRINT_MANUAL_QUOTATION_REQ,
  PRINT_MANUAL_QUOTATION_DETAIL_REQ,
  SAVE_MANUAL_QUOTATION_DETAIL_REQ,
  SHARE_MANUAL_QUOTATION_DETAIL_REQ,
  GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD,
  SAVE_DATA_FROM_NEW_QUOTE,
  REMOVE_SAVE_DATA_FROM_NEW_QUOTE,
  GET_ABOUT_VERSION_CONTROL_ALL_DATA,
  GET_ABOUT_VERSION_CONTROL_DATA_BY_ID,
  GET_LOADER_STATUS,
  SAVE_MANUAL_QUOTATION_SUCCESS,
  SAVE_MANUAL_QUOTATION_FAIL,
  PRINT_MANUAL_QUOTATION_SUCCESS,
  PRINT_MANUAL_QUOTATION_FAIL,
  SHARE_MANUAL_QUOTATION_SUCCESS,
  SHARE_MANUAL_QUOTATION_FAIL,
  PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS,
  PRINT_MANUAL_QUOTATION_DETAIL_FAIL,
  SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS,
  SHARE_MANUAL_QUOTATION_DETAIL_FAIL,
  CREATE_TRUKCING_QUOTATION_REQ,
  CREATE_TRUKCING_QUOTATION_SUCCESS,
  CREATE_TRUKCING_QUOTATION_FAIL,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SAVE_AVAILABILITY_MODAL_ACCEPTED,
  SAVED_QUOTATION_ID,
  GET_PRINT_LOADER_STATUS,
  GET_UNIT_MEASUREMENT,
  MEASUREMENTS_SYSYTEM_SUCCESS,
  MEASUREMENTS_SYSYTEM_FAIL,
  MEASUREMENTS_SYSYTEM_REQ,
  SET_MEASUREMENTS_SYSYTEM_SUCCESS,
  SET_MEASUREMENTS_SYSYTEM_FAIL,
  SET_MEASUREMENTS_SYSYTEM_REQ,
  MEASUREMENTS_SYSYTEM_SUCCESS_PDF,
  MEASUREMENTS_SYSYTEM_FAIL_PDF,
  MEASUREMENTS_SYSYTEM_REQ_PDF,
  SET_MEASUREMENTS_SYSYTEM_SUCCESS_PDF,
  SET_MEASUREMENTS_SYSYTEM_FAIL_PDF,
  SET_MEASUREMENTS_SYSYTEM_REQ_PDF,
  SET_FAVROUITE_AIRPORT_REQ,
  SET_FAVROUITE_AIRPORT_SUCCESS,
  SET_FAVROUITE_AIRPORT_FAIL,
  GET_PREFERRED_AIRPORTS_LIST,
  GET_INCOMING_MANAULQUOTE_DETAILS_REQ,
  GET_INCOMING_MANAULQUOTE_DETAILS_SUCCESS,
  GET_INCOMING_MANAULQUOTE_DETAILS_FAIL,
  GET_INCOMING_AGENT_LIST_REQ,
  GET_INCOMING_AGENT_LIST_SUCCESS,
  GET_INCOMING_AGENT_LIST_FAIL,
  RENEW_ADD_ON_REQ,
  RENEW_ADD_ON_SUCCESS,
  RENEW_ADD_ON_FAIL,
  PAYPAL_STATUS,
  SAVE_TRANSACTION_ID,
  SAVE_ADDONS_DATA,
  HANDLE_BACK_BUTTON_IN_KEYMANAGEMENT,
  GET_HOST_REASSIGN_DETAILS_REQ,
  GET_HOST_REASSIGN_DETAILS_SUCCESS,
  GET_HOST_REASSIGN_DETAILS_FAIL
} from '../constants';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UPDATE_LOGO_IMAGEURL, UPDATE_USER_IMAGEURL } from 'shared/constants/ActionTypes';
import { lbsValue, toastDelay } from '../../../../../../../shared/constants/AppConst';
import { getAllNotifications, getNotificationsCount, notificationMenuCountData } from 'redux/actions/Notification';
import { convertStrToNum } from 'shared/utils/ValidationUtils';
import moment from 'moment';

export const getAllQuotation = (page, size, sorting, filtering) => async (dispatch) => {

  const queryParams = {
    page,
    size,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  try {
    dispatch({ type: GET_FFADMIN_QUOTATIONS_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    // const response = await jwtAxios.get(
    //   `/ffadmin/quotations-list?page=${page}&size=${size}`,
    //   config,
    // );


    const response = await jwtAxios.get('/ffadmin/quotations-list', {
      ...config,
      params: queryParams,
    });

    // console.log("response");
    // console.log(response);
    if (response.status === 200) {
      dispatch({
        type: GET_FFADMIN_QUOTATIONS_SUCCESS,
        payload: { ...response.data },
      });
      return new Promise((resolve, _) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_FFADMIN_QUOTATIONS_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getQuoteInquirerDeatilsByID = (inquirerID) => async (dispatch) => {
  try {
    dispatch({ type: GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/ffadmin/quotation-inquirer/${inquirerID}`, config);

    if (response.status === 200) {
      const inquirerNamesByCompanyNameFinalNew = [];

      if (response?.data?.companyName) {
        inquirerNamesByCompanyNameFinalNew.push({
          companyName: response?.data?.companyName,
          details: response?.data?.companyName,
        });
      }

      if (response?.data?.nameofInquirer) {
        inquirerNamesByCompanyNameFinalNew.push({
          nameofInquirer: response?.data?.nameofInquirer,
          details: response?.data?.nameofInquirer,
        });
      }

      if (response?.data?.companyType) {
        inquirerNamesByCompanyNameFinalNew.push({
          companyType: response?.data?.companyType,
          details: response?.data?.companyType,
        });
      }

      if (response?.data?.country) {
        inquirerNamesByCompanyNameFinalNew.push({
          country: response?.data?.country,
          details: response?.data?.country,
        });
      }

      if (response?.data?.email) {
        inquirerNamesByCompanyNameFinalNew.push({
          email: response?.data?.email,
          details: response?.data?.email,
        });
      }

      if (response?.data?.phone) {
        inquirerNamesByCompanyNameFinalNew.push({
          phone: response?.data?.phone,
          details: response?.data?.phone,
        });
      }

      if (response?.data?.id) {
        inquirerNamesByCompanyNameFinalNew.push({
          id: response?.data?.id,
          details: response?.data?.id,
        });
      }

      dispatch({
        type: GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD,
        payload: inquirerNamesByCompanyNameFinalNew,
      });

      dispatch({
        type: GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_SUCCESS,
        payload: { ...response.data },
      });
      return new Promise((resolve, _) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_FAIL });
    toast.error('Something went wrong!');
  }
};

export const saveQuotationPriceItem = (data) => async (dispatch) => {
  try {
    dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios({
      url: `/ffadmin/quotation/save?request_id=${data.request_id}&quote_id=${data.quote_id}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
        payload: response.data,
      });
      toast.success('Quotation Items saved.');
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_FAIL });
    toast.error(error.response.data.title);
  }
};

export const sendClientMailMessage = (data) =>
  async (dispatch) => {

    let post_data = {
      email: data?.email,
      msg: data?.msg,
      sender: data?.sender,
      receiver: data?.receiver,
    }

    try {
      const response = await jwtAxios({
        url: `/send/email`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: post_data,
      });

      if (response.status === 200) {
        toast.success('Message sent successfully');
        return new Promise((resolve, _) => {
          resolve(response);
        });
      }
    } catch (err) {
      if (err.response?.data?.detail != undefined) {
        toast.error(err.response.data?.detail, { autoClose: 3500 });
        return new Promise((resolve, reject) => {
          reject(err.response);
        });
      } else {
        toast.error('Internal Server Error');
        return new Promise((resolve, reject) => {
          reject(err.response);
        });
      }

    }
  };

















export const sendClientReplyMessage = (data) =>
  async (dispatch) => {

    let post_data = {
      email: data?.email,
      msg: data?.msg,
    }

    try {
      const response = await jwtAxios({
        url: `/send/msg`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: post_data,
      });

      if (response.status === 200) {
        toast.success('Message sent successfully');
        return new Promise((resolve, _) => {
          resolve(response);
        });
      }
    } catch (err) {
      if (err.response?.data?.detail != undefined) {
        toast.error(err.response.data?.detail, { autoClose: 3500 });
        return new Promise((resolve, reject) => {
          reject(err.response);
        });
      } else {
        toast.error('Internal Server Error');
        return new Promise((resolve, reject) => {
          reject(err.response);
        });
      }

    }
  };

export const createQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FFADMIN_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/quotation/create', data, config);

    if (response.status === 200) {
      dispatch({
        type: CREATE_FFADMIN_QUOTATION_SUCCESS,
        payload: response.data,
      });
      // dispatch({
      //   type: GET_SEARCH_BY_ZIP_SUCCESS,
      //   payload: ''
      // });
      // dispatch({
      //   type: GET_DELIVERY_ZIPCODE_SUCCESS,
      //   payload:'',
      // });
      if (response.data.truckingCarriers.length != 0)
        dispatch({
          type: GET_TRUKING_DATA,
          payload: response.data.truckingCarriers,
        });
      // toast.success("Quotation created");

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
    // toast.error(error.response.data.title, { autoClose: 3500 });
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const getTruckingCarriersDetails = (Rid, truckingCarriersId, truckingCarrierData) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        url: `ffadmin/quotation?request_id=${Rid}&trucking_id=${truckingCarriersId}`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // truckingCarrierData.economicsRates = res?.data?.economicsRates;

      let truckingCarriers = truckingCarrierData;

      if (res.status === 200) {
        dispatch({
          type: CREATE_FFADMIN_QUOTATION_SUCCESS,
          payload: { ...res?.data, truckingCarriers },
          // payload: res?.data,
        });
        toast.success('Quotation updated successfully');
      }
    } catch (error) {
      // console.log(error);
      // toast.error(`Internal Server Error`);
      if (error.res != undefined) {
        toast.error(error.res, { autoClose: 5000 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

export const updateQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FFADMIN_QUOTATION_REQ });

    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'loading' });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/quotation/create', data, config);

    if (response.status === 200) {
      dispatch({
        type: CREATE_FFADMIN_QUOTATION_SUCCESS,
        payload: response.data,
      });
      if (response.data.truckingCarriers.length != 0) {
        dispatch({
          type: GET_TRUKING_DATA,
          payload: response.data.truckingCarriers,
        });
        dispatch({ type: GET_RECALCULATE_VALUE });
      }
      toast.success('Quotation updated successfully');

      dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'Recalculate Handle successfully' });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
    // toast.error(error.response.data.title);
    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'Recalculate Handle Bad Request' })
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const updateSaveQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/saved-quotation/update', data, config);
    if (response.status === 200) {
      dispatch({
        type: EDIT_QUOTATION_SUCCESS,
        payload: response.data,
      });

      if (response.data.truckingCarriers.length != 0)
        dispatch({
          type: GET_TRUKING_DATA,
          payload: response.data.truckingCarriers,
        });
      toast.success('Quotation updated successfully');

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: EDIT_QUOTATION_FAIL });
    toast.error(error.response.data.title);
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};
// Testing purpose

export const getPricedQuote = () => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FFADMIN_QUOTATION_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/quotation/replays/598', {}, config);

    if (response.status === 200) {
      dispatch({
        type: CREATE_FFADMIN_QUOTATION_SUCCESS,
        payload: response.data,
      });
      // toast.success("Quotation created");

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
    toast.error(error.response.data.title);
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const getQuotation = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_FFADMIN_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/ffadmin/quotation/${id}`, config);

    if (response.status === 200) {
      dispatch({ type: GET_FFADMIN_QUOTATION_SUCCESS, payload: response.data });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_FFADMIN_QUOTATION_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getShipperQuotationDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_SHIPPER_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/ffadmin/client/quotation-request/${id}`, config);

    if (response.status === 200) {
      dispatch({ type: SET_SHIPPER_QUOTATION_SUCCESS, payload: response.data });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: SET_SHIPPER_QUOTATION_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getIncomingShipperQuotationDetail = (quoteId) => async (dispatch) => {
  try {
    dispatch({ type: SET_INCOMING_SHIPPER_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/ffcompany/client-quotation/${quoteId}`, config);

    if (response.status === 200) {
      dispatch({ type: SET_INCOMING_SHIPPER_QUOTATION_SUCCESS, payload: response.data });
      dispatch(getNotificationsCount());
      dispatch(getAllNotifications());
      dispatch(notificationMenuCountData());
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: SET_INCOMING_SHIPPER_QUOTATION_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getCreateShipperQuotationDetail = (id) => async (dispatch) => {
  try {
    // dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_REQ });

    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${localStorage.getItem('token')}`,
    //   },
    // };
    // const response = await jwtAxios.post(`/ffadmin/quotation/create/${id}`, config);

    // if (response.status === 200) {
    //   dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_SUCCESS, payload: response.data });
    // }

    dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_REQ });

    const res = await jwtAxios({
      url: `/ffadmin/quotation/create/${id}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      // data: {
      //   ...post_data,
      // },
    });

    if (200 <= res.status && res.status < 300) {
      dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_SUCCESS, payload: res.data });
      // toast.success('Record Created Successfully');
    } else {
      toast.error(`Internal Server Error`);
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_FAIL });
    toast.error('Something went wrong!');
  }
};

export const searchFromAddress = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: GET_FFADMIN_SEARCH_ADDRESS_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(
      `/ffadmin/search/directory`,
      { search: searchQuery },
      config,
    );

    if (response.status === 200) {
      dispatch({
        type: GET_FFADMIN_SEARCH_ADDRESS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_FFADMIN_SEARCH_ADDRESS_FAIL });
    toast.error('Something went wrong!');
  }
};

export const searchToAddress = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: GET_FFADMIN_SEARCH_ADDRESS2_REQ });

    dispatch({
      type: GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS,
      payload: [],
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(
      `/ffadmin/quotation-inquirer/search`,
      { search: searchQuery },
      config,
    );

    if (response.status === 200) {
      dispatch({
        type: GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS,
        payload: response.data,
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_FFADMIN_SEARCH_ADDRESS2_FAIL });
    toast.error('Something went wrong!');
  }
};

export const searchInquirerNameByCompany = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(
      '/ffadmin/quotation-inquirer/company-name-inquirer-list',
      { search: searchQuery },
      config,
    );

    if (response.status === 200) {
      dispatch({
        type: GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL });
    toast.error('Something went wrong!');
  }
};

export const searchInquirerDetailsByInquirerName = (
  searchQuery,
  inquirerNameToGetDetails,
) => async (dispatch) => {
  try {
    dispatch({ type: GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(
      `/ffadmin/quotation-inquirer/${searchQuery}/${inquirerNameToGetDetails}`,
      config,
    );

    if (response.status === 200) {
      dispatch({
        type: GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_SUCCESS,
        payload: response.data,
      });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_FAIL });
    toast.error('Something went wrong!');
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const getReqQuotation = () => async (dispatch) => {
  try {
    dispatch({ type: GET_REQUEST_QUOTE_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get('/ffadmin/client/quotation-request/list', config);

    if (response.status === 200) {
      dispatch({
        type: GET_REQUEST_QUOTE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_REQUEST_QUOTE_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getShipFromAirportList = (zipcode) => async (dispatch) => {
  try {
    dispatch({ type: GET_SHIP_FROM_AIRPORT_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(`/near-airport/search?zipcode=${zipcode}`, {}, config);

    if (response.status === 200) {
      dispatch({
        type: GET_SHIP_FROM_AIRPORT_SUCCESS,
        payload: response.data,
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_SHIP_FROM_AIRPORT_FAIL });
    // toast.error("Something went wrong!");
  }
};

export const getShipToAirportList = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_SHIP_TO_AIRPORT_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(`/airport/search`, { search: data }, config);

    if (response.status === 200) {
      dispatch({
        type: GET_SHIP_TO_AIRPORT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_SHIP_TO_AIRPORT_FAIL });
    // toast.error('Something went wrong!');
  }
};

export const getShipToQuickAirportList = (data) => async (dispatch) => {
  try {
    if (data === '' || data == null) {
      dispatch({ type: GET_EMPTY_COUNTRY_LIST });
    } else {
      dispatch({ type: GET_SHIP_TO_QUICK_AIRPORT_REQ });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.post(`/airport/search`, { search: data, country: 'ALL', flag: 'search' }, config);

      if (response.status === 200) {
        dispatch({
          type: GET_SHIP_TO_QUICK_AIRPORT_SUCCESS,
          payload: response.data,
        });
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_SHIP_TO_QUICK_AIRPORT_FAIL });
    toast.error('Something went wrong!');
  }
};

// export const getAddressByZip = (zipcode) => async (dispatch) => {
//   if (zipcode != '') {
//     try {
//       dispatch({ type: GET_SEARCH_BY_ZIP_REQ });

//       const config = {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         },
//       };
//       // const response = await jwtAxios.get(
//       //   `/quotation/search/zipcode?zipcode=${zipcode}`,
//       //   // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//       //   config
//       // );
//       const response = await axios.get(
//         // `/quotation/search/zipcode?zipcode=${zipcode}`,
//         // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//         `api/zipcode?zipcode=${zipcode}`,
//         config,
//       );

//       if (response.status === 200) {
//         dispatch({
//           type: GET_SEARCH_BY_ZIP_SUCCESS,
//           payload: response.data,
//         });
//         return new Promise((resolve, reject) => {
//           resolve(response);
//         });
//       }
//     } catch (error) {
//       console.log(error.message);
//       toast.error('Zipcode not found Please enter the data Manaualy');
//       dispatch({ type: GET_SEARCH_BY_ZIP_FAIL });
//       return new Promise((resolve, reject) => {
//         reject(error);
//       });
//       // toast.error("Something went wrong!");
//     }
//   } else {
//     dispatch({
//       type: GET_SEARCH_BY_ZIP_SUCCESS,
//       payload: '',
//     });
//   }
// };

export const getAddressByZip = (data) => {
  return async (dispatch, getState) => {
    if (data != '') {

      dispatch({ type: GET_SEARCH_BY_ZIP_REQ });

      try {
        const response = await jwtAxios({
          method: 'get',
          url: `/zipcode?zipcode=${data}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          dispatch({
            type: GET_SEARCH_BY_ZIP_SUCCESS,
            payload: response.data,
          });
          const hasEmptyObject = response.data.some(obj => Object.keys(obj).length === 0);
          if (hasEmptyObject)
            toast.error('Zipcode not available');
          return new Promise((resolve, reject) => {
            resolve(response);
          });
        }
      } catch (error) {
        // console.log(error.message);
        toast.error('Zipcode not found Please enter the data Manaualy');
        dispatch({ type: GET_SEARCH_BY_ZIP_FAIL });
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    };

    if (data === '')
      dispatch({
        type: GET_SEARCH_BY_ZIP_SUCCESS,
        payload: '',
      });

  }
};

export const getShipToCountries = () => async (dispatch) => {
  try {
    dispatch({ type: GET_COUNTRY_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`country-list`, config);

    if (response.status === 200) {
      dispatch({
        type: GET_COUNTRY_SUCCESS,
        payload: response.data,
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_COUNTRY_FAIL });
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // toast.error("Something went wrong!");
  }
};

export const checkQuoteAvailable = (zipcode, airportCode) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_QUOTE_AVAILABLE_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    // const response = await jwtAxios.get(
    //   `/airlines/search?zipcode=${zipcode}&airport_code=${airportCode}`,
    //   config
    // );

    const response = await jwtAxios.get(
      `/airlines/airport-to-airport/search?from_airport=${zipcode}&to_airport=${airportCode}`,
      config,
    );

    if (response.status === 200) {
      dispatch({
        type: CHECK_QUOTE_AVAILABLE_SUCCESS,
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: CHECK_QUOTE_AVAILABLE_FAIL });
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // toast.error("Something went wrong!");
  }
};

export const setQuotationDataReq = (data) => (dispatch) => {
  dispatch({ type: SET_QUOTATION_DATA, payload: data });
};

// export const saveQuotationDataReq = (data, unit, date, airpDep, airpDest, quoteType) => (
export const saveQuotationDataReq = (data, unit, date, airpDep, airpDest, quoteInquirerId) => (dispatch,
) => {
  dispatch({
    type: SAVE_QUOTATION_DATA,
    payload: { data, unit, date, airpDep, airpDest, quoteInquirerId },
  });
};

export const sendQuoteByEmail = (data) => async (dispatch) => {
  try {
    dispatch({ type: SEND_QUOTE_BY_EMAIL_REQ });

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // };

    // const response = await jwtAxios.post(
    //   "/ffadmin/quotation/email",
    //   data,
    //   config
    // );
    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'loading' });

    const response = await jwtAxios({
      url: `/ffadmin/quotation/email?request_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&airline_show=${data?.radioDataForAirlineDrop}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: SEND_QUOTE_BY_EMAIL_SUCCESS,
      });
      toast.success('Email sent successfully!');

      dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'Share Handle successfully' });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: SEND_QUOTE_BY_EMAIL_FAIL });
    toast.error(error.response.data.title);

    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'Share Handle Bad Request' })
  }
};

export const sendQuoteByEmailClient = (data) => async (dispatch) => {
  try {
    dispatch({ type: SEND_QUOTE_BY_EMAIL_REQ });

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // };

    // const response = await jwtAxios.post(
    //   "/ffadmin/quotation/email",
    //   data,
    //   config
    // );
    const response = await jwtAxios({
      url: `/ffclient/quotation/email?request_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&airline_show=${data?.radioDataForAirlineDrop}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: SEND_QUOTE_BY_EMAIL_SUCCESS,
      });
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: SEND_QUOTE_BY_EMAIL_FAIL });
    toast.error(error.response.data.title);
  }
};

export const sendQuoteByEmailShipper = (data) => async (dispatch) => {
  try {
    dispatch({ type: SEND_QUOTE_BY_EMAIL_REQ });

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // };

    // const response = await jwtAxios.post(
    //   "/ffadmin/quotation/email",
    //   data,
    //   config
    // );
    const response = await jwtAxios({
      url: `/ffclient/quotation/email?request_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: SEND_QUOTE_BY_EMAIL_SUCCESS,
      });
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: SEND_QUOTE_BY_EMAIL_FAIL });
    toast.error(error.response.data.title);
  }
};

export const getAirportOfdepartureList = (values) => async (dispatch) => {
  if (values)
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const data = {
        search: values,
        country: 'USA',
        flag: 'search'
      };
      const response = await jwtAxios.post('/airport/search', data, config);

      if (response.status === 200) {
        dispatch({
          type: GET_AIRPORT_DEPATURE_LIST,
          payload: response.data,
        });
      }
      return new Promise((resolve, reject) => {
        resolve(response)
      })
    } catch (error) {
      // console.log(error.message);
      // toast.error(error.response.data.title);
    }
  else {

  }
};

export const getRadioOption = (useroption) => async (dispatch) => {
  dispatch({ type: STORE_RADIO_OPTION, payload: useroption });
};

export const saveCheckBoxOption = (option) => async (dispatch) => {
  dispatch({ type: SAVE_CHECKBOX_DATA, payload: option });
};

export const printQuotationPriceItem = (data) => async (dispatch) => {
  try {
    dispatch({ type: POST_FFADMIN_QUOTATION_PDF_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/quotation/pdf', data, config);

    if (response.status === 200) {
      dispatch({
        type: POST_FFADMIN_QUOTATION_PDF_SUCCESS,
        payload: response.data,
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // toast.success("Pdf Items received successfully.");
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: POST_FFADMIN_QUOTATION_PDF_FAIL });
    toast.error(error.response.data.title);
  }
};

export const updatedQuotationList = (data) => async (dispatch) => {
  try {
    // dispatch({ type:GET_UPDATED_QUOTATION_LIST  });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/quotation/edit', data, config);

    if (response.status === 200) {
      dispatch({
        type: GET_UPDATED_QUOTATION_LIST,
        payload: response.data,
      });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // toast.success("Pdf Items received successfully.");
    }
  } catch (error) {
    // console.log(error.message);
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // dispatch({ type: POST_FFADMIN_QUOTATION_PDF_FAIL });
  }
};

export const updatePreviousQuotationList = (data) => async (dispatch) => {
  try {
    // dispatch({ type:GET_UPDATED_QUOTATION_LIST  });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/quotation/edit', data, config);

    if (response.status === 200) {
      dispatch({
        type: GET_UPDATED_QUOTATION_LIST,
        payload: response.data,
      });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // toast.success("Pdf Items received successfully.");
    }
  } catch (error) {
    // console.log(error.message);
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // dispatch({ type: POST_FFADMIN_QUOTATION_PDF_FAIL });
  }
};

export const updatededitQuotationList = (data) => async (dispatch) => {
  try {
    // dispatch({ type:GET_UPDATED_QUOTATION_LIST  });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/saved-quotation/edit', data, config);

    if (response.status === 200) {
      dispatch({
        type: EDIT_SAVE_QUOTATION,
        payload: response.data,
      });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // toast.success("Pdf Items received successfully.");
    }
  } catch (error) {
    // console.log(error.message);
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // dispatch({ type: POST_FFADMIN_QUOTATION_PDF_FAIL });
  }
};

export const getShipperList = (page, size, status) => async (dispatch) => {
  try {
    dispatch({ type: GET_SHIPPER_LIST_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    var response = await jwtAxios.get(
      `/ffadmin/client/quotation-request/list?page=${page}&size=${size}&status=${status}`,
      config,
    );

    if (response.status === 200) {
      dispatch({ type: GET_SHIPPER_LIST_SUCCESS, payload: response.data });

      // toast.success('Sh approved.');
      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // dispatch(getRequestedPackage());
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_SHIPPER_LIST_FAIL });
    toast.error('Something went wrong.');
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const getIncomingShipperList = (filter, statusDropfilter, page, size, sorting, filtering) => async (dispatch) => {
  const queryParams = {
    // date_filter: filter,
    // status: statusDropfilter,
    page,
    size,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  try {
    dispatch({ type: GET_SHIPPER_LIST_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    // var response = await jwtAxios.get(
    //   `/ffcompany/recent/quotations-lists?filter=${filter}&status=${statusDropfilter}`,
    //   config,
    // );

    const response = await jwtAxios.get(`/ffcompany/recent/quotations-lists?date_filter=${filter}&status=${statusDropfilter}`, {
      ...config,
      params: queryParams,
    });

    if (response.status === 200) {
      dispatch({ type: GET_SHIPPER_LIST_SUCCESS, payload: response.data });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // dispatch(getRequestedPackage());
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_SHIPPER_LIST_FAIL });
    toast.error('Something went wrong.');
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const getPreviousState = (data) => (dispatch) => {
  dispatch({ type: SET_PREVIOUS_STATE, payload: data });
};

export const getUpdatePreviousState = (data) => (dispatch) => {
  dispatch({ type: SET_UPDATE_PREVIOUS_STATE, payload: data });
};

export const getQuotationUpdateUnit = (data) => (dispatch) => {
  dispatch({ type: SET_QUOTATION_UPDATE_UNIT, payload: data });
};

export const sendSaveQuoteByEmail = (data) => async (dispatch) => {
  try {
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // };

    // const response = await jwtAxios.post(
    //   "/ffadmin/quotation/email",
    //   data,
    //   config
    // );
    const response = await jwtAxios({
      // url: `/ffadmin/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      url: `/ffclient/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      // url: `/ffclient/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&airline_show=${data?.radioDataForAirlineDrop}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.response.data.title);
  }
};

export const sendSaveQuoteByEmailShipper = (data) => async (dispatch) => {
  try {
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // };

    // const response = await jwtAxios.post(
    //   "/ffadmin/quotation/email",
    //   data,
    //   config
    // );
    const response = await jwtAxios({
      // url: `/ffadmin/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      url: `/ffclient/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}`,
      // url: `/ffclient/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&airline_show=${data?.radioDataForAirlineDrop}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.response.data.title);
  }
};

export const sendSaveQuotationByEmail = (data) => async (dispatch) => {
  try {
    const response = await jwtAxios({
      url: `/ffadmin/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status === 200) {
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.response.data.title);
  }
};

export const sendSaveIncomingQuoteByEmail = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'loading' });

    const response = await jwtAxios({
      // url: `/ffadmin/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      url: `/ffcompany/client-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      toast.success('Email sent successfully!');

      dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'Share Handle successfully' });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.response.data.title);

    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'Share Handle Bad Request' })
  }
};

export const saveIncomingQuoteByEmailForQuoteAnalysis = (data) => async (dispatch) => {
  try {
    const response = await jwtAxios({
      // url: `/ffadmin/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      // url: `/ffcompany/client-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      url: `ffcompany/quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.response.data.title);
  }
};

export const sendRejectIncomingQuoteByEmail = (data) => async (dispatch) => {
  try {
    const response = await jwtAxios({
      url: `/ffadmin/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&version=${data.version}&airline_show=${data?.radioDataForAirlineDrop}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.response.data.title);
  }
};

// export const sendRejectAndReplyQuoteAPI = (data) => async (dispatch) => {
//   try {
//     const response = await jwtAxios({
//       url: `/ffadmin/quotation/reject-replay`,
//       method: 'post',
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('token')}`,
//         data,
//       },
//     });

//     if (response.status === 200) {
//       toast.success('Email sent successfully!');
//     }
//   } catch (error) {
//     console.log(error.message);
//     toast.error(error.response.data.title);
//   }
// };

export const sendRejectAndReplyQuoteAPI = (data) => {
  // const post_data = {
  //   airlineCompanyId: DATA.rangeDetails[0].airlineCompanyId,
  //   ratesType: 'RATE_GENERAL',
  //   fromStations: DATA.fromAirport.map((item) => item.label.split(',')[0]),

  //   toStation: DATA.toAirport.label.split(",")[0],
  //   typeOfGoods: DATA.goodsCategory,
  //   minCharges: convertStrToNum(DATA.minCharge),
  //   timeDuration: DATA.timeDuration,

  //   perUnitCharges: DATA.rangeDetails.map((item) => ({
  //     lowerRange: item.lowerRange,
  //     upperRange: item.upperRange,
  //     perUnitCharge: parseFloat(item.perUnitCharge.replace(/,/g, '')),
  //   })),

  //   fuelSurcharge: convertStrToNum(DATA.fuelSurcharge),
  //   securitySurcharge: convertStrToNum(DATA.securitySurcharge),
  //   screeningFee: convertStrToNum(DATA.screeningFee),
  //   eawbFee: convertStrToNum(DATA.eawbFee),
  //   mawbFee: convertStrToNum(DATA.mawbFee),
  //   hawbFee: convertStrToNum(DATA.hawbFee),
  //   unNumberFee: convertStrToNum(DATA.unNumberFee),
  //   additionalUnNumberFee: convertStrToNum(DATA.additionalUnNumberFee),
  //   airlineSurcharges: [
  //     ...DATA.extra_surcharge_list.map((item) => ({
  //       additionSuchargeLabale: item.extraSurchargeName,
  //       additionSuchargeValue: convertStrToNum(item.extraSurchargeValue),
  //     })),
  //   ],

  //   // perUNNumberFee: DATA.unNumberList.map((item) => ({
  //   //   unNumber: item.unNumber,
  //   //   charges: item.charge,
  //   // })),
  //   // airlineSurcharges: DATA.extra_surcharge_list.map((item) => ({
  //   //   additionSuchargeLabale: item.extraSurchargeName,
  //   //   additionSuchargeValue: item.extraSurchargeValue.replace(/,/g, ''),
  //   // })),
  // };

  return async (dispatch, getState) => {
    try {
      // console.log(post_data.perUNNumberFee);
      const res = await jwtAxios({
        // url: '/airfare/create',
        url: `/ffadmin/quotation/reject-reply`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          ...data,
        },
      });

      if (res.status === 200) {
        toast.success('Quotation Rejected successfully!');
        if (data?.type?.toLowerCase() == 'manual') {
          dispatch(getIncomingQuoteManualQuotation(data?.quoteId))

        }
        else
          dispatch(getIncomingShipperQuotationDetail(data?.quoteId));

      }

      // if (200 <= res.status && res.status < 300) {
      //   toast.success('Record Created Successfully');
      // } else {
      //   toast.error(`Internal Server Error`);
      // }
    } catch (error) {
      // if (error.message == "Request failed with status code 404") {
      //   console.log("Airport already exists." + err.message + " abc " + err.response);
      // }
      if (error.response?.data != undefined) {
        toast.error(error.response.data.detail, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

export const sendAcceptAndReplyQuoteAPI = (data) => {
  // const post_data = {
  //   airlineCompanyId: DATA.rangeDetails[0].airlineCompanyId,
  //   ratesType: 'RATE_GENERAL',
  //   fromStations: DATA.fromAirport.map((item) => item.label.split(',')[0]),

  //   toStation: DATA.toAirport.label.split(",")[0],
  //   typeOfGoods: DATA.goodsCategory,
  //   minCharges: convertStrToNum(DATA.minCharge),
  //   timeDuration: DATA.timeDuration,

  //   perUnitCharges: DATA.rangeDetails.map((item) => ({
  //     lowerRange: item.lowerRange,
  //     upperRange: item.upperRange,
  //     perUnitCharge: parseFloat(item.perUnitCharge.replace(/,/g, '')),
  //   })),

  //   fuelSurcharge: convertStrToNum(DATA.fuelSurcharge),
  //   securitySurcharge: convertStrToNum(DATA.securitySurcharge),
  //   screeningFee: convertStrToNum(DATA.screeningFee),
  //   eawbFee: convertStrToNum(DATA.eawbFee),
  //   mawbFee: convertStrToNum(DATA.mawbFee),
  //   hawbFee: convertStrToNum(DATA.hawbFee),
  //   unNumberFee: convertStrToNum(DATA.unNumberFee),
  //   additionalUnNumberFee: convertStrToNum(DATA.additionalUnNumberFee),
  //   airlineSurcharges: [
  //     ...DATA.extra_surcharge_list.map((item) => ({
  //       additionSuchargeLabale: item.extraSurchargeName,
  //       additionSuchargeValue: convertStrToNum(item.extraSurchargeValue),
  //     })),
  //   ],

  //   // perUNNumberFee: DATA.unNumberList.map((item) => ({
  //   //   unNumber: item.unNumber,
  //   //   charges: item.charge,
  //   // })),
  //   // airlineSurcharges: DATA.extra_surcharge_list.map((item) => ({
  //   //   additionSuchargeLabale: item.extraSurchargeName,
  //   //   additionSuchargeValue: item.extraSurchargeValue.replace(/,/g, ''),
  //   // })),
  // };

  return async (dispatch, getState) => {
    try {
      // console.log(post_data.perUNNumberFee);
      const res = await jwtAxios({
        // url: '/airfare/create',
        url: `/ffadmin/quotation/accept-reply`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          ...data,
        },
      });

      if (res.status === 200) {
        toast.success('Quotation Accepted successfully!');
        if (data?.type?.toLowerCase() == 'manual')
          dispatch(getIncomingQuoteManualQuotation(data?.quoteId))
        else
          dispatch(getIncomingShipperQuotationDetail(data?.quoteId));
      }

      // if (200 <= res.status && res.status < 300) {
      //   toast.success('Record Created Successfully');
      // } else {
      //   toast.error(`Internal Server Error`);
      // }
    } catch (error) {
      // if (error.message == "Request failed with status code 404") {
      //   console.log("Airport already exists." + err.message + " abc " + err.response);
      // }
      if (error.response?.data != undefined) {
        toast.error(error.response.data.detail, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

export const retrivePreAirportOfDepatureDetails = (airportDept) => async (dispatch, getState) => {
  if (airportDept != '') {
    try {
      const res = await jwtAxios({
        method: 'POST',
        url: `airport/${airportDept}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({
        type: 'RETRIEVE_PRE_AIRPORT_OF_DEPARTURE_DETAILS',
        payload: res.data?.airport,
      });
    } catch (err) {
      toast.error('Internal Server Error');
    }
  }
  else {
    dispatch({
      type: 'RETRIEVE_PRE_AIRPORT_OF_DEPARTURE_DETAILS',
      payload: '',
    });
  }
};

export const retrivePreAirportOfDestinationDetails = (airportDest) => async (
  dispatch,
  getState,
) => {
  if (airportDest != '') {
    try {
      const res = await jwtAxios({
        method: 'POST',
        url: `airport/${airportDest}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({
        type: 'RETRIEVE_PRE_AIRPORT_OF_DESTINATION_DETAILS',
        payload: res.data?.airport,
      });
    } catch (err) {
      toast.error('Internal Server Error');
    }
  }
  else {
    dispatch({
      type: 'RETRIEVE_PRE_AIRPORT_OF_DESTINATION_DETAILS',
      payload: '',
    });
  }
};



export const combinedPdfsOutput = (quoteId, previousQuotationData, previousQuotationData2) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'loading' });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios({
      // url: `/ffadmin/quotation/combine-pdf?request_id=${quoteId}&quote_id=${decodeURIComponent(checkedQuoteIdsString)}&airline_show=yes`,
      url: `/ffadmin/saved-quotation/combine-pdf?quotation_id=${quoteId}&quote_id=${previousQuotationData},${previousQuotationData2}&airline_show=yes&version=current`,
      method: 'post',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({ type: GET_PRINT_LOADER_STATUS, payload: false });
      toast.success('Printed Successfully');

      return new Promise((resolve, rejecct) => {
        resolve(response)
      })
    }
  } catch (error) {
    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: false });
    toast.error(error?.response?.data?.title);
  }
};

// export const getDeliveryAddressByZip = (zipcode) => async (dispatch) => {
//   if (zipcode != '') {
//     try {
//       dispatch({ type: GET_DELIVERY_ZIPCODE_REQ });

//       const config = {
//         headers: {
//           // Authorization: `Bearer ${localStorage.getItem("token")}`,
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         },
//       };
//       // const response = await jwtAxios.get(
//       //   `/quotation/search/zipcode?zipcode=${zipcode}`,
//       //   // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//       //   config
//       // );
//       const response = await axios.get(
//         // `/quotation/search/zipcode?zipcode=${zipcode}`,
//         // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//         `https://api.zippopotam.us/us/${zipcode}`,
//         config,
//       );

//       if (response.status === 200) {
//         dispatch({
//           type: GET_DELIVERY_ZIPCODE_SUCCESS,
//           payload: response.data,
//         });
//         return new Promise((resolve, reject) => {
//           resolve(response);
//         });
//       }
//     } catch (error) {
//       console.log(error.message);
//       toast.error('Zipcode not found Please enter the data Manaualy');
//       dispatch({ type: GET_DELIVERY_ZIPCODE_FAIL });
//       return new Promise((resolve, reject) => {
//         reject(error);
//       });
//       // toast.error("Something went wrong!");
//     }
//   } else {
//     dispatch({
//       type: GET_DELIVERY_ZIPCODE_SUCCESS,
//       payload: '',
//     });
//   }
// };

export const getDeliveryAddressByZip = (data) => {
  return async (dispatch, getState) => {
    if (data != '') {

      dispatch({ type: GET_DELIVERY_ZIPCODE_REQ });

      try {
        const response = await jwtAxios({
          method: 'get',
          url: `/zipcode?zipcode=${data}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          dispatch({
            type: GET_DELIVERY_ZIPCODE_SUCCESS,
            payload: response.data,
          });
          const hasEmptyObject = response.data.some(obj => Object.keys(obj).length === 0);
          if (hasEmptyObject)
            toast.error('Zipcode not available');
          return new Promise((resolve, reject) => {
            resolve(response);
          });
        }
      } catch (error) {
        // console.log(error.message);
        toast.error('Zipcode not found Please enter the data Manaualy');
        dispatch({ type: GET_DELIVERY_ZIPCODE_FAIL });
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    };

    if (data === '')
      dispatch({
        type: GET_DELIVERY_ZIPCODE_SUCCESS,
        payload: [],
      });

  }
};

// export const getUpdateDeliveryAddressByZip = (zipcode) => async (dispatch) => {
//   if (zipcode != '') {
//     try {
//       dispatch({ type: GET_UPDATE_DELIVERY_ZIPCODE_REQ });

//       const config = {
//         headers: {
//           // Authorization: `Bearer ${localStorage.getItem("token")}`,
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         },
//       };
//       // const response = await jwtAxios.get(
//       //   `/quotation/search/zipcode?zipcode=${zipcode}`,
//       //   // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//       //   config
//       // );
//       const response = await axios.get(
//         // `/quotation/search/zipcode?zipcode=${zipcode}`,
//         // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//         `https://api.zippopotam.us/us/${zipcode}`,
//         config,
//       );

//       if (response.status === 200) {
//         dispatch({
//           type: GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
//           payload: response.data,
//         });
//         return new Promise((resolve, reject) => {
//           resolve(response);
//         });
//       }
//     } catch (error) {
//       console.log(error.message);
//       toast.error('Zipcode not found Please enter the data Manaualy');
//       dispatch({ type: GET_UPDATE_DELIVERY_ZIPCODE_FAIL });
//       return new Promise((resolve, reject) => {
//         reject(error);
//       });
//       // toast.error("Something went wrong!");
//     }
//   } else {
//     dispatch({
//       type: GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
//       payload: '',
//     });
//   }
// };

// export const getPickUpAddressByZip = (zipcode) => async (dispatch) => {
//   if (zipcode != '') {
//     try {
//       dispatch({ type: GET_UPDATED_PICKUP_ZIPCODE_REQ });

//       const config = {
//         headers: {
//           // Authorization: `Bearer ${localStorage.getItem("token")}`,
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         },
//       };
//       // const response = await jwtAxios.get(
//       //   `/quotation/search/zipcode?zipcode=${zipcode}`,
//       //   // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//       //   config
//       // );
//       const response = await axios.get(
//         // `/quotation/search/zipcode?zipcode=${zipcode}`,
//         // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//         `https://api.zippopotam.us/us/${zipcode}`,
//         config,
//       );

//       if (response.status === 200) {
//         dispatch({
//           type: GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
//           payload: response.data,
//         });
//         return new Promise((resolve, reject) => {
//           resolve(response);
//         });
//       }
//     } catch (error) {
//       console.log(error.message);
//       toast.error('Zipcode not found Please enter the data Manaualy');
//       dispatch({ type: GET_UPDATED_PICKUP_ZIPCODE_FAIL });
//       return new Promise((resolve, reject) => {
//         reject(error);
//       });

//       // toast.error("Something went wrong!");
//     }
//   } else {
//     dispatch({
//       type: GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
//       payload: '',
//     });
//   }
// };

export const getPickUpAddressByZip = (data) => {
  return async (dispatch, getState) => {
    if (data != '') {

      dispatch({ type: GET_UPDATED_PICKUP_ZIPCODE_REQ });

      try {
        const response = await jwtAxios({
          method: 'get',
          url: `/zipcode?zipcode=${data}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          dispatch({
            type: GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
            payload: response.data,
          });

          const hasEmptyObject = response.data.some(obj => Object.keys(obj).length === 0);
          if (hasEmptyObject)
            toast.error('Zipcode not available');

          return new Promise((resolve, reject) => {
            resolve(response);
          });
        }
      } catch (error) {
        // console.log(error.message);
        toast.error('Zipcode not found Please enter the data Manaualy');
        dispatch({ type: GET_UPDATED_PICKUP_ZIPCODE_FAIL });
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    };

    if (data === '')
      dispatch({
        type: GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
        payload: '',
      });

  }
};


export const getUpdateDeliveryAddressByZip = (data) => {
  return async (dispatch, getState) => {
    if (data != '') {

      dispatch({ type: GET_UPDATE_DELIVERY_ZIPCODE_REQ });

      try {
        const response = await jwtAxios({
          method: 'get',
          url: `/zipcode?zipcode=${data}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          dispatch({
            type: GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
            payload: response.data,
          });
          const hasEmptyObject = response.data.some(obj => Object.keys(obj).length === 0);
          if (hasEmptyObject)
            toast.error('Zipcode not available');

          return new Promise((resolve, reject) => {
            resolve(response);
          });
        }
      } catch (error) {
        // console.log(error.message);
        toast.error('Zipcode not found Please enter the data Manaualy');
        dispatch({ type: GET_UPDATE_DELIVERY_ZIPCODE_FAIL });
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    };

    if (data === '')
      dispatch({
        type: GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
        payload: '',
      });

  }
};

export const retriveUnassigKeyList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: 'GET_UNASSIGNED_LIST_REQ' });
    const res = await jwtAxios({
      method: 'GET',
      url: `ffadmin/key-management/unassigned-list?role=all`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    dispatch({
      type: 'GET_UNASSIGNED_LIST_SUCCESS',
      payload: res.data,
    });
  } catch (err) {
    toast.error('Internal Server Error');
    dispatch({
      type: 'GET_UNASSIGNED_LIST_FAIL',
    });
  }
};

export const retriveAssigKeyList = (cid, dropDown) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'GET_ASSIGNED_LIST_REQ' });
    const res = await jwtAxios({
      method: 'GET',
      // url: `key-management/get-keys?status=unused&activeStatus=active&assignStatus=assign&companyID=12`,
      url: `/key-management/get-keys?assignStatus=assign&companyID=${cid}&role=${dropDown}`,
      // url: `http://3.134.15.140:8080/api/key-management/get-keys?assignStatus=assign&role=${dropDown.toLowerCase()}&companyID=${cid}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    dispatch({
      type: 'GET_ASSIGNED_LIST_SUCCESS',
      payload: res.data,
    });
  } catch (err) {
    toast.error('Internal Server Error');
    dispatch({
      type: 'GET_ASSIGNED_LIST_FAIL',
    });
  }
};

export const updateStausOfUser = (id, status, cid, dropDown, role) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(
      `freight-forwarder/active-status?id=${id}&status=${status}&role=${role.toLowerCase()}`,
      config,
    );

    if (response.status === 200) {
      dispatch(retriveAssigKeyList(cid, dropDown));
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.response.data.title);
  }
};

export const handleSelectionType = (companyId, data) => async (dispatch) => {
  try {
    const res = await jwtAxios({
      method: 'GET',
      url: `key-management/get-keys?assignStatus=assign&companyID=${companyId}&role=${data}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    dispatch({
      type: 'GET_ASSIGNED_LIST_SUCCESS',
      payload: res.data,
    });
  } catch (err) {
    toast.error(err);
  }
};

export const checkDistanceBetweenZipcode = (pickUpzipcode, departureZipcode, airport, shipFromAirlineDelivery) => async (
  dispatch,
) => {
  try {
    const shipFromAirlineDeliveryValue = shipFromAirlineDelivery ? 'Yes' : 'No';
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(
      `check-trucking?pickup-zip=${pickUpzipcode}&delivery-zip=${departureZipcode}&departure-airport=${airport}&airlineDrop=${shipFromAirlineDeliveryValue}`,
      config,
    );

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  } catch (error) {
    // console.log(error?.response?.data?.title);
    console.log('');
  }
};

export const checkDistBetweenDeliveryZipcodeAndDeptAirptort = (departureZipcode, airport) =>
  async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(
        `check-distance?delivery-zip=${departureZipcode}&departure-airport=${airport}`,
        config,
      );

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  };

export const sendClientQuoteMail = (DATA) => {
  return async (dispatch, getState) => {
    try {
      // console.log(post_data.perUNNumberFee);
      const res = await jwtAxios({
        url: `/ffadmin/client-quotation/send?quotation_id=${DATA?.quotation_id}&request_id=${DATA?.request_id}&quote_id=${DATA?.quote_id}&client_id=${DATA?.client_id}`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        // data: {
        //   // ...post_data,
        // },
      });

      if (200 <= res.status && res.status < 300) {
        toast.success('Record Created Successfully');
      } else {
        toast.error(`Internal Server Error`);
      }
    } catch (error) {
      // if (error.message == "Request failed with status code 404") {
      //   console.log("Airport already exists." + err.message + " abc " + err.response);
      // }
      if (error.response?.data != undefined) {
        toast.error(error.response.data, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

// export const confirmOrCancelQuotationRecord = (ccId, ccQuoteId, status) => {
//   return async (dispatch, getState) => {
//     try {
//       const res = await jwtAxios({
//         url: `/ffclient/saved-quotation/confirm-or-cancel?quotation_id=${ccId}&quote_id=${ccQuoteId}&status=${status}`,
//         method: 'put',
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       });

//       if (200 <= res.status && res.status < 300) {
//         toast.success(`Record ${status} Successfully`);
//       }
//     } catch (error) {
//       if (error?.response?.data?.title != undefined) {
//         toast.error(error?.response?.data?.title, { autoClose: 1500 });
//       } else {
//         toast.error('Internal Server Error');
//       }
//     }
//   };
// };

export const confirmOrCancelQuotationRecord = (ccId, ccQuoteId, status, type) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        url: `/ffadmin/saved-client-quotation/accept-or-cancel?quotation_id=${ccId}&quote_id=${ccQuoteId}&status=${status}`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (200 <= res.status && res.status < 300) {
        toast.success(`Record ${status} Successfully`);
        if (type?.toLowerCase() == 'manual')
          dispatch(getIncomingQuoteManualQuotation(ccQuoteId))
        else
          dispatch(getIncomingShipperQuotationDetail(ccQuoteId));

      }
    } catch (error) {
      if (error?.response?.data?.title != undefined) {
        toast.error(error?.response?.data?.title, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

export const getKeyCount = (agent) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`key-management/${agent}`, config);
    if (response.status == 200)
      dispatch({ type: GET_USER_KEY_MANAGEMENT_SUCCESS, payload: response.data });

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  } catch (error) {
    toast.error(error.response.data.title);
    // console.log(error.response.data.title);
  }
};

export const getCompanyCount = (id, userId) => async (dispatch) => {
  if (id != undefined) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      if (userId === 'company') {
        const response = await jwtAxios.get(
          `freight-forwarder/get-key-count?companyID=${id}`,
          config,
        );
        if (response.status == 200) {
          dispatch({ type: GET_KEY_COUNT_SUCCESS, payload: response.data });
        }

        return new Promise((resolve, reject) => {
          resolve(response);
        });
      } else {
        const response = await jwtAxios.get(
          `freight-forwarder/get-key-count?companyID=${id}&userId=${userId}`,
          config,
        );
        if (response.status == 200)
          dispatch({ type: GET_KEY_COUNT_SUCCESS, payload: response.data });

        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.title);
      // console.log(error?.response?.data?.title);
    }
  }
};

export const getInitalKeyCount = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`freight-forwarder/get-key-count`, config);
    if (response.status == 200) dispatch({ type: GET_KEY_COUNT_SUCCESS, payload: response.data });

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  } catch (error) {
    toast.error(error.response.data.title);
    // console.log(error.response.data.title);
  }
};

export const getUserRole = (id) => async (dispatch) => {
  if (id != undefined) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await jwtAxios.get(`quotation/count?companyID=${id}`, config);
      if (response.status == 200) dispatch({ type: GET_NO_0F_QUOTATION, payload: response.data });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    } catch (error) {
      toast.error(error.response.data.title);
      // console.log(error.response.data.title);
    }
  }
};

export const getUserFilteredRole = (filter, role) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.get(
      `ffcompany/quotations?filter=${filter}&role=${role}`,
      config,
    );
    if (response.status == 200)
      dispatch({ type: GET_KEY_USER_ROLE_SELECT_SUCCESS, payload: response.data });

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  } catch (error) {
    toast.error(error?.response?.data?.title);
    // console.log(error?.response?.data?.title);
  }
};

export const getKeyManagementAssignKey = (cid, role, userId, status, page, pageSize, sorting, filtering, index) => async (dispatch) => {
  let assignStatus = status;
  switch (assignStatus) {
    case 'All':
      assignStatus = 'all';
      break;
    case 'Assign List':
      assignStatus = 'assign';
      break;
    case 'Unassign List':
      assignStatus = 'unassign';
      break;
    default:
      break;
  }
  dispatch({ type: GET_KEY_MANAGEMENT_ASSIGNED_KEY_REQ });
  const queryParams = {
    userId: userId,
    companyID: cid,
    role: role.toLowerCase(),
    userId: userId,
    // assignStatus:assignStatus.toLowerCase(),
    pageNum: page,
    pageSize: pageSize,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    // const response = await jwtAxios.get(
    //   `key-management/get-keys?companyID=${cid}&role=${role.toLowerCase()}&userId=${userId}&assignStatus=${assignStatus.toLowerCase()}&pageNum=${page}&pageSize=${pageSize}&filter=${JSON.stringify(filtering)}&sort=${JSON.stringify(sorting)}}`,
    //   config,
    // );
    const response = await jwtAxios.get('key-management/get-keys', {
      ...config,
      params: queryParams,
    });
    if (response.status == 200)
      // dispatch({type:GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS})
      dispatch({ type: GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS, payload: response.data });

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  } catch (error) {
    toast.error(error?.response?.data?.title);
    dispatch({ type: GET_KEY_MANAGEMENT_ASSIGNED_KEY_FAIL });
    // console.log(error?.response?.data?.title);
  }
};

export const getKeyManagementAssignKeyNew = (role, key) =>

  async (dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await jwtAxios.get(`get-key/${role}/${key}`, {
        ...config,
      });

      if (response.status == 200)
        return new Promise((resolve, reject) => {
          resolve(response);
        });

    } catch (error) {
      toast.error(error?.response?.data?.title);
      // console.log(error?.response?.data?.title);
      return new Promise((resolve, reject) => {
        reject(error.response);
      });
    }
  };
export const getKeyManagementAssignKeyNewforSuperadmin = (role, key, cid) =>

  async (dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await jwtAxios.get(`get-key/${role.toLowerCase()}/${key}`, {
        ...config,
      });

      if (response.status == 200)
        return new Promise((resolve, reject) => {
          resolve(response);
        });

    } catch (error) {
      toast.error(error?.response?.data?.title);
      // console.log(error?.response?.data?.title);
      return new Promise((resolve, reject) => {
        reject(error.response);
      });
    }
  };

export const getKeyManagementUnAssignKey = (cid, role, userId) => async (dispatch) => {
  dispatch({ type: GET_KEY_MANAGEMENT_UNASSIGNED_KEY_REQ });
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    if (userId != '') {
      const response = await jwtAxios.get(
        `key-management/unassigned-list?companyID=${cid}&role=${role}&userId=${userId}`,
        config,
      );
      if (response.status == 200)
        // dispatch({type:GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS,payload:response.data})

        dispatch({ type: GET_KEY_MANAGEMENT_UNASSIGNED_KEY_SUCCESS, payload: response.data });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }

    if (userId == '') {
      const response = await jwtAxios.get(`key-management/unassigned-list?role=${role}`, config);
      if (response.status == 200)
        // dispatch({type:GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS,payload:response.data})

        dispatch({ type: GET_KEY_MANAGEMENT_UNASSIGNED_KEY_SUCCESS, payload: response.data });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.title);
    dispatch({ type: GET_KEY_MANAGEMENT_UNASSIGNED_KEY_FAIL });
    // console.log(error?.response?.data?.title);
  }
};

export const activeStatus = (
  id,
  status,
  role,
  companyID,
  userId,
  dropDownValue,
  comeFrom,
  page,
  pageSize,
  sorting,
  filtering,
  index
) => async (dispatch) => {
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.get(
      `freight-forwarder/active-status?id=${id}&status=${status}&role=${role.toLowerCase()}`,
      config,
    );
    if (response.status == 200) {
      if (comeFrom === 'agentList') {
        // dispatch(getKeyManagementAssignKey(companyID, 'agent', '', 'All', page, pageSize, sorting, filtering, index)); commented by vishal

      } else {
        // New Comment
        // dispatch(getKeyManagementAssignKey(companyID, role, userId !== undefined ? userId : '', dropDownValue, comeFrom, page, sorting, filtering, index));
      }
      status === 'active'
        ? toast.success(`Record Activated Successfully`)
        : toast.success(`Record Suspended Successfully`);
    }

    // dispatch({type:GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS,payload:response.data})

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  } catch (error) {
    toast.error(error?.response?.data?.title);
    // console.log(error?.response?.data?.title);
    return new Promise((resolve, _) => {
      resolve(error.response);
    });
  }
};

export const resendFFAdminUnusedKey = (
  formikStateFfUserID,
  formikStateKeyType,
  formikStateAlphanumericKey,
  formikStateEmail,
) => {
  const post_data = {
    key: formikStateAlphanumericKey,
    email: formikStateEmail,
    id: formikStateFfUserID,
    keyType: formikStateKeyType,
  };

  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'POST',
        url: '/key-management/resend-key',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          ...post_data,
        },
      });

      // if (res.status < 200 || res.status > 300) {
      //   toast.error('Oops! There was an error');
      // }

      if (res.status === 200) {
        toast.success('Record Resend Successfully');
        return new Promise((resolve, _) => {
          resolve(res);
        });
      }
      // else {
      //   toast.error(res?.status?.data?.title);
      //   return new Promise((resolve, _) => {
      //     resolve(res);
      //   });
      // }
    } catch (err) {
      if (err.response?.data?.title != undefined) {
        toast.error(err.response.data?.title, { autoClose: 2000 });
        return new Promise((resolve, _) => {
          resolve(err.response);
        });
      } else {
        toast.error('Internal Server Error');
        return new Promise((resolve, _) => {
          resolve(err.response);
        });
      }
    }
  };
};

export const getIncomingCount = (cid) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`quotation/incoming-count?companyID=${cid}`, config);
    if (response.status == 200) dispatch({ type: GET_INCOMING_COUNT, payload: response.data });
  } catch (error) {
    // console.log(error.message);
    toast.error('Internal server error');
  }
};

export const getIncomingDataListBasedOnRole = (role, filter) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(
      // `ffcompany/client/quotations?role=${role}&filter=${filter}`,
      `ffcompany/quotations?role=${role}&filter=${filter}`,
      config,
    );
    if (response.status == 200) dispatch({ type: GET_CLIENT_QUOTATION, payload: response.data });
  } catch (error) {
    // console.log(error.message);
    toast.error('Internal server error');
  }
};

export const getIncomingQuotationList = (filter, role, userId, page, size, sorting, filtering, index) => async (dispatch) => {

  const queryParams = {
    date_filter: filter,
    role: role,
    userID: userId,
    page,
    size,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  const queryParamsAllClient = {
    date_filter: filter,
    role: 'all_client',
    page,
    size,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  try {
    dispatch({ type: GET_INCOMING_LIST_REQ });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    // const response = await jwtAxios.get(
    //   `ffcompany/client/quotations-lists?filter=${filter}&role=${role}&userID=${userId}`,
    //   config,
    // );

    // const response = await jwtAxios.get('ffcompany/client/quotations-lists', {
    const response = await jwtAxios.get('ffcompany/quotations-lists', {
      ...config,
      params: index == 0 ? queryParamsAllClient : queryParams,
    });

    if (response.status == 200) {
      dispatch({ type: GET_INCOMING_LIST_SUCCESS, payload: response.data });
      return new Promise((resolve, _) => {
        resolve(response);
      });
    }
  } catch (error) {
    // toast.error('Internal Server Error');
    dispatch({ type: GET_INCOMING_LIST_FAIL });
  }
};

export const getIncomingQuotationsDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_INCOMING_QUOTATIONS_DETAIL_REQ });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`ffadmin/quotation/${id}`, config);
    if (response.status == 200)
      dispatch({ type: GET_INCOMING_QUOTATIONS_DETAIL_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_INCOMING_QUOTATIONS_DETAIL_FAIL });
  }
};

export const getQuoteAnalystQuotationList = (filter, role, userId, page, size, sorting, filtering, index) => async (dispatch) => {

  const queryParams = {
    date_filter: filter,
    role,
    userID: userId,
    page,
    size,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  const queryParamsAllCompany = {
    date_filter: filter,
    // role: 'all_company',
    role,
    page,
    size,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  try {
    dispatch({ type: GET_QUOTEANALYS_QUOTAION_LIST_REQ });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    // const response = await jwtAxios.get(
    //   `ffcompany/quotations-lists?filter=${filter}&role=${role}&userID=${userId}`,
    //   config,
    // );

    const response = await jwtAxios.get('ffcompany/quotations-lists', {
      ...config,
      params: index == 0 ? queryParamsAllCompany : queryParams,
    });

    if (response.status == 200) {
      dispatch({ type: GET_QUOTEANALYS_QUOTAION_LIST_SUCCESS, payload: response.data });
      return new Promise((resolve, _) => {
        resolve(response);
      });
    }
  } catch (error) {
    // toast.error('Internal Server Error');
    dispatch({ type: GET_QUOTEANALYS_QUOTAION_LIST_FAIL });
  }
};

export const getQuoteAnalystQuotationsDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_QUOTEANALYST_QUOTATION_DETAILS_REQ });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`ffadmin/quotation/${id}`, config);
    if (response.status == 200)
      dispatch({ type: GET_QUOTEANALYST_QUOTATION_DETAILS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_QUOTEANALYST_QUOTATION_DETAILS_FAIL });
  }
};

export const setFilterTypeValue = (value) => async (dispatch) => {
  try {
    dispatch({ type: GET_ANALYST_USER_FILTER_VALUE, payload: value });
  } catch (error) { }
};

// export const getAdminUserProfile=(value)=>async(dispatch)=>{
//   console.log(value)
//   try {
//     const config={
//       headers:{
//         Authorization: `Bearer ${localStorage.getItem('token')}`,
//       },
//     }
//     const body={
//       file:value
//     }
//     const response=await jwtAxios.post(`/uploadFile/profile`,config,body)
//     console.log(response)
//   } catch (error) {

//   }
// }
export const getAdminUserProfile = (value) => {
  const post_data = {
    id: value,
  };

  var body = new FormData();
  // body.append('file',URL.createObjectURL(value))
  body.append('file', value);

  return async (dispatch, getState) => {
    // let updateAuthStore={...getState().auth.profile,imageUrl:URL.createObjectURL(value)}
    if (value != undefined) {
      try {
        dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_REQ });

        const res = await jwtAxios({
          url: `/uploadFile/profile`,
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: body,
        });

        if (200 <= res.status && res.status < 300) {
          dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_SUCCES });

          dispatch(downProfileFile(res.data.fileName));
        }
      } catch (error) {
        // toast.error(`Internal Server Error`);
        dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_FAIL });
      }
    }
  };
};

export const downProfileFile = (value) => {
  const post_data = {
    id: value,
  };

  var body = new FormData();
  // body.append('file',URL.createObjectURL(value))
  body.append('file', value);
  return async (dispatch, getState) => {
    if (value != undefined && value != null && value != '' && value?.includes('blob') == false) {
      try {
        dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_REQ });

        const res = await jwtAxios({
          url: `/downloadFile/${value}`,
          method: 'get',
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          responseType: 'blob',
        });

        if (200 <= res.status && res.status < 300) {
          dispatch({ type: UPDATE_USER_IMAGEURL, payload: URL.createObjectURL(res?.data) });
          dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_SUCCES });

          return new Promise((resolve, reject) => {
            resolve(res);
          });
        }
      } catch (error) {
        dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_FAIL });

        // toast.error(`Internal Server Error`);
      }
    }
  };
};

export const updateLogo = (value) => {
  const post_data = {
    id: value,
  };

  var body = new FormData();
  // body.append('file',URL.createObjectURL(value))
  body.append('file', value);

  return async (dispatch, getState) => {
    // let updateAuthStore={...getState().auth.profile,imageUrl:URL.createObjectURL(value)}
    if (value != undefined && value != null && value != '') {
      try {
        dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_REQ });

        const res = await jwtAxios({
          url: `/uploadFile/logo`,
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: body,
        });

        if (200 <= res.status && res.status < 300) {
          // dispatch({type:DOWNLOAD_STATUS_OF_IMAGE_SUCCES})
          // console.log(res);

          dispatch(downLogoFile(res.data.fileName));
        }
      } catch (error) {
        // toast.error(`Internal Server Error`);
        // dispatch({type:DOWNLOAD_STATUS_OF_IMAGE_FAIL})
      }
    }
  };
};

export const downLogoFile = (value) => {
  return async (dispatch, getState) => {
    if (value != undefined && value != null && value != '' && value?.includes('blob') == false) {
      try {
        const res = await jwtAxios({
          url: `/downloadFile/${value}`,
          method: 'get',
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          responseType: 'blob',
        });

        if (200 <= res.status && res.status < 300) {
          dispatch({ type: UPDATE_LOGO_IMAGEURL, payload: URL.createObjectURL(res?.data) });
          dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_SUCCES });

          return new Promise((resolve, reject) => {
            resolve(res);
          });
        }
      } catch (error) {
        dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_FAIL });

        // toast.error(`Internal Server Error`);
      }
    }
  };
};

export const getFeedBacklist = (uid, cid, pageNum, pageSize, sorting, filtering) => async (dispatch) => {
  // const queryParams = {
  //   user_id: uid,
  //   company_id: cid,
  //   pageNum,
  //   pageSize,
  //   sorting,
  //   filter
  // };

  const queryParams = {
    user_id: uid,
    company_id: cid,
    pageNum,
    pageSize,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  try {
    dispatch({ type: GET_FEED_BACK_LIST_REQ });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    // const response = await jwtAxios.get(
    //   `ffcompany/feedback?user_id=${uid}&company_id=${cid}`,
    //   config,
    // );

    // if (response.status == 200)
    //   dispatch({ type: GET_FEEDBACK_LIST_SUCCESS, payload: response.data.content });

    const response = await jwtAxios.get('ffcompany/feedback', {
      ...config,
      params: queryParams,
    });

    if (response.status === 200) {
      return new Promise((resolve, _) => {
        resolve(response);
      });
    }

  } catch (error) {
    dispatch({ type: GET_FEEDBACL_LIST_FAIL });
  }
};

export const submitFeedBack = (description, mainmenu, submenu, feedBack, file) => {
  return async (dispatch, getState) => {
    const body = {
      description: description,
      mainMenu: mainmenu,
      subMenu: submenu,
      feedbackType: feedBack,
    };

    var data = new FormData();

    file.forEach((file, index) => {
      // data.append(`files[${index}]`, file, file.name);
      data.append(`file`, file, file.name);
    });

    // file.forEach((file, index) => {
    //   // data.append(`files[${index}]`, JSON.stringify(file));
    //   data.append(`files[${index}]`, file);
    // });
    // data.append('feedback', JSON.stringify(body));


    // file.map((item) => data.append('file', item));
    // DATA.map((item) => body.append('file', item));

    data.append('feedback', JSON.stringify(body));

    try {
      const res = await jwtAxios({
        url: `feedback`,
        method: 'post',
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (200 <= res.status && res.status < 300) {
        return new Promise((resolve, reject) => {
          resolve(res);
        });
      }
    } catch (error) {
      if (error?.response?.data?.detail != undefined) {
        toast.error(error?.response?.data?.detail, { autoClose: 2000 });
      } else {
        toast.error('Internal Server Error');
      }
      return new Promise((resolve, reject) => {
        reject(error);
      })
    }
  };
};
export const getFeedBacklistForairline = (uid, cid, pageNum, pageSize, sorting, filtering) => async (dispatch) => {
  // const queryParams = {
  //   user_id: uid,
  //   company_id: cid,
  //   pageNum,
  //   pageSize,
  //   sorting,
  //   filter
  // };

  const queryParams = {
    user_id: uid,
    company_id: cid,
    pageNum,
    pageSize,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  try {
    dispatch({ type: GET_FEED_BACK_LIST_REQ });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    // const response = await jwtAxios.get(
    //   `ffcompany/feedback?user_id=${uid}&company_id=${cid}`,
    //   config,
    // );

    // if (response.status == 200)
    //   dispatch({ type: GET_FEEDBACK_LIST_SUCCESS, payload: response.data.content });

    const response = await jwtAxios.get('airline/feedback', {
      ...config,
      params: queryParams,
    });

    if (response.status === 200) {
      return new Promise((resolve, _) => {
        resolve(response);
      });
    }

  } catch (error) {
    dispatch({ type: GET_FEEDBACL_LIST_FAIL });
  }
};

export const submitDisclaimer = (DATA) => async (dispatch, state) => {
  const put_data = {
    disclaimer: DATA,
  };

  try {
    const res = await jwtAxios({
      method: 'post',
      url: `/disclaimer`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: put_data,
    });

    if (res.status >= 200 || res.status <= 200) {
      // dispatch(getProfile());
      dispatch({ type: GET_DISCLAIMER_DATA, payload: res?.data });

      // console.log(res, 'in put data');
      // toast.success('Record Updated Successfully');
    }
  } catch (error) {
    toast.error('Internal Server Error');
  }
};
export const updateDisclaimerDetail = (id, disclaimer) => {
  return async (dispatch, getState) => {
    const post_data = {
      id: id,
      disclaimer: disclaimer,
    };
    try {
      const header = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
      const body = { ...post_data };

      const res = await jwtAxios.put(`/disclaimer`, body, { headers: header });

      if (200 <= res.status && res.status < 300) {
        dispatch({ type: GET_DISCLAIMER_DATA, payload: res?.data });
        toast.success('Disclaimer Updated successfully');
      }
    } catch (error) {
      if (error?.response?.data?.title != undefined) {
        toast.error(error?.response?.data?.title, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};
export const submitQuotationinquire = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(`/ffadmin/quotation-inquirer/create`, data, config);

    if (response.status === 200) {
      // dispatch({
      //   type: GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS,
      //   payload: response.data,
      // });
      // console.log(response)
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    // dispatch({ type: GET_FFADMIN_SEARCH_ADDRESS2_FAIL });
    toast.error(error?.response?.data?.title, { autoClose: 2000 });
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const submitCreateQuotationinquire = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(`/ffadmin/quotation-inquirer/save-as-new`, data, config);

    if (response.status === 200) {
      // console.log(response)
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error?.response?.data?.title, { autoClose: 2000 });
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const clearAdminRedux = () => async (dispatch) => {
  dispatch({ type: RESET_STATE });
};

export const quoteInquerierForList = (page, size, sorting, filtering) => {

  const queryParams = {
    page,
    size,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_FFADMIN_REQ_QUOTEFORMODEL_LIST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      // const res = await jwtAxios({
      //   method: 'get',
      //   url: `/ffadmin/quotation-inquirer/inquiryList?page=${page}&size=${size}&filter=${JSON.stringify(filtering)}&sort=${JSON.stringify(sorting)}`,
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('token')}`,
      //   },
      // });

      const res = await jwtAxios.get('/ffadmin/quotation-inquirer/inquiryList', {
        ...config,
        params: queryParams,
      });

      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [...res.data],
      // });

      if (res.status === 200) {
        dispatch({ type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST });
        return new Promise((resolve, _) => {
          resolve(res);
        });
      }

    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
      dispatch({ type: GET_FFADMIN_FAIL_QUOTEFORMODEL_LIST });
    }
  };
};

export const saveAsNewQuotations = (id, quoteId, version) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'post',
        url: `/ffadmin/saved-quotation/save-as-new?quotation_id=${JSON.stringify(
          id,
        )}&quote_id=${quoteId}&version=${version}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        // console.log(res);
        dispatch({ type: GET_SAVE_AS_NEW_QUOTATION, payload: res?.data });
        toast.success('Quote saved successfully', { autoClose: 1500 });
      }
      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [
      //     ...res.data,
      //   ],
      // });
    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
    }
  };
};

export const submitTrukingData = (id, tid, carrier, transitDays) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'post',
        url: `/ffadmin/quotation?request_id=${id}&trucking_id=${tid}&carrier=${carrier}&transit_days=${transitDays}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: UPDATE_QUOTATION_RESULT_DATA, payload: res?.data });
        // dispatch({type:GET_SAVE_AS_NEW_QUOTATION,payload:res?.data})
      }
      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [
      //     ...res.data,
      //   ],
      // });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
    }
  };
};

export const submitTrukingDataClient = (id, tid, carrier, transitDays) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'post',
        url: `/ffclient/quotation?request_id=${id}&trucking_id=${tid}&carrier=${carrier}&transit_days=${transitDays}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: UPDATE_QUOTATION_RESULT_DATA, payload: res?.data });
        // dispatch({type:GET_SAVE_AS_NEW_QUOTATION,payload:res?.data})
      }
      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [
      //     ...res.data,
      //   ],
      // });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
    }
  };
};

export const getAddressByZipcode = (data) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'get',
        url: `/api/zipcode`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: UPDATE_QUOTATION_RESULT_DATA, payload: res?.data });
        // dispatch({type:GET_SAVE_AS_NEW_QUOTATION,payload:res?.data})
      }
      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [
      //     ...res.data,
      //   ],
      // });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
    }
  };
};

export const getDepartureByZipcode = (data) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'get',
        url: `/zipcode?zipcode=${data}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (res?.status >= 200 && res?.status <= 301) {
        // dispatch({ type: UPDATE_QUOTATION_RESULT_DATA, payload: res?.data })
        // console.log(res);
        dispatch({ type: GET_DEPARTURE_ZIPCODE, payload: res?.data });
        // dispatch({type:GET_SAVE_AS_NEW_QUOTATION,payload:res?.data})
      }
      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [
      //     ...res.data,
      //   ],
      // });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      // toast.error('Something went wrong!');
      toast.error('Zipcode not available');
    }
  };
};

export const disableValuefromUpdate = () => (dispatch) => {
  dispatch({ type: UPDATE_VALUE_FOR_STATUS });
};

export const retrievePrintQuotationAirlineName = (quotationAirlineName) => {
  const post_data = {
    search: quotationAirlineName,
  };
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME });

      const res = await jwtAxios({
        method: 'get',
        url: `/airline-details/search`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({
        type: GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME_SUCCESS,
        payload: [...res.data],
      });
    } catch (err) {
      // console.log(err);
      dispatch({ type: GET_ABORT_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME });
      toast.error('Internal Server Error');
    }
  };
};

export const createManualQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_MANUAL_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/manual-quotation/create', data, config);

    if (response.status === 200) {
      dispatch({
        type: GET_MANUAL_QUOTATION_SUCCESS,
        payload: response.data,
      });
      // console.log(response);
      // dispatch({
      //   type: GET_SEARCH_BY_ZIP_SUCCESS,
      //   payload: ''
      // });
      // dispatch({
      //   type: GET_DELIVERY_ZIPCODE_SUCCESS,
      //   payload:'',
      // });
      // if (response.data.truckingCarriers.length != 0)
      //   dispatch({
      //     type: GET_TRUKING_DATA,
      //     payload: response.data.truckingCarriers,
      //   });
      toast.success('Manual Quotation created SuccessFully');

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: GET_MANUAL_QUOTATION_FAIL });
    // toast.error(error.response.data.title, { autoClose: 3500 });
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const sendAboutUs = (data) => {
  return async (dispatch, getState) => {
    // console.log(data);
    try {
      const res = await jwtAxios({
        method: 'post',
        url: `/version-control/create`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data,
      });
      if (res?.status >= 200 && res?.status <= 301) {
        // console.log(res);
        dispatch({ type: SET_VERSION_NUMBER, payload: res?.data?.id });
        // dispatch({ type: GET_SAVE_AS_NEW_QUOTATION, payload: res?.data })
      }
      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [
      //     ...res.data,
      //   ],
      // });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
    }
  };
};
export const getVersionBasedOnId = (data) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'get',
        // url: `/version-control/getVersionControl/${data}`,
        url: `/version-control`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (res?.status >= 200 && res?.status <= 301) {
        // dispatch({ type: UPDATE_QUOTATION_RESULT_DATA, payload: res?.data })
        // console.log(res);
        // dispatch({type:GET_DEPARTURE_ZIPCODE,payload:res?.data})
        dispatch({ type: GET_VERSION_ID_DATA, payload: res?.data });
        // dispatch({type:GET_SAVE_AS_NEW_QUOTATION,payload:res?.data})
      }
      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [
      //     ...res.data,
      //   ],
      // });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
    }
  };
};

export const getAboutVersionControlAllData = (data) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'get',
        url: `/version-control-all`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (res?.status >= 200 && res?.status <= 301) {
        // console.log(res);
        dispatch({ type: GET_ABOUT_VERSION_CONTROL_ALL_DATA, payload: res?.data });
      }
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
    }
  };
};

export const getAboutVersionControlDataByID = (idForFixedBugsView) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_LOADER_STATUS, payload: 'loading' });
    try {
      const res = await jwtAxios({
        method: 'get',
        url: `/version-control/${idForFixedBugsView}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: GET_ABOUT_VERSION_CONTROL_DATA_BY_ID, payload: res?.data });
      }
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
    }
  };
};

export const getAirLinetList = (data) => async (dispatch) => {
  try {
    // dispatch({ type: GET_SHIP_TO_AIRPORT_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(
      `/airline-details/search-airline`,
      { search: data },
      config,
    );

    if (response.status === 200) {
      // dispatch({
      //   type: GET_SHIP_TO_AIRPORT_SUCCESS,
      //   payload: response.data,
      // });
      dispatch({ type: GET_AIRLINE_LIST, payload: response?.data });
      // console.log(response, 'inside getAirlineList');
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    } else dispatch({ type: GET_AIRLINE_LIST1, payload: [] });
  } catch (error) {
    // console.log(error.message);
    // dispatch({ type: GET_SHIP_TO_AIRPORT_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getAirLinetList1 = (data, index) => async (dispatch) => {
  try {
    // dispatch({ type: GET_SHIP_TO_AIRPORT_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(
      `/airline-details/search-airline`,
      { search: data },
      config,
    );

    if (response.status === 200 && index == 1) {
      // dispatch({
      //   type: GET_SHIP_TO_AIRPORT_SUCCESS,
      //   payload: response.data,
      // });
      dispatch({ type: GET_AIRLINE_LIST1, payload: response?.data });
      // console.log(response, 'inside getAirlineList');
    } else dispatch({ type: GET_AIRLINE_LIST, payload: [] });
  } catch (error) {
    // console.log(error.message);
    // dispatch({ type: GET_SHIP_TO_AIRPORT_FAIL });
    toast.error('Something went wrong!');
  }
};

export const saveManualQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: SAVE_MANUAL_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/manual-quotation/save', data, config);

    if (response.status === 200) {
      dispatch({
        type: SAVE_MANUAL_QUOTATION_SUCCESS,
      });

      toast.success('Manual Quotation saved successfully', { autoClose: toastDelay });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    toast.error('Internal Server Error', { autoClose: toastDelay });
    dispatch({
      type: SAVE_MANUAL_QUOTATION_FAIL,
    });

  }
};

export const printManualQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: PRINT_MANUAL_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      responseType: 'blob',
    };

    const response = await jwtAxios.post(
      '/ffadmin/manual-quotation/pdf?airline_show=yes',
      data,
      config,
    );

    if (response.status === 200) {
      dispatch({ type: PRINT_MANUAL_QUOTATION_SUCCESS })
      toast.success('Print successfully', { autoClose: toastDelay });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: PRINT_MANUAL_QUOTATION_FAIL })
    // console.log(error, 'in print')
    toast.error('Internal Server Error', { autoClose: toastDelay });

  }
};

export const shareManualQuotation = (data, email) => async (dispatch) => {
  try {
    dispatch({ type: SHARE_MANUAL_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post(
      `/ffadmin/manual-quotation/email?email=${email}&airline_show=yes`,
      data,
      config,
    );

    if (response.status === 200) {
      dispatch({ type: SAVE_MANUAL_QUOTATION_SUCCESS });

      toast.success('Email Send Successfully', { autoClose: toastDelay });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    dispatch({ type: SHARE_MANUAL_QUOTATION_FAIL });
    toast.error('Internal Server Error', { autoClose: toastDelay });
  }
};

export const getQuoteReferenceId = () => {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await jwtAxios.post(`/ffadmin/quote-id`, '', config);
      if (response?.status >= 200 && response?.status <= 301) {
        dispatch({ type: 'SAVE_REFRENCE_ID', payload: response?.data?.quoteId });
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    } catch (error) { }
  };
};

export const changeQuoteReferenceId = (id) => async (dispatch) => {
  dispatch({ type: 'SAVE_REFRENCE_ID', payload: id })

}

export const clearQuoteRefrenceId = () => async (dispatch) => {
  dispatch({ type: 'EMPTY_REFRENCE_ID', payload: '' })
}

export const getManualQuotation = (id) => async (dispatch) => {
  if (id != '') {
    try {
      dispatch({ type: GET_MANUAL_QUOTATION_REQ });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffadmin/manual-quotation/${id}`, config);

      if (response.status === 200) {
        dispatch({ type: GET_MANUAL_QUOTATION_SUCCESS, payload: response.data });
      }
    } catch (error) {
      // console.log(error.message);
      dispatch({ type: GET_MANUAL_QUOTATION_FAIL });
      toast.error('Something went wrong!');
    }
  }
  else {
    dispatch({ type: GET_MANUAL_QUOTATION_SUCCESS, payload: '' });

  }
};



export const shareManualQuotationdetails = (qid, email) => async (dispatch) => {
  try {
    dispatch({ type: SHARE_MANUAL_QUOTATION_DETAIL_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post(
      `/ffadmin/saved-manual-quotation/email?quote_id=${qid}&email=${email}&airline_show=yes`,
      '',
      config,
    );

    if (response.status === 200) {
      toast.success('Email Send Successfully', { autoClose: toastDelay });
      dispatch({ type: SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: SHARE_MANUAL_QUOTATION_DETAIL_FAIL });

    toast.error('Internal Server Error', { autoClose: toastDelay });
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const printManualQuotationdetails = (qid) => async (dispatch) => {
  try {
    dispatch({ type: PRINT_MANUAL_QUOTATION_DETAIL_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      responseType: 'blob',
    };

    const response = await jwtAxios.post(
      `/ffadmin/saved-manual-quotation/pdf?quote_id=${qid}&airline_show=yes`,
      '',
      config,
    );

    if (response.status === 200) {
      toast.success('Print successFully', { autoClose: toastDelay });
      dispatch({ type: PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS })
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: PRINT_MANUAL_QUOTATION_DETAIL_FAIL })

    toast.error('Internal Server Error', { autoClose: toastDelay });
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const saveManualQuotationdetails = (data, email) => async (dispatch) => {
  try {
    dispatch({ type: SAVE_MANUAL_QUOTATION_DETAIL_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post(
      `/ffadmin/manual-quotation/email?email=${email}&airline_show=yes`,
      data,
      config,
    );

    if (response.status === 200) {
      // console.log(response);

      toast.success('Save SuccessFully', { autoClose: toastDelay });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    toast.error(error.response.data.title, { autoClose: toastDelay });
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const saveDataFromNewQuoteTomanualQUote = (data, chooseUnit) => async (
  dispatch,
  getState,
) => {
  let len = data.goodsArray.length;

  let previousChooseUnit = 'Imperial / Metric';
  // if (previousChooseUnit == 'Imperial / Metric' && chooseUnit == 'Imperial / Metric')
  //   previousChooseUnit = 'Imperial / Metric';
  if (chooseUnit === 'Imperial') {
    for (let i = 0; i < len; i++) {
      // console.log(chooseUnit, 'inside imperial')
      if (
        data?.goodsArray[i]?.totalWeight !== undefined &&
        data?.goodsArray[i]?.totalWeight !== ''
      ) {
        data.goodsArray[i].totalWeight = (
          parseFloat(data.goodsArray[i].totalWeight) / lbsValue
        ).toFixed(2);
      } else {
        data.goodsArray[i].totalWeight = '';
      }
    }

  }

  if (chooseUnit === 'Metric') {
    for (let i = 0; i < len; i++) {
      if (data?.goodsArray[i]?.height !== undefined && data?.goodsArray[i]?.height !== '') {
        data.goodsArray[i].height = (parseFloat(data.goodsArray[i].height) * 0.3937007874).toFixed(2);
      } else {
        data.goodsArray[i].height = '';
      }
      if (data?.goodsArray[i]?.width !== undefined && data?.goodsArray[i]?.width !== '') {
        data.goodsArray[i].width = (parseFloat(data.goodsArray[i].width) * 0.3937007874).toFixed(2);
      } else {
        data.goodsArray[i].width = '';
      }
      if (data?.goodsArray[i]?.length !== undefined && data?.goodsArray[i]?.length !== '') {
        data.goodsArray[i].length = (parseFloat(data.goodsArray[i].length) * 0.3937007874).toFixed(2);
      } else {
        data.goodsArray[i].length = '';
      }
    }

  }
  // else if (previousChooseUnit === 'Metric' && chooseUnit === 'Imperial') {
  //   for (let i = 0; i < data.goodsArray?.len; i++) {
  //     if (
  //       data?.goodsArray[i]?.totalWeight !== undefined &&
  //       data?.goodsArray[i]?.totalWeight !== ''
  //     ) {
  //       data.goodsArray[i].totalWeight = (
  //         parseFloat(data.goodsArray[i].totalWeight) * lbsValue
  //       ).toFixed(2);
  //     } else {
  //       data.goodsArray[i].totalWeight = '';
  //     }

  //     if (data?.goodsArray[i]?.height !== undefined && data?.goodsArray[i]?.height !== '') {
  //       data.goodsArray[i].height = (parseFloat(data.goodsArray[i].height) * 0.3937007874).toFixed(
  //         2,
  //       );
  //     } else {
  //       data.goodsArray[i].height = '';
  //     }
  //     if (data?.goodsArray[i]?.width !== undefined && data?.goodsArray[i]?.width !== '') {
  //       data.goodsArray[i].width = (parseFloat(data.goodsArray[i].width) * 0.3937007874).toFixed(2);
  //     } else {
  //       data.goodsArray[i].width = '';
  //     }
  //     if (data?.goodsArray[i]?.length !== undefined && data?.goodsArray[i]?.length !== '') {
  //       data.goodsArray[i].length = (parseFloat(data.goodsArray[i].length) * 0.3937007874).toFixed(
  //         2,
  //       );
  //     } else {
  //       data.goodsArray[i].length = '';
  //     }
  //   }

  //   previousChooseUnit = chooseUnit;
  // } else if (previousChooseUnit === 'Imperial' && chooseUnit === 'Imperial / Metric') {
  //   for (let i = 0; i < data.goodsArray?.len; i++) {
  //     if (
  //       data?.goodsArray[i]?.totalWeight !== undefined &&
  //       data?.goodsArray[i]?.totalWeight !== ''
  //     ) {
  //       data.goodsArray[i].totalWeight = (
  //         parseFloat(data.goodsArray[i].totalWeight) / lbsValue
  //       ).toFixed(2);
  //     } else {
  //       data.goodsArray[i].totalWeight = '';
  //     }
  //   }

  //   previousChooseUnit = chooseUnit;
  // } else if (previousChooseUnit === 'Imperial / Metric' && chooseUnit === 'Metric') {
  //   for (let i = 0; i < data.goodsArray?.len; i++) {
  //     if (data?.goodsArray[i]?.height !== undefined && data?.goodsArray[i]?.height !== '') {
  //       data.goodsArray[i].height = (parseFloat(data.goodsArray[i].height) * 2.54).toFixed(2);
  //     } else {
  //       data.goodsArray[i].height = '';
  //     }
  //     if (data?.goodsArray[i]?.width !== undefined && data?.goodsArray[i]?.width !== '') {
  //       data.goodsArray[i].width = (parseFloat(data.goodsArray[i].width) * 2.54).toFixed(2);
  //     } else {
  //       data.goodsArray[i].width = '';
  //     }
  //     if (data?.goodsArray[i]?.length !== undefined && data?.goodsArray[i]?.length !== '') {
  //       data.goodsArray[i].length = (parseFloat(data.goodsArray[i].length) * 2.54).toFixed(2);
  //     } else {
  //       data.goodsArray[i].length = '';
  //     }
  //   }

  //   previousChooseUnit = chooseUnit;
  // }
  //  else {
  //   if (previousChooseUnit === 'Metric' && chooseUnit === 'Imperial / Metric') {
  //     for (let i = 0; i < data?.goodsArray?.len; i++) {
  //       if (
  //         data?.goodsArray[i]?.totalWeight !== undefined &&
  //         data?.goodsArray[i]?.totalWeight !== ''
  //       ) {
  //         data.goodsArray[i].totalWeight = (
  //           parseFloat(data.goodsArray[i].totalWeight) * lbsValue
  //         ).toFixed(2);
  //       } else {
  //         data.goodsArray[i].totalWeight = '';
  //       }

  //       if (data?.goodsArray[i]?.height !== undefined && data?.goodsArray[i]?.height !== '') {
  //         data.goodsArray[i].height = (
  //           parseFloat(data.goodsArray[i].height) * 0.3937007874
  //         ).toFixed(2);
  //       } else {
  //         data.goodsArray[i].height = '';
  //       }
  //       if (data?.goodsArray[i]?.width !== undefined && data?.goodsArray[i]?.width !== '') {
  //         data.goodsArray[i].width = (parseFloat(data.goodsArray[i].width) * 0.3937007874).toFixed(
  //           2,
  //         );
  //       } else {
  //         data.goodsArray[i].width = '';
  //       }
  //       if (data?.goodsArray[i]?.length !== undefined && data?.goodsArray[i]?.length !== '') {
  //         data.goodsArray[i].length = (
  //           parseFloat(data.goodsArray[i].length) * 0.3937007874
  //         ).toFixed(2);
  //       } else {
  //         data.goodsArray[i].length = '';
  //       }
  //     }

  //     previousChooseUnit = chooseUnit;
  //   }
  // }

  dispatch({ type: SAVE_DATA_FROM_NEW_QUOTE, payload: data });
};

export const saveAvailabiltyModalAccepted = (data) => async (dispatch) => {
  dispatch({ type: SAVE_AVAILABILITY_MODAL_ACCEPTED, payload: data });
  return new Promise((resolve, reject) => {
    resolve(data);
  });
};

export const removeSaveDataFromNewQuote = () => async (dispatch) => {
  dispatch({ type: REMOVE_SAVE_DATA_FROM_NEW_QUOTE });
};


export const createTrukcingQuotation = (data) => async (dispatch) => {
  if (data != '' && data != null && data != undefined) {
    try {
      dispatch({ type: CREATE_TRUKCING_QUOTATION_REQ });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await jwtAxios.post('/ffadmin/manual-trucking-quotation/create', data, config);

      if (response.status === 200) {
        dispatch({
          type: CREATE_TRUKCING_QUOTATION_SUCCESS,
          payload: response.data,
        });
        // dispatch({
        //   type: GET_SEARCH_BY_ZIP_SUCCESS,
        //   payload: ''
        // });
        // dispatch({
        //   type: GET_DELIVERY_ZIPCODE_SUCCESS,
        //   payload:'',
        // });
        // if (response.data.truckingCarriers.length != 0)
        //   dispatch({
        //     type: GET_TRUKING_DATA,
        //     payload: response.data.truckingCarriers,
        //   });
        toast.success("Trucking Quotation created", { autoClose: 5000 });

        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    } catch (error) {
      // console.log(error);
      dispatch({ type: CREATE_TRUKCING_QUOTATION_FAIL });
      toast.error(error.response.data.title, { autoClose: 5000 });
      return new Promise((resolve, reject) => {
        reject(error.response);
      });
    }
  }
  else
    dispatch({
      type: CREATE_TRUKCING_QUOTATION_SUCCESS,
      payload: {},
    });
};

export const setLoggedIn = () => {
  return {
    type: SET_LOGGED_IN
  };
};

export const setLoggedOut = () => {
  return {
    type: SET_LOGGED_OUT
  };
};



export const checkStatusforQid = (qid) => async (dispatch) => {
  if (qid != '') {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffadmin/status/quote-id?quote_id=${qid}`, config);

      if (response.status === 200) {
        // dispatch({ type: SET_SHIPPER_QUOTATION_SUCCESS, payload: response.data });
        // console.log(response);
        dispatch({ type: SAVED_QUOTATION_ID, payload: response?.data });
      }
    } catch (error) {
      // console.log(error.message);
      // dispatch({ type: SET_SHIPPER_QUOTATION_FAIL });
      toast.error('Something went wrong!');
    }
  } else {
    dispatch({ type: SAVED_QUOTATION_ID, payload: '' });
  }
};

export const shareAsNewManualQuotation = (data, email) => async (dispatch) => {
  try {
    dispatch({ type: SHARE_MANUAL_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post(
      `/ffadmin/manual-quotation/save-as-new/email?email=${email}&airline_show=yes`,
      data,
      config,
    );

    if (response.status === 200) {
      dispatch({ type: SAVE_MANUAL_QUOTATION_SUCCESS });
      dispatch({ type: 'SAVE_REFRENCE_ID', payload: response?.data?.quoteId });
      toast.success('Email Send SuccessFully', { autoClose: toastDelay });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    dispatch({ type: SHARE_MANUAL_QUOTATION_FAIL });
    toast.error('Internal Server Error', { autoClose: toastDelay });
  }
};




export const saveAsNewManualQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: SAVE_MANUAL_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/manual-quotation/save-as-new', data, config);

    if (response.status === 200) {
      dispatch({
        type: SAVE_MANUAL_QUOTATION_SUCCESS,
      });
      dispatch({ type: 'SAVE_REFRENCE_ID', payload: response?.data?.quoteId });
      toast.success('Manual Quotation saved successfully', { autoClose: toastDelay });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    toast.error('Internal Server Error', { autoClose: toastDelay });
    dispatch({
      type: SAVE_MANUAL_QUOTATION_FAIL,
    });

  }
};

export const printSaveAsNewtManualQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: PRINT_MANUAL_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      responseType: 'blob',
    };

    const response = await jwtAxios.post(
      '/ffadmin/manual-quotation/save-as-new/pdf?airline_show=yes',
      data,
      config,
    );

    if (response.status === 200) {
      dispatch({ type: 'SAVE_REFRENCE_ID', payload: response?.headers?.filename });

      dispatch({ type: PRINT_MANUAL_QUOTATION_SUCCESS });
      toast.success('Print successfully', { autoClose: toastDelay });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: PRINT_MANUAL_QUOTATION_FAIL });
    // console.log(error, 'in print');
    toast.error('Internal Server Error', { autoClose: toastDelay });
  }
};

export const updateSubmission = (data) => (dispatch) => {
  dispatch({ type: 'UPDATE_SUBMIT', payload: data });
};

export const updateForModal = (data) => (dispatch) => {
  dispatch({ type: 'UPDATE_MODAL', payload: data });
};

export const updateForFirstTime = (data) => (dispatch) => {
  dispatch({ type: 'UPDATE_FOR_FIRST_TIME', payload: data });
};

export const saveEditManualQuotationData = (data) => (dispatch) => {
  dispatch({ type: 'EDIT_MANUAL_QUOATION', payload: data == '' ? {} : data })
}


export const incomingQuoteRecalculate = (data) => async (dispatch, getState) => {
  if (data != '') {
    try {

      dispatch({ type: CREATE_FFADMIN_QUOTATION_REQ });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await jwtAxios.post(`/ffadmin/client-quotation/update?quoteId=${data}`, '', config);
      if (response.status === 200) {
        dispatch({
          type: CREATE_FFADMIN_QUOTATION_SUCCESS,
          payload: response.data,
        });
        let updateQuoteList = {
          ...response?.data?.quotationRequest,
          shipFromAirportDetail: getState()?.ffAdminQuotation?.preAirportOfDepatureDetails,
          shipToAirportDetail: getState()?.ffAdminQuotation?.preAirportOfDestinationDetails
        }
        dispatch({
          type: SET_QUOTATION_DATA, payload: updateQuoteList
        })
        // dispatch({
        //   type: GET_SEARCH_BY_ZIP_SUCCESS,
        //   payload: ''
        // });
        // dispatch({
        //   type: GET_DELIVERY_ZIPCODE_SUCCESS,
        //   payload:'',
        // });
        if (response.data.truckingCarriers.length != 0)
          dispatch({
            type: GET_TRUKING_DATA,
            payload: response.data.truckingCarriers,
          });
        // toast.success("Quotation created");

        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    } catch (error) {
      // console.log(error);
      dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
      // toast.error(error.response.data.title, { autoClose: 3500 });
      return new Promise((resolve, reject) => {
        reject(error.response);
      });
    }
  }
  else {
    dispatch({
      type: CREATE_FFADMIN_QUOTATION_SUCCESS,
      payload: '',
    });
    dispatch({
      type: SET_QUOTATION_DATA, payload: '',
    })
  }

};

export const updateIncomingQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FFADMIN_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/quotation/create', data, config);

    if (response.status === 200) {
      dispatch({
        type: CREATE_FFADMIN_QUOTATION_SUCCESS,
        payload: response.data,
      });
      if (response.data.truckingCarriers.length != 0) {
        dispatch({
          type: GET_TRUKING_DATA,
          payload: response.data.truckingCarriers,
        });
        dispatch({ type: GET_RECALCULATE_VALUE });
      }
      toast.success('Quotation updated successfully');

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
    // toast.error(error.response.data.title);
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};


export const saveIncomingQuotationPriceItem = (data) => async (dispatch) => {
  try {
    dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios({
      url: `/ffadmin/client-quotation/save?request_id=${data.request_id}&quote_id=${data.quote_id}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
        payload: response.data,
      });
      toast.success('Quotation Items saved.');
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_FAIL });
    toast.error(error.response.data.title);
  }
};


export const sendIncomingQuoteByEmail = (data) => async (dispatch) => {
  try {
    dispatch({ type: SEND_QUOTE_BY_EMAIL_REQ });

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // };

    // const response = await jwtAxios.post(
    //   "/ffadmin/quotation/email",
    //   data,
    //   config
    // );
    const response = await jwtAxios({
      url: `/ffadmin/client-quotation/email?request_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}&airline_show=${data?.radioDataForAirlineDrop}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: SEND_QUOTE_BY_EMAIL_SUCCESS,
      });
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: SEND_QUOTE_BY_EMAIL_FAIL });
    toast.error(error.response.data.title);
  }
};

export const getIncomingQuoteTrucking = (Rid, truckingCarriersId, truckingCarrierData) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        url: `ffadmin/client-quotation?request_id=${Rid}&trucking_id=${truckingCarriersId}`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // truckingCarrierData.economicsRates = res?.data?.economicsRates;

      let truckingCarriers = truckingCarrierData;

      if (res.status === 200) {
        dispatch({
          type: CREATE_FFADMIN_QUOTATION_SUCCESS,
          payload: { ...res?.data, truckingCarriers },
          // payload: res?.data,
        });
        toast.success('Quotation updated successfully');
      }
    } catch (error) {
      // console.log(error);
      // toast.error(`Internal Server Error`);
      if (error.res != undefined) {
        toast.error(error.res, { autoClose: 5000 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};


export const submitIncomingTrukingData = (id, tid, carrier, transitDays) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'post',
        url: `/ffadmin/client-quotation?request_id=${id}&trucking_id=${tid}&carrier=${carrier}&transit_days=${transitDays}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: UPDATE_QUOTATION_RESULT_DATA, payload: res?.data });
        // dispatch({type:GET_SAVE_AS_NEW_QUOTATION,payload:res?.data})
      }
      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [
      //     ...res.data,
      //   ],
      // });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      toast.error('Something went wrong!');
    }
  };
};


export const confirmIncomingQuotationPriceItem = (data) => async (dispatch) => {
  try {
    dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios({
      url: `/ffadmin/client-quotation/accept-or-cancel?request_id=${data.request_id}&quote_id=${data.quote_id}&status=${data?.status}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
        payload: response.data,
      });
      toast.success('Quotation Confirmed Successfully.');
      return new Promise((resolve, reject) => {
        resolve(response)
      })
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_FAIL });
    toast.error(error.response.data.title);
  }
};


export const confirmIncomingQuotation = (data) => async (dispatch) => {
  try {
    // dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_REQ });
    // console.log(data)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios({
      url: `/ffadmin/saved-client-quotation/accept-or-cancel?quotation_id=${data.request_id}&quote_id=${data.data}&status=${encodeURIComponent(data?.status)}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });


    if (response.status === 200) {
      // console.log(response, 'inside confirmIncoming Quotation');
      dispatch(getIncomingShipperQuotationDetail(data?.data));
      // data?.status=='FF Not Confirm'?dispatch({type:'UPDATE_STATUS',payload:'FF Confirmed'}):dispatch({type:'UPDATE_STATUS',payload:'FF Not Confirm'})
      // dispatch({
      //   type: POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
      //   payload: response.data,
      // });
      toast.success('Quotation Confirmed Successfully.');
      return new Promise((resolve, reject) => {
        resolve(response)
      })
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_FAIL });
    toast.error(error.response.data.title);
  }
};

export const getUnit = () => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'get',
        url: '/measurements-unit',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: GET_UNIT_MEASUREMENT, payload: res?.data?.unit })
        // dispatch({type:GET_SAVE_AS_NEW_QUOTATION,payload:res?.data})
      }
      // dispatch({
      //   type: GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
      //   payload: [
      //     ...res.data,
      //   ],
      // });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (err) {
      // console.log(err);
      // toast.error('Something went wrong!');
    }
  };
};

export const getMeasurementSystem = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: MEASUREMENTS_SYSYTEM_REQ })
      const res = await jwtAxios({
        method: 'get',
        url: `/measurements-unit`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: MEASUREMENTS_SYSYTEM_SUCCESS, payload: res?.data?.unit });
      }
    } catch (err) {
      // console.log(err);
      dispatch({ type: MEASUREMENTS_SYSYTEM_FAIL })
      // toast.error('Something went wrong!');
    }
  };
};

export const getMeasurementSystemwithoutLoader = () => {
  return async (dispatch) => {
    try {
      const res = await jwtAxios({
        method: 'get',
        url: `/measurements-unit`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: MEASUREMENTS_SYSYTEM_SUCCESS, payload: res?.data?.unit });
      }
    } catch (err) {
      // console.log(err);
      dispatch({ type: MEASUREMENTS_SYSYTEM_FAIL })
      // toast.error('Something went wrong!');
    }
  };
};

export const updateMeasurementSystem = (unit) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_MEASUREMENTS_SYSYTEM_REQ })
      const res = await jwtAxios({
        method: 'post',
        url: `/measurements-unit`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          unit: unit
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: SET_MEASUREMENTS_SYSYTEM_SUCCESS });
        toast.success('Measurements System Updated');
        dispatch(getMeasurementSystemwithoutLoader());
      }
    } catch (err) {
      // console.log(err);
      dispatch({ type: SET_MEASUREMENTS_SYSYTEM_FAIL })
      // toast.error('Something went wrong!');
    }
  };
};

export const getMeasurementSystemPdf = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: MEASUREMENTS_SYSYTEM_REQ_PDF })
      const res = await jwtAxios({
        method: 'get',
        url: `/pdf-measurements-unit`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: MEASUREMENTS_SYSYTEM_SUCCESS_PDF, payload: res?.data?.pdfMeasurementUnit });
      }
    } catch (err) {
      // console.log(err);
      dispatch({ type: MEASUREMENTS_SYSYTEM_FAIL_PDF })
      // toast.error('Something went wrong!');
    }
  };
};

export const getMeasurementSystemwithoutLoaderPdf = () => {
  return async (dispatch) => {
    try {
      const res = await jwtAxios({
        method: 'get',
        url: `/pdf-measurements-unit`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: MEASUREMENTS_SYSYTEM_SUCCESS_PDF, payload: res?.data?.pdfMeasurementUnit });
      }
    } catch (err) {
      // console.log(err);
      dispatch({ type: MEASUREMENTS_SYSYTEM_FAIL_PDF })
      toast.error('Something went wrong!');
    }
  };
};

export const updateMeasurementSystemPdf = (unit) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_MEASUREMENTS_SYSYTEM_REQ_PDF })
      const res = await jwtAxios({
        method: 'post',
        url: `/pdf-measurements-unit`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          pdfMeasurementUnit: unit
        },
      });
      if (res?.status >= 200 && res?.status <= 301) {
        dispatch({ type: SET_MEASUREMENTS_SYSYTEM_SUCCESS_PDF });
        toast.success('PDF Measurements System Updated');
        dispatch(getMeasurementSystemwithoutLoaderPdf());
      }
    } catch (err) {
      // console.log(err);
      dispatch({ type: SET_MEASUREMENTS_SYSYTEM_FAIL_PDF })
      toast.error('Something went wrong!');
    }
  };
};

export const combinedPdfOutput = (reqId, data) => async (dispatch) => {
  try {
    const checkedItems = data.filter(item => item.checked);
    const checkedQuoteIdsString = checkedItems.map(item => item.quoteId).join(',');
    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: 'loading' });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios({
      url: `/ffadmin/quotation/combine-pdf?request_id=${reqId}&quote_id=${decodeURIComponent(checkedQuoteIdsString)}&airline_show=yes`,
      method: 'post',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });
    // console.log(response)

    if (response.status === 200) {
      dispatch({ type: GET_PRINT_LOADER_STATUS, payload: false });
      toast.success('Printed Successfully');

      return new Promise((resolve, rejecct) => {
        resolve(response)
      })
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_PRINT_LOADER_STATUS, payload: false });
    toast.error(error?.response?.data?.title);
  }
};


export const setFavouriteAirport = (data, cid) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffcompany/add-airport', data, config);

    if (response.status === 200) {
      dispatch(getFreightForwarderAirportList(cid))
      toast.success('Airport saved successfully.');
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.response.data.title);
  }
};

export const getFreightForwarderAirportList = (cid) => async (dispatch) => {
  try {
    dispatch({ type: SET_FAVROUITE_AIRPORT_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`ffcompany/airport-list?company_id=${cid}`, config);
    if (response.status === 200) {
      dispatch({
        type: SET_FAVROUITE_AIRPORT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: SET_FAVROUITE_AIRPORT_FAIL });

    toast.error('Internal server error');
  }
};


// export const deletFavrouitList = (id,cid) => async (dispatch) => {
//   try {
//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${localStorage.getItem('token')}`,
//       },
//     };

//     const response = await jwtAxios({
//       url: `/ffcompany/remove-airport?id=${id}`,
//       method: 'delete',
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('token')}`,
//         // data,
//       },
//     });
//     if (response.status === 200) {
//       dispatch(getFreightForwarderAirportList(cid))
//       toast.success('Airport Deleted Successfully')
//     }
//   } catch (error) {
//     toast.error('Internal server error');
//   }
// };

export const deletFavrouitList = (id, cid) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        url: `/ffcompany/delete-airport?id=${id}`,
        method: `DELETE`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (200 <= res.status && res.status < 300) {
        toast.success('Airport Deleted Successfully');
        dispatch(getFreightForwarderAirportList(cid))

        // dispatch(retrieveWarehouseListAction());
        // dispatch(retrieveWarehouseListDataAction(pageNoForDelete, pageSize))

        return new Promise((resolve, _) => {
          resolve(res);
        });
      } else {
        toast.error(`Internal Server Error`);

        return new Promise((resolve, reject) => {
          reject(res);
        });
      }
    } catch (error) {
      toast.error(`Internal Server Error`);

      return new Promise((resolve, reject) => {
        reject(error?.response);
      });
    }
  };
};

export const getPreferredAirportsAdmin = (companyId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.get(
      `/ffcompany/airport-list?company_id=${companyId}`,
      config,
    );

    if (response.status === 200) {
      dispatch({
        type: GET_PREFERRED_AIRPORTS_LIST,
        payload: response.data
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // toast.error("Something went wrong!");
  }
};

export const getIncomingQuoteManualQuotation = (id) => async (dispatch) => {
  if (id != '') {
    try {
      dispatch({ type: GET_INCOMING_MANAULQUOTE_DETAILS_REQ });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffcompany/client-manual-quotation/${id}`, config);

      if (response.status === 200) {
        dispatch({ type: GET_INCOMING_MANAULQUOTE_DETAILS_SUCCESS, payload: response.data });
        dispatch(getNotificationsCount());
        dispatch(getAllNotifications());
        dispatch(notificationMenuCountData());
      }
    } catch (error) {
      // console.log(error.message);
      dispatch({ type: GET_INCOMING_MANAULQUOTE_DETAILS_FAIL });
      toast.error('Something went wrong!');
    }
  }
  else {
    dispatch({ type: GET_MANUAL_QUOTATION_SUCCESS, payload: '' });

  }
};

export const getIncomingAgentList = (role) => async (dispatch) => {
  try {
    dispatch({ type: GET_INCOMING_AGENT_LIST_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`ffcompany/user-list/${role}`, config);
    if (response.status === 200) {
      console.log(response, 'res')
      dispatch({
        type: GET_INCOMING_AGENT_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: GET_INCOMING_AGENT_LIST_FAIL });

    toast.error('Internal server error');
  }
};

export const getIncomingAgentLists = (role) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    // const response = await jwtAxios.get(`ffcompany/user-list/${role}`, config);

    const response = await jwtAxios.get(role === `admin` ? `ffcompany/user-list/${role}` : `ffcompany/user-list`, config);

    if (response.status === 200) {
      return new Promise((resolve, _) => {
        resolve(response);
      })
    }

  } catch (error) {
    toast.error('Internal server error');
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const postHostReassignAPI = (roleType, userID, alphanumericLicenseKey) => {

  const post_data = {
    key: alphanumericLicenseKey,
    ffuserId: userID,
    keyType: roleType === 'All' ? 'agent' : roleType,
    // keyType: roleType,
  };

  const apiUrl = roleType === 'admin' ? `/admin-key/host-reassign`
    : roleType === 'agent' ? `/agent-key/host-reassign`
      : roleType === 'All' ? `/agent-key/host-reassign`
        : `/client-key/host-reassign`;

  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'POST',
        // url: `/client-key/host-reassign`,
        url: apiUrl,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          ...post_data,
        },
      });

      if (res.status === 200) {
        toast.success('Host Reassigned Successfully');
        return new Promise((resolve, _) => {
          resolve(res);
        });
      }

    } catch (err) {
      if (err.response?.data?.detail != undefined) {
        toast.error(err.response.data?.detail, { autoClose: 3500 });
        return new Promise((resolve, reject) => {
          reject(err.response);
        });
      } else {
        toast.error('Internal Server Error');
        return new Promise((resolve, reject) => {
          reject(err.response);
        });
      }
    }
  };
};

export const getHostReassignDetails = (key, roleType) => async (dispatch) => {
  try {

    dispatch({ type: GET_HOST_REASSIGN_DETAILS_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const apiUrl = roleType === 'admin' ? `/admin-key/host-reassign/details/${key}`
      : roleType === 'agent' ? `/agent-key/host-reassign/details/${key}`
        : roleType === 'All' ? `/agent-key/host-reassign/details/${key}`
          : `/client-key/host-reassign/details/${key}`;

    // const response = await jwtAxios.get(`/client-key/host-reassign/details/${key}`, config);
    const response = await jwtAxios.get(apiUrl, config);

    if (response.status === 200) {
      dispatch({
        type: GET_HOST_REASSIGN_DETAILS_SUCCESS,
        payload: response.data,
      });
    }

  } catch (error) {
    dispatch({ type: GET_HOST_REASSIGN_DETAILS_FAIL });
    // toast.error(error?.response?.data?.title ? error?.response?.data?.title : '');

    dispatch({
      type: GET_HOST_REASSIGN_DETAILS_SUCCESS,
      payload: {},
    });

  }
};

export const assignAgent = (requestId, ffUserId) => {
  return async (dispatch, getState) => {
    // const post_data = {
    //   id: id,
    //   disclaimer: disclaimer,
    // };
    try {
      const header = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
      // const body = { ...post_data };

      const res = await jwtAxios.put(`/ffadmin/assign-client/incoming-request?requestId=${requestId}&ffUserId=${ffUserId}`, '', { headers: header });
      if (res?.status >= 200 && res.status < 300) {
        // dispatch({ type: GET_DISCLAIMER_DATA, payload: res?.data });
        toast.success(res?.data);
        return new Promise((resolve, _) => {
          resolve(res);
        })
      }
    } catch (error) {
      if (error?.response?.data?.title != undefined) {
        toast.error(error?.response?.data?.title, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};


export const getPriceBasedOnrole = () =>

  async (dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await jwtAxios.get(`/ffadmin/subscription`, {
        ...config,
      });

      if (response.status == 200)
        return new Promise((resolve, reject) => {
          resolve(response);
        });

    } catch (error) {
      toast.error(error?.response?.data?.title);
      // console.log(error?.response?.data?.title);
      return new Promise((resolve, reject) => {
        reject(error.response);
      });
    }
  };


export const renewAddOn = (data) => async (dispatch) => {
  if (Object.keys(data).length != 0)
    try {
      dispatch({ type: RENEW_ADD_ON_REQ });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.post('/stripe/renew-key/create-payment', data, config);

      if (response.status === 200) {
        // dispatch({ type: RENEW_ADD_ON_SUCCESS });
        console.log(response, 'response')
        dispatch({ type: RENEW_ADD_ON_SUCCESS, payload: response?.data?.clientSecret })
        return new Promise((resolve, reject) => {
          resolve(response)
        })
      }
    } catch (error) {
      // console.log(error.message);
      dispatch({ type: RENEW_ADD_ON_FAIL });
      toast.error('Something went wrong.');
    }
  else
    dispatch({ type: RENEW_ADD_ON_SUCCESS, payload: '' })

};


export const renewaddonsPaypal = (data) => async (dispatch) => {
  try {
    dispatch({ type: RENEW_ADD_ON_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post('/paypal/renew-key/create-payment', data, config);
    // console.log(response)
    if (response.status === 200) {

      dispatch({ type: RENEW_ADD_ON_SUCCESS, payload: '' })
      return new Promise((resolve, reject) => {
        resolve(response)
      })
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: RENEW_ADD_ON_FAIL });
    toast.error('Something went wrong.');
  }
};

export const paymentSuccess = (clientKey, status, tid) => async (dispatch) => {

  try {
    const res = await jwtAxios({
      url: `/stripe/capture?id=${tid}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (res?.status >= 200 && res.status <= 300)
      dispatch({ type: SAVE_TRANSACTION_ID, payload: res?.data })
    dispatch({ type: PAYPAL_STATUS, payload: {} })

    // dispatch({
    //   type: 'RETRIEVE_AIRFARE_LIST',
    //   payload: [
    //     ...res.data
    //   ]
    // })
    return new Promise((resolve, reject) => {
      resolve(res)
    })

  } catch (error) {
    toast.error(`Internal Server Error`);
    // return new Promise((resolve,reject)=>{
    //   reject(res)
    // })
  }
}


export const getPaypalPayemtDetail = (orderID) => async (dispatch) => {
  try {
    const response = await jwtAxios({
      url: `/paypal/capture?token=${orderID}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.status === 200) {
      // console.log(response, 'paypal api')
      dispatch({ type: PAYPAL_STATUS, payload: response?.data })
      dispatch({ type: SAVE_TRANSACTION_ID, payload: {} })

      return new Promise((resolve, reject) => {
        resolve(response)
      })
      // dispatch(getAgentApprovedAddons());
    }
  } catch (error) {
    return new Promise((resolve, reject) => {
      resolve(error)
    })
  }
};

export const renewAddonsData = (data) => async (dispatch) => {
  dispatch({ type: SAVE_ADDONS_DATA, payload: data })
}

export const handleBackButtonFromKeyManagement = (data) => async (dispatch) => {
  if (Object.keys(data)?.length == 0) {
    dispatch({ type: HANDLE_BACK_BUTTON_IN_KEYMANAGEMENT, payload: data })

  }
  else {
    dispatch({ type: HANDLE_BACK_BUTTON_IN_KEYMANAGEMENT, payload: data })

  }
}

export const actionKeyComesFrom = (data) => async (dispatch) => {
  dispatch({ type: 'SAVE_FOR_BACK_BUTTON_HANDLE', payload: data })
}
export const savePaymentState = (data) => async (dispatch) => {
  dispatch({ type: 'SAVE_PAYMENT_STAE', payload: data })
}

export const fromWhichTab = (data) => async (dispatch) => {
  dispatch({ type: 'SAVE_FROM_WHICH_TAB', payload: data })
}


export const getAirlineListForManaul = (cid) => async (dispatch) => {
  try {
    const response = await jwtAxios({
      url: `ffadmin/manual-airline/list?companyId=${cid}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.status === 200) {
      // console.log(response, 'paypal api')
      dispatch({ type: 'MANUAL_AIRLINE_LIST', payload: response?.data })
      // dispatch({ type: SAVE_TRANSACTION_ID, payload: {} })
      console.log(response)
      return new Promise((resolve, reject) => {
        resolve(response)
      })
      // dispatch(getAgentApprovedAddons());
    }
  } catch (error) {
    return new Promise((resolve, reject) => {
      resolve(error)
    })
  }
};


export const addManaulAirline = (data, cid) => async (dispatch) => {
  try {

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(`/ffadmin/manual-airline/create`, data, config);
    // console.log(response)
    if (response.status === 200) {
      console.log(response)
      // dispatch({ type: RENEW_ADD_ON_SUCCESS, payload: '' })
      return new Promise((resolve, reject) => {
        resolve(response)
      })
    }
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: RENEW_ADD_ON_FAIL });
    toast.error('Something went wrong.');
  }
};

export const retrieveExtraSurchareListAction = () => async (dispatch, getState) => {
  try {
    const res = await jwtAxios({
      method: 'GET',
      url: `/airfare/surcharges`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    dispatch({
      type: 'RETRIEVE_EXTRA_SURCHARGE_LIST',
      payload: [...res.data],
    });


    if (res.status >= 200 && res.status < 300) {
      return new Promise((resolve, _) => {
        resolve(res);
      });
    }

  } catch (error) {
    toast.error('Internal Server Error');
    return new Promise((resolve, reject) => {
      reject(error?.response);
    });
  }
};

export const createContractRate = (formikState, selectedDate, selectedEndDate) => {
  const selectedStartDate = selectedDate ? moment(new Date(selectedDate)).format('YYYY-MM-DD') : '';
  const selectedEndDateChange = selectedEndDate ? moment(new Date(selectedEndDate)).format('YYYY-MM-DD') : '';
  const post_data = {
    airlineCompanyId: formikState?.airlineCompanyId,
    ratesType: 'RATE_PRIORITY',
    fromStations: formikState?.fromAirport.map((item) => item.label.split(',')[0]),
    toStation: formikState?.toAirport.map((item) => item.label.split(',')[0]),
    // typeOfGoods: formikState?.goodsCategory,
    typeOfGoods: formikState?.typeOfGoodNames,
    minCharges: (formikState?.minCharge),
    timeDuration: formikState?.timeDuration,
    eawbFee: (formikState?.eawbFee),
    mawbFee: (formikState?.mawbFee),
    hawbFee: (formikState?.hawbFee),
    gracePeriod: formikState?.gracePeriod,
    unNumberFee: (formikState.unNumberFee == '' || formikState.unNumberFee == null || formikState.unNumberFee == undefined) ? 0 : convertStrToNum(formikState.unNumberFee),
    additionalUnNumberFee: (formikState.additionalUnNumberFee == '' || formikState.additionalUnNumberFee == null || formikState.additionalUnNumberFee == undefined) ? 0 : convertStrToNum(formikState.additionalUnNumberFee),
    // startDate: selectedStartDate ? selectedStartDate : '',
    // expiryDate: selectedEndDateChange ? selectedEndDateChange : '',
    startDate: formikState?.startDate ? moment(formikState?.startDate).format('YYYY-MM-DD') : '',
    expiryDate: formikState?.endDate ? moment(formikState?.endDate).format('YYYY-MM-DD') : '',
    perUnitCharges: formikState?.perUnitCharges?.map((item) => ({
      lowerRange: item?.lowerRange,
      upperRange: item?.upperRange,
      perUnitCharge: item?.perUnitCharge ? parseFloat(item?.perUnitCharge.replace(/,/g, '')) : '',
      surchargePricing: item?.surchargePricing,
      applicableSurcharge: item?.applicableSurcharge ? item?.applicableSurcharge : [],
    })),
    airlineSurcharges: formikState?.airlineSurcharges ? [
      ...formikState?.airlineSurcharges?.map((item) => ({
        additionSuchargeLabale: item?.additionSuchargeLabale,
        additionSuchargeUnit: item?.additionSuchargeUnit,
        additionSuchargeValue:
          item?.additionSuchargeValue != ''
            ? (item?.additionSuchargeValue.toString())
            : 0.0,
        additionSurchargeMinimumValue:
          item?.additionSurchargeMinimumValue != ''
            ? (item?.additionSurchargeMinimumValue.toString())
            : 0.0,
      })),
    ] : [],
  };
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'POST',
        url: '/ffadmin/manual-airfare/create',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          ...post_data,
        },
      });

      // if (res.status < 200 || res.status > 300) {
      //   toast.error('Oops! There was an error');
      // }

      if (res.status === 200) {
        console.log(res)
        toast.success('Record Created Successfully');
        return new Promise((resolve, _) => {
          resolve(res);
        });
      }
      // else {
      //   toast.error(res?.status?.data?.title);
      //   return new Promise((resolve, _) => {
      //     resolve(res);
      //   });
      // }

    } catch (err) {

      // if (err.message == "Request failed with status code 404") {
      //   // console.log("Internal Server Error 1 " + err.message + " abc " + err.response);
      //   // alert(JSON.stringify(err.response.data));
      // }

      if (err.response?.data?.detail != undefined) {
        toast.error(err.response.data?.detail, { autoClose: 3500 });
        return new Promise((resolve, reject) => {
          reject(err.response);
        });
      } else {
        toast.error('Internal Server Error');
        return new Promise((resolve, reject) => {
          reject(err.response);
        });
      }

      // if (err.response?.data?.title != undefined) {
      //   toast.error(err.response.data?.title, { autoClose: 3500 });
      //   return new Promise((resolve, _) => {
      //     resolve(err.response);
      //   });
      // } else if (err.response?.data != undefined) {
      //   toast.error(err?.response?.data, { autoClose: 3500 });
      //   return new Promise((resolve, _) => {
      //     resolve(err.response);
      //   });
      // } else {
      //   toast.error('Internal Server Error');
      //   return new Promise((resolve, _) => {
      //     resolve(err.response);
      //   });
      // }
    }
  };
};

export const retrieveContractRate = (airfare_id) => async (dispatch, getState) => {
  try {
    const res = await jwtAxios({
      method: 'GET',
      url: `/ffadmin/manual-airfare/${airfare_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    dispatch({
      type: 'VIEW_AIRRATES_DETAILS',
      payload: {
        ...res.data,
      },
    });
  } catch (error) {
    toast.error('Internal Server Error');
    console.log(error);
  }
};

export const putDataForcontract = (formikState, selectedDate, selectedEndDate, id) => {

  // let filteredResultForfreightForwarder = freight_forwarder_company_list.find(
  //   (e) => e.companyName == formikState.freightForwarder.label,
  // );

  if (formikState?.startDate.charAt(2) === '-' || formikState?.startDate.charAt(1) === '-') {
    let StartDate = new Date(formikState?.startDate);
    let dd = String(StartDate.getDate()).padStart(2, '0');
    let mm = String(StartDate.getMonth() + 1).padStart(2, '0');
    let yyyy = StartDate.getFullYear();
    formikState.startDate = yyyy + '-' + mm + '-' + dd;
  }
  if (formikState?.endDate.charAt(2) === '-' || formikState?.endDate.charAt(1) === '-') {
    let EndDate = new Date(formikState?.endDate);
    let dd = String(EndDate.getDate()).padStart(2, '0');
    let mm = String(EndDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = EndDate.getFullYear();
    formikState.endDate = yyyy + '-' + mm + '-' + dd;
  }

  const put_data = {
    id: id,
    ratesType: 'RATE_PRIORITY',
    airlineCompanyId: formikState?.airlineCompanyId,
    // ffCompanyId: filteredResultForfreightForwarder?.id,
    freightForwarder: formikState?.freightForwarder ? formikState?.freightForwarder : '',
    fromStation: formikState?.fromAirport ? formikState?.fromAirport[0].label.split(',')[0] : '',
    toStation: formikState?.toAirport ? formikState?.toAirport[0].label.split(',')[0] : '',
    typeOfGoods: formikState ? formikState.typeOfGoods : "",
    gracePeriod: formikState ? formikState.gracePeriod : "",
    // typeOfGoods: filter ? filter : '',
    eawbFee: formikState?.eawbFee !== undefined && formikState?.eawbFee !== null ? formikState.eawbFee.toString() : '',
    mawbFee: formikState?.mawbFee !== undefined && formikState?.mawbFee !== null ? formikState.mawbFee.toString() : '',
    hawbFee: formikState?.hawbFee !== undefined && formikState?.hawbFee !== null ? formikState.hawbFee.toString() : '',
    minCharges: formikState?.minCharge !== undefined && formikState?.minCharge !== null ? formikState.minCharge.toString() : '',
    timeDuration: formikState?.timeDuration !== undefined && formikState?.timeDuration !== null ? formikState.timeDuration.toString() : '',
    // minCharges: formikState?.minCharge ? (formikState?.minCharge.toString()) : '',
    // timeDuration: formikState?.timeDuration ? formikState?.timeDuration : '',

    startDate: formikState?.startDate ? moment(formikState.startDate).format('YYYY-MM-DD') : '',
    expiryDate: formikState?.endDate ? moment(formikState.endDate).format('YYYY-MM-DD') : '',

    // startDate: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : '',
    // expiryDate: selectedEndDate ? moment(selectedEndDate).format('YYYY-MM-DD') : '',

    // eawbFee: formikState?.eawbFee ? (formikState?.eawbFee.toString()) : '',
    // mawbFee: formikState?.mawbFee ? (formikState?.mawbFee.toString()) : '',
    // hawbFee: formikState?.hawbFee ? (formikState?.hawbFee.toString()) : '',
    unNumberFee: (formikState.unNumberFee == '' || formikState.unNumberFee == null || formikState.unNumberFee == undefined) ? 0 : (formikState.unNumberFee),
    additionalUnNumberFee: (formikState.additionalUnNumberFee == '' || formikState.additionalUnNumberFee == null || formikState.additionalUnNumberFee == undefined) ? 0 : (formikState.additionalUnNumberFee),
    perUnitCharges: formikState?.perUnitCharges ? formikState?.perUnitCharges.map((item) => ({
      lowerRange: item?.lowerRange,
      upperRange: item?.upperRange,
      perUnitCharge: item?.perUnitCharge,
      surchargePricing: item?.surchargePricing,
      applicableSurcharge: item?.applicableSurcharge ? item?.applicableSurcharge : [],
    })) : [],
    airlineSurcharges: formikState?.airlineSurcharges ? [
      ...formikState.airlineSurcharges?.map((item) => ({
        additionSuchargeLabale: item.additionSuchargeLabale,
        additionSuchargeUnit: item.additionSuchargeUnit,
        additionSuchargeValue:
          item.additionSuchargeValue != ''
            ? item.additionSuchargeValue != undefined ? (item?.additionSuchargeValue?.toString()) : 0.0
            : 0.0,
        additionSurchargeMinimumValue:
          item.additionSurchargeMinimumValue != ''
            ? item.additionSurchargeMinimumValue != undefined ? (item?.additionSurchargeMinimumValue?.toString()) : 0.0
            : 0.0,
      })),
    ] : [],
  };

  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'put',
        url: '/ffadmin/manual-airfare/update',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          ...put_data,
        },
      });
      console.log(res, 'inside ffadmin action')
      if (res.status >= 200 && res.status <= 300) {
        toast.success('Record Updated Successfully');
        return new Promise((resolve, _) => {
          resolve(res);
        });
      }
    } catch (error) {
      // if (
      //   (error.response?.data?.fieldErrors?.[0]?.message &&
      //     error.response?.data?.fieldErrors?.[0]?.field) != undefined
      // ) {
      //   toast.error(
      //     error.response?.data?.fieldErrors?.[0]?.field +
      //     ' ' +
      //     error.response.data?.fieldErrors?.[0]?.message,
      //     { autoClose: 1500 },
      //   );
      // } else


      if (error?.response?.data?.detail != undefined) {
        toast.error(error?.response?.data?.detail, { autoClose: 3500 });
        return new Promise((resolve, reject) => {
          reject(error.response);
        });
      } else {
        toast.error('Internal Server Error');
        return new Promise((resolve, reject) => {
          reject(error.response);
        });
      }
    }
  };
};


export const createSaveAsNewForairrate = (formikState, selectedDate, selectedEndDate, id) => {

  if (formikState?.startDate.charAt(2) === '-' || formikState?.startDate.charAt(1) === '-') {
    let StartDate = new Date(formikState?.startDate);
    let dd = String(StartDate.getDate()).padStart(2, '0');
    let mm = String(StartDate.getMonth() + 1).padStart(2, '0');
    let yyyy = StartDate.getFullYear();
    formikState.startDate = yyyy + '-' + mm + '-' + dd;
  }
  if (formikState?.endDate.charAt(2) === '-' || formikState?.endDate.charAt(1) === '-') {
    let EndDate = new Date(formikState?.endDate);
    let dd = String(EndDate.getDate()).padStart(2, '0');
    let mm = String(EndDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = EndDate.getFullYear();
    formikState.endDate = yyyy + '-' + mm + '-' + dd;
  }

  const put_data = {
    id: id,
    ratesType: 'RATE_PRIORITY',
    airlineCompanyId: formikState?.airlineCompanyId,
    // ffCompanyId: filteredResultForfreightForwarder?.id,
    freightForwarder: formikState?.freightForwarder ? formikState?.freightForwarder : '',
    fromStations: formikState?.fromAirport.map((item) => item.label.split(',')[0]),
    toStation: formikState?.toAirport.map((item) => item.label.split(',')[0]),
    typeOfGoods: formikState ? formikState.typeOfGoods : "",
    gracePeriod: formikState ? formikState.gracePeriod : "",
    // typeOfGoods: filter ? filter : '',
    // minCharges: formikState?.minCharge ? (formikState?.minCharge.toString()) : '',
    // minCharges: formikState?.minCharge !== undefined && formikState?.minCharge !== null ? formikState.minCharge.toString() : '',
    // timeDuration: formikState?.timeDuration !== undefined && formikState?.timeDuration !== null ? formikState.timeDuration.toString() : '',

    // timeDuration: formikState?.timeDuration ? formikState?.timeDuration : '',
    startDate: formikState ? moment(formikState.startDate).format('YYYY-MM-DD') : '',
    expiryDate: formikState ? moment(formikState.endDate).format('YYYY-MM-DD') : '',
    // startDate: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : '',
    // expiryDate: selectedEndDate ? moment(selectedEndDate).format('YYYY-MM-DD') : '',

    eawbFee: formikState?.eawbFee !== undefined && formikState?.eawbFee !== null ? formikState.eawbFee.toString() : '',
    mawbFee: formikState?.mawbFee !== undefined && formikState?.mawbFee !== null ? formikState.mawbFee.toString() : '',
    hawbFee: formikState?.hawbFee !== undefined && formikState?.hawbFee !== null ? formikState.hawbFee.toString() : '',
    minCharges: formikState?.minCharge !== undefined && formikState?.minCharge !== null ? formikState.minCharge.toString() : '',
    timeDuration: formikState?.timeDuration !== undefined && formikState?.timeDuration !== null ? formikState.timeDuration.toString() : '',
    // mawbFee: formikState?.mawbFee ? (formikState?.mawbFee.toString()) : '0',
    // hawbFee: formikState?.hawbFee ? (formikState?.hawbFee.toString()) : '0',
    unNumberFee: (formikState.unNumberFee == '' || formikState.unNumberFee == null || formikState.unNumberFee == undefined) ? 0 : (formikState.unNumberFee),
    additionalUnNumberFee: (formikState.additionalUnNumberFee == '' || formikState.additionalUnNumberFee == null || formikState.additionalUnNumberFee == undefined) ? 0 : (formikState.additionalUnNumberFee),
    perUnitCharges: formikState?.perUnitCharges ? formikState?.perUnitCharges.map((item) => ({
      lowerRange: item?.lowerRange,
      upperRange: item?.upperRange,
      perUnitCharge: item?.perUnitCharge,
      surchargePricing: item?.surchargePricing,
      applicableSurcharge: item?.applicableSurcharge ? item?.applicableSurcharge : [],
    })) : [],
    airlineSurcharges: formikState?.airlineSurcharges ? [
      ...formikState.airlineSurcharges?.map((item) => ({
        additionSuchargeLabale: item.additionSuchargeLabale,
        additionSuchargeUnit: item.additionSuchargeUnit,
        additionSuchargeValue:
          item.additionSuchargeValue != ''
            ? item.additionSuchargeValue != undefined ? (item?.additionSuchargeValue?.toString()) : 0.0
            : 0.0,
        additionSurchargeMinimumValue:
          item.additionSurchargeMinimumValue != ''
            ? item.additionSurchargeMinimumValue != undefined ? (item?.additionSurchargeMinimumValue?.toString()) : 0.0
            : 0.0,
      })),
    ] : [],
  };

  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'post',
        url: '/ffadmin/manual-airfare/create',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          ...put_data,
        },
      });
      console.log(res, 'inside ffadmin action')
      if (res.status >= 200 && res.status <= 300) {
        toast.success('Record Updated Successfully');
        return new Promise((resolve, _) => {
          resolve(res);
        });
      }
    } catch (error) {


      if (error?.response?.data?.detail != undefined) {
        toast.error(error?.response?.data?.detail, { autoClose: 3500 });
        return new Promise((resolve, reject) => {
          reject(error.response);
        });
      } else {
        toast.error('Internal Server Error');
        return new Promise((resolve, reject) => {
          reject(error.response);
        });
      }
    }
  };
};


export const saveAsNewContractRateModal = (formikState, selectedDate, selectedEndDate, id) => async (dispatch) => {

  if (formikState?.startDate.charAt(2) === '-' || formikState?.startDate.charAt(1) === '-') {
    let StartDate = new Date(formikState?.startDate);
    let dd = String(StartDate.getDate()).padStart(2, '0');
    let mm = String(StartDate.getMonth() + 1).padStart(2, '0');
    let yyyy = StartDate.getFullYear();
    formikState.startDate = yyyy + '-' + mm + '-' + dd;
  }
  if (formikState?.endDate.charAt(2) === '-' || formikState?.endDate.charAt(1) === '-') {
    let EndDate = new Date(formikState?.endDate);
    let dd = String(EndDate.getDate()).padStart(2, '0');
    let mm = String(EndDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = EndDate.getFullYear();
    formikState.endDate = yyyy + '-' + mm + '-' + dd;
  }

  const put_data = {
    id: id,
    ratesType: 'RATE_PRIORITY',
    airlineCompanyId: formikState?.airlineCompanyId,
    provider: formikState?.provider,
    // ffCompanyId: filteredResultForfreightForwarder?.id,
    // freightForwarder: formikState?.freightForwarder ? formikState?.freightForwarder : '',
    fromStation: formikState?.fromAirport.map((item) => item.label.split(',')[0]),
    toStation: formikState?.toAirport.map((item) => item.label.split(',')[0]),
    typeOfGoods: formikState ? formikState.typeOfGoods : "",
    // typeOfGoods: filter ? filter : '',
    minCharges: formikState?.minCharge !== undefined && formikState?.minCharge !== null ? formikState.minCharge.toString() : '',
    // minCharges: formikState?.minCharge ? (formikState?.minCharge.toString()) : '',
    // timeDuration: formikState?.timeDuration ? formikState?.timeDuration : '',
    timeDuration: formikState?.timeDuration !== undefined && formikState?.timeDuration !== null ? formikState.timeDuration.toString() : '',
    gracePeriod: formikState ? formikState.gracePeriod : "",
    // startDate: formikState ? moment(formikState.startDate).format('YYYY-MM-DD') : '',
    // expiryDate: formikState ? moment(formikState.endDate).format('YYYY-MM-DD') : '',
    startDate: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : '',
    expiryDate: selectedEndDate ? moment(selectedEndDate).format('YYYY-MM-DD') : '',
    // eawbFee: formikState?.eawbFee ? (formikState?.eawbFee.toString()) : '',
    // mawbFee: formikState?.mawbFee ? (formikState?.mawbFee.toString()) : '',
    // hawbFee: formikState?.hawbFee ? (formikState?.hawbFee.toString()) : '',
    eawbFee: formikState?.eawbFee !== undefined && formikState?.eawbFee !== null ? formikState.eawbFee.toString() : '',
    mawbFee: formikState?.mawbFee !== undefined && formikState?.mawbFee !== null ? formikState.mawbFee.toString() : '',
    hawbFee: formikState?.hawbFee !== undefined && formikState?.hawbFee !== null ? formikState.hawbFee.toString() : '',
    unNumberFee: (formikState.unNumberFee == '' || formikState.unNumberFee == null || formikState.unNumberFee == undefined) ? 0 : (formikState.unNumberFee),
    additionalUnNumberFee: (formikState.additionalUnNumberFee == '' || formikState.additionalUnNumberFee == null || formikState.additionalUnNumberFee == undefined) ? 0 : (formikState.additionalUnNumberFee),
    perUnitCharges: formikState?.perUnitCharges ? formikState?.perUnitCharges.map((item) => ({
      lowerRange: item?.lowerRange,
      upperRange: item?.upperRange,
      perUnitCharge: item?.perUnitCharge,
      surchargePricing: item?.surchargePricing,
      applicableSurcharge: item?.applicableSurcharge ? item?.applicableSurcharge : [],
    })) : [],
    airlineSurcharges: formikState?.airlineSurcharges ? [
      ...formikState.airlineSurcharges?.map((item) => ({
        additionSuchargeLabale: item.additionSuchargeLabale,
        additionSuchargeUnit: item.additionSuchargeUnit,
        additionSuchargeValue:
          item.additionSuchargeValue != ''
            ? item.additionSuchargeValue != undefined ? (item?.additionSuchargeValue?.toString()) : 0.0
            : 0.0,
        additionSurchargeMinimumValue:
          item.additionSurchargeMinimumValue != ''
            ? item.additionSurchargeMinimumValue != undefined ? (item?.additionSurchargeMinimumValue?.toString()) : 0.0
            : 0.0,
      })),
    ] : [],
  };
  // let bodyData={
  //   // ratesType: 'RATE_PRIORITY',
  //   airlineCompanyId: formikState?.airlineCompanyId,
  //   // ffCompanyId: filteredResultForfreightForwarder?.id,
  //   // freightForwarder: formikState?.freightForwarder ? formikState?.freightForwarder : '',
  //   fromStation: formikState?.fromAirport.map((item) => item.label.split(',')[0]),
  //   toStation: formikState?.toAirport.map((item) => item.label.split(',')[0]),
  //   typeOfGoods: formikState ? formikState.typeOfGoods : "",
  // }
  try {

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/manual-airrate/check', put_data, config);

    if (response.status === 200) {
      console.log(response, 'response')
      // dispatch({
      //   type: CREATE_FFADMIN_QUOTATION_SUCCESS,
      //   payload: response.data,
      // });
      // dispatch({
      //   type: GET_SEARCH_BY_ZIP_SUCCESS,
      //   payload: ''
      // });
      // dispatch({
      //   type: GET_DELIVERY_ZIPCODE_SUCCESS,
      //   payload:'',
      // });
      // if (response.data.truckingCarriers.length != 0)
      //   dispatch({
      //     type: GET_TRUKING_DATA,
      //     payload: response.data.truckingCarriers,
      //   });
      // toast.success("Quotation created");

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
    // toast.error(error.response.data.title, { autoClose: 3500 });
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const saveAsNewContractRateModalWhileCreate = (formikState) => async (dispatch) => {
  let bodyData = {
    // ratesType: 'RATE_PRIORITY',
    airlineCompanyId: formikState?.airlineCompanyId,
    // ffCompanyId: filteredResultForfreightForwarder?.id,
    // freightForwarder: formikState?.freightForwarder ? formikState?.freightForwarder : '',
    fromStation: formikState?.fromAirport.map((item) => item.label.split(',')[0]),
    toStation: formikState?.toAirport.map((item) => item.label.split(',')[0]),
    typeOfGoods: formikState ? formikState.typeOfGoodNames : "",
  }
  try {

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffadmin/manual-airrate/check', bodyData, config);

    if (response.status === 200) {
      console.log(response, 'response')
      // dispatch({
      //   type: CREATE_FFADMIN_QUOTATION_SUCCESS,
      //   payload: response.data,
      // });
      // dispatch({
      //   type: GET_SEARCH_BY_ZIP_SUCCESS,
      //   payload: ''
      // });
      // dispatch({
      //   type: GET_DELIVERY_ZIPCODE_SUCCESS,
      //   payload:'',
      // });
      // if (response.data.truckingCarriers.length != 0)
      //   dispatch({
      //     type: GET_TRUKING_DATA,
      //     payload: response.data.truckingCarriers,
      //   });
      // toast.success("Quotation created");

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
    // toast.error(error.response.data.title, { autoClose: 3500 });
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};


export const selectCurrency = (id, cur) => {
  return async (dispatch, getState) => {
    const post_data = {
      userId: id,
      currency: cur,
    };
    try {
      const header = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
      const body = { ...post_data };

      const res = await jwtAxios.put(`/preferred-currency/ffuser/update`, body, { headers: header });

      if (200 <= res.status && res.status < 300) {
        dispatch(getPrefferedCurrencySelected(id))
        // dispatch({ type: GET_DISCLAIMER_DATA, payload: res?.data });
        toast.success('successfully');
      }
    } catch (error) {
      if (error?.response?.data?.title != undefined) {
        toast.error(error?.response?.data?.title, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

export const getPrefferedCurrencySelected = (id) => async (dispatch) => {
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/preferred-currency/ffuser/${id}`, config);

    if (response.status === 200) {
      // dispatch({
      //   type: GET_COUNTRY_SUCCESS,
      //   payload: response.data,
      // });
      dispatch({ type: 'GET_PREFERED_CURRENCY', payload: response?.data })
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    // dispatch({ type: GET_COUNTRY_FAIL });
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // toast.error("Something went wrong!");
  }
};


export const selectManualSettng = (id, type, type2) => {
  console.log(id, type)
  return async (dispatch, getState) => {
    const post_data = {
      companyId: id,
      isAllowManualQuotesAdmin: type === 'FF Admin Only' ? 'Yes' : 'No',
      isAllowManualQuotesAgent: type2 === 'FF Agent Only' ? 'Yes' : 'No'
    };
    console.log(post_data, 'post data')
    try {
      const header = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
      const body = { ...post_data };

      const res = await jwtAxios.put(`/manual-quote-setting/update`, body, { headers: header });

      if (200 <= res.status && res.status < 300) {
        dispatch(getManaualSetting(id))
        // dispatch({ type: GET_DISCLAIMER_DATA, payload: res?.data });
        toast.success('Manual quote feature access changed successfully', { autoClose: 2000 });
      }
    } catch (error) {
      if (error?.response?.data?.title != undefined) {
        toast.error(error?.response?.data?.title, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

export const getKeyManagementforSuperadmin = (cid, role, userId, status, page, pageSize, sorting, filtering, index) => async (dispatch) => {

  console.log(status)
  let assignStatus = status;
  switch (assignStatus) {
    case 'All':
      assignStatus = 'all';
      break;
    case 'Assign List':
      assignStatus = 'assign';
      break;
    case 'Unassign List':
      assignStatus = 'unassign';
      break;
    default:
      break;
  }
  dispatch({ type: GET_KEY_MANAGEMENT_ASSIGNED_KEY_REQ });
  const queryParams = {
    userId: userId,
    companyID: cid,
    role: role.toLowerCase(),
    userId: userId,
    // assignStatus:assignStatus.toLowerCase(),
    pageNum: page,
    pageSize: pageSize,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    // const response = await jwtAxios.get(
    //   `key-management/get-keys?companyID=${cid}&role=${role.toLowerCase()}&userId=${userId}&assignStatus=${assignStatus.toLowerCase()}&pageNum=${page}&pageSize=${pageSize}&filter=${JSON.stringify(filtering)}&sort=${JSON.stringify(sorting)}}`,
    //   config,
    // );
    const response = await jwtAxios.get('key-management/get-keys', {
      ...config,
      params: queryParams,
    });
    if (response.status == 200)
      // dispatch({type:GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS})
      dispatch({ type: GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS, payload: response.data });

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  } catch (error) {
    toast.error(error?.response?.data?.title);
    dispatch({ type: GET_KEY_MANAGEMENT_ASSIGNED_KEY_FAIL });
    // console.log(error?.response?.data?.title);
  }
};

export const getManaualSetting = (id) => async (dispatch) => {
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/manual-quote-setting/${id}`, config);

    if (response.status === 200) {
      // dispatch({
      //   type: GET_COUNTRY_SUCCESS,
      //   payload: response.data,
      // });
      dispatch({ type: 'MANAUL_SETTING', payload: response?.data })
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // console.log(error.message);
    // dispatch({ type: GET_COUNTRY_FAIL });
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // toast.error("Something went wrong!");
  }
};

export const getStaticDetails = (cid) => async (dispatch) => {

  if (cid != undefined) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffcompany-statistics/user-count?companyId=${cid}`, config);

      if (response.status === 200) {
        // dispatch({
        //   type: GET_COUNTRY_SUCCESS,
        //   payload: response.data,
        // });
        dispatch({ type: 'GET_FFADMIN_STATISTIC_ROLE_WISE_DATA', payload: response?.data })
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  else {
    console.log("Clearing FF Admin data");

    dispatch({ type: 'GET_FFADMIN_STATISTIC_ROLE_WISE_DATA', payload: null })

  }
}

export const getRevenueCostProfit = (cid) => async (dispatch) => {
  if (cid != undefined) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffcompany-statistics/quotation-quote-revenue?companyId=${cid}`, config);

      if (response.status === 200) {
        // dispatch({
        //   type: GET_COUNTRY_SUCCESS,
        //   payload: response.data,
        // });
        dispatch({ type: 'GET_FFADMIN_REVENUE_COST', payload: response?.data })
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  else
    dispatch({ type: 'GET_FFADMIN_REVENUE_COST', payload: '' })


}

export const getTopAirportList = (cid) => async (dispatch) => {
  if (cid != undefined) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffcompany-statistics/top-airport/to-station?companyId=${cid}`, config);

      if (response.status === 200) {
        // dispatch({
        //   type: GET_COUNTRY_SUCCESS,
        //   payload: response.data,
        // });
        let transformedData = response?.data && response?.data?.chart && Object.entries(response?.data?.chart).map(([key, value]) => {
          return { x: key.trim(), y: value }; // trim to remove any trailing spaces
        });
        dispatch({ type: 'GET_TOP_AIRPORT_LIST', payload: [{ data: transformedData }] })
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  else
    dispatch({ type: 'GET_TOP_AIRPORT_LIST', payload: [] })



}

export const barChartFFadmin = (cid, isCumlative) => async (dispatch) => {
  if (cid != undefined) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffcompany-statistics/quotation-count/group-by-user?companyId=${cid}&isCumlative=${isCumlative}`, config);

      if (response.status === 200) {
        // dispatch({
        //   type: GET_COUNTRY_SUCCESS,
        //   payload: response.data,
        // });
        dispatch({ type: 'GET_GRAPH_CHART_FFADMIN', payload: response?.data })
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  else

    dispatch({ type: 'GET_GRAPH_CHART_FFADMIN', payload: '' })


}

export const topQuoteDoneByUser = (cid, role, count) => async (dispatch) => {
  if (cid != undefined) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffcompany-statistics/top-user-by-quote?companyID=${cid}&role=${role}&contributionMetric=${count}`, config);

      if (response.status === 200) {
        // dispatch({
        //   type: GET_COUNTRY_SUCCESS,
        //   payload: response.data,
        // });
        if (count == 'quote_count')
          dispatch({ type: 'GET_QUOTE_COUNT', payload: response.data })
        else if (count == 'quote_amount')
          dispatch({ type: 'GET_QUOTE_AMOUNT', payload: response.data })
        else if (count == 'quote_margin')
          dispatch({ type: 'GET_QUOTE_MARGIN', payload: response.data })


        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  else {
    if (count == 'quote_count')
      dispatch({ type: 'GET_QUOTE_COUNT', payload: [] })
    else if (count == 'quote_amount')
      dispatch({ type: 'GET_QUOTE_AMOUNT', payload: [] })
    else if (count == 'quote_margin')
      dispatch({ type: 'GET_QUOTE_MARGIN', payload: [] })



  }

}

export const getFFadminBarchartData = (cid) => async (dispatch) => {
  if (cid != undefined) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffcompany-statistics/user-key/count-chart?companyId=${cid}`, config);

      if (response.status === 200) {
        // dispatch({
        //   type: GET_COUNTRY_SUCCESS,
        //   payload: response.data,
        // });
        dispatch({ type: 'GET_BAR_CHART_FFADMIN_DATA', payload: response?.data })
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  else {
    dispatch({ type: 'GET_BAR_CHART_FFADMIN_DATA', payload: '' })

  }


}

export const growthChart = (cid, role, count) => async (dispatch) => {
  if (cid != undefined) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(`/ffcompany-statistics/quotation-quote-revenue?companyId=${cid}`, config);

      if (response.status === 200) {
        // dispatch({
        //   type: GET_COUNTRY_SUCCESS,
        //   payload: response.data,
        // });

        console.log(response.data.reportCards)
        // if(count=='quote_count')
        dispatch({ type: 'GET_TOTAL_REVENUE', payload: response?.data?.reportCards?.[0] })
        // else if(count=='quote_amount')
        dispatch({ type: 'GET_TOTAL_PROFIT', payload: response?.data?.reportCards?.[1] })
        //  else if(count=='quote_margin')
        dispatch({ type: 'GET_TOTAL_QUOTES', payload: response?.data?.reportCards?.[2] })


        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  else {
    if (count == 'quote_count')
      dispatch({ type: 'GET_QUOTE_COUNT', payload: [] })
    else if (count == 'quote_amount')
      dispatch({ type: 'GET_QUOTE_AMOUNT', payload: [] })
    else if (count == 'quote_margin')
      dispatch({ type: 'GET_QUOTE_MARGIN', payload: [] })



  }

}



