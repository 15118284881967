import React, { lazy } from 'react';

export const airlineAdminRouteConfig = [
  {
    auth: ['ROLE_AIRLINE_ADMIN'],
    routes: [
      // MY PROFILE ROUTES
      {
        path: '/airline/admin/profile/update',
        component: lazy(() => import('pages/mainPages/Airline/Admin/Profile/UserProfile/UserProfile.js')),
      },
      //FREIGHT FORWARDER ROUTES
      {
        path: '/airline/admin/freight-forwarder/create',
        component: lazy(() =>
          import('pages/mainPages/Airline/Admin/FreightForwarder/AddFreightForwarder'),
        ),
      },
      {
        path: '/airline/admin/freight-forwarder/list/view',
        component: lazy(() =>
          import('pages/mainPages/Airline/Admin/FreightForwarder/FreightForwarderList'),
        ),
      },
      // HISTORY
      {
        path: '/airline/admin/history/list',
        component: lazy(() => import('pages/mainPages/Airline/Admin/History/HistoryList')),
      },
      {
        path: '/airline/admin/history/view/:airrateID',
        component: lazy(() => import('pages/mainPages/Airline/Admin/SpotRate/Update/historyViewIndex')),
      },
      // Weight Range
      {
        path: '/airline/admin/wieght-range/create',
        component: lazy(() => import('pages/mainPages/Airline/Admin/WeightRange/Create')),
      },
      {
        path: '/airline/admin/wieght-range/update',
        component: lazy(() => import('pages/mainPages/Airline/Admin/WeightRange/Update')),
      },

      // Sur Charge
      {
        path: '/airline/admin/sur-charge/create',
        component: lazy(() => import('pages/mainPages/Airline/Admin/SurCharge/Create')),
      },
      {
        path: '/airline/admin/sur-charge/update',
        component: lazy(() => import('pages/mainPages/Airline/Admin/SurCharge/Update')),
      },

      //General Rate
      {
        path: '/airline/admin/general-rate/create',
        component: lazy(() => import('pages/mainPages/Airline/Admin/GeneralRate/Create')),
      },
      {
        path: '/airline/admin/general-rate/upload-rates',
        component: lazy(() => import('pages/mainPages/Airline/Admin/GeneralRate/UploadRates/index')),
      },
      {
        path: '/airline/admin/general-rate/list/view',
        component: lazy(() => import('pages/mainPages/Airline/Admin/GeneralRate/List')),
      },
      {
        path: '/airline/admin/general-rate/edit/:airfare_id',
        component: lazy(() => import('pages/mainPages/Airline/Admin/GeneralRate/Update')),
      },
      // {
      //   path: '/airline/admin/general-rate/surcharge',
      //   component: lazy(() => import('pages/mainPages/Airline/Admin/GeneralRate/Surcharge')),
      // },
      // {
      // 	path: "/airline/admin/general-rate/:airfare_id/range-details/view",
      // 	component: lazy(() => import("pages/mainPages/Airline/Admin/GeneralRate/ListGeneralRanges")),
      // },

      //Priority Rate
      {
        path: '/airline/admin/contract-rate/create',
        component: lazy(() => import('pages/mainPages/Airline/Admin/PriorityRate/Create')),
      },
      {
        path: '/airline/admin/contract-rate/upload-rates',
        component: lazy(() => import('pages/mainPages/Airline/Admin/PriorityRate/UploadRates/index')),
      },
      {
        path: '/airline/admin/contract-rate/list/view',
        component: lazy(() => import('pages/mainPages/Airline/Admin/PriorityRate/List')),
      },
      {
        path: '/airline/admin/contract-rate/edit/:airfare_id',
        component: lazy(() => import('pages/mainPages/Airline/Admin/PriorityRate/Update')),
      },
      // {
      // 	path: "/airline/admin/priority-rate/:airfare_id/range-details/view",
      // 	component: lazy(() => import("pages/mainPages/Airline/Admin/PriorityRate/ListPriorityRanges")),
      // },

      //Spot Rate
      {
        path: '/airline/admin/spot-rate/create',
        component: lazy(() => import('pages/mainPages/Airline/Admin/SpotRate/Create')),
      },
      {
        path: '/airline/admin/spot-rate/upload-rates',
        component: lazy(() => import('pages/mainPages/Airline/Admin/SpotRate/UploadRates/index')),
      },
      {
        path: '/airline/admin/spot-rate/list/view',
        component: lazy(() => import('pages/mainPages/Airline/Admin/SpotRate/List')),
      },
      {
        path: '/airline/admin/spot-rate/edit/:airfare_id',
        component: lazy(() => import('pages/mainPages/Airline/Admin/SpotRate/Update')),
      },
      // {
      // 	path: "/airline/admin/spot-rate/:airfare_id/range-details/view",
      // 	component: lazy(() => import("pages/mainPages/Airline/Admin/SpotRate/ListSpotRanges")),
      // }, 

      // Agent Route
      {
        path: '/airline/admin/agents/add',
        component: lazy(() => import('pages/mainPages/Airline/Admin/Agents/AddAgent')),
      },
      {
        path: '/airline/admin/agents/list/view',
        component: lazy(() => import('pages/mainPages/Airline/Admin/Agents/AgentList')),
      },
      {
        path: '/airline/admin/agents/edit/:id',
        component: lazy(() => import('pages/mainPages/Airline/Admin/Agents/EditAgent')),
      },
      // {
      // 	path: "/airline/admin/statistics",
      // 	component: lazy(() => import("pages/mainPages/Airline/Admin/Statistics/Statistics")),
      // },

      // ASSIGN KEY ROUTES
      {
        path: '/airline/admin/assign-key',
        component: lazy(() => import('pages/mainPages/Airline/Admin/KeyManagement/AssignKeyList')),
      },

      {
        path: '/airline/admin/keymanagement/add/reassign/:reassignId',
        component: lazy(() =>
          // import('pages/mainPages/Airline/Admin/Quotation/QuotationDetails'),
          import('pages/mainPages/Airline/Admin/KeyManagement/AssignReassign'),
        ),
      },

      // UNASSIGN KEY ROUTES
      {
        path: '/airline/admin/unassign-key',
        component: lazy(() => import('pages/mainPages/Airline/Admin/KeyManagement/UnassignKeyList')),
      },

      // Notification
      {
        path: '/airline/admin/notification',
        component: React.lazy(() =>
          import('pages/mainPages/Airline/Admin/Notification/Notification'),
        ),
      },

      //FeedBacklist
       {
              path: '/airline-admin/feedback/view',
              component: React.lazy(() =>
                import('pages/mainPages/Airline/Admin/Feedback/FeedbackList'),
              ),
            },
            {
              path: '/airline-admin/feedback/addfeedback',
              component: React.lazy(() =>
                import('pages/mainPages/Airline/Admin/Feedback/Addfeedback'),
              ),
            },
            {
              path: '/airline-admin/feedback/feedbacklist/view/:id',
              component: React.lazy(() =>
                import('pages/mainPages/Airline/Admin/Feedback/FeedbackListView'),
              ),
            },

      //Tutorial View
      {
        path: '/airline/admin/tutorial/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Tutorial/ViewTutorial'),
        ),
      },
    ],
  },
];
