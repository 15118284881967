import React, { useContext, useEffect, useMemo } from 'react';
import { Icon, ListItem, ListItemText, Tooltip, } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Badge, NavLink } from '../../../index';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../utility/IntlMessages';
import useStyles from './VerticalItem.style';
import AppContext from '../../../utility/AppContext';
import { checkPermission } from '../../../utility/Utils';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDrawer } from 'redux/actions';

const VerticalItem = ({ level, item, index, profile,
  notificationMenuData,
  indexNoFlag,
}) => {

  const { themeMode } = useContext(AppContext);
  const classes = useStyles({ level, themeMode });
  const { user } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch()
  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);
  const openDrawer = useSelector(({ settings }) => settings.openDrawer);

  const hasPermission = useMemo(() => checkPermission(item.auth, user.role), [
    item.auth,
    user.role,
  ]);
  if (!hasPermission) {
    return null;
  }
  const path = window.location.pathname;

  // console.log("notificationMenuData");
  // console.log(index);
  // console.log(index - 53);

  return (
    <ListItem
      button
      component={NavLink}
      to={item?.url[0]}
      activeClassName="active"
      // className={clsx(navCollapsed ? classes.navItem : classes.navItemChildren, 'nav-item',)}
      className={clsx(classes.navItem, 'nav-item',)}
      exact={item.exact}
      onClick={() => navCollapsed ? '' : dispatch(toggleDrawer(true))}
    >
      {item.icon && (
        <Box component="span" mr={6}>
          <Tooltip title={item?.title} placement="right-start">
            {/* <Icon className={clsx(classes.listIcon, 'nav-item-icon')} color="action" >
            {item.icon}
          </Icon> */}
            <img className={clsx(classes.listIcon, 'nav-item-icon')} color="action" src={Array.isArray(item.url) && item?.url.some(a => path.includes(a)) ? item.icon[0] : item.icon[1]}></img>
            {/* <img className={clsx(classes.listIcon, 'nav-item-icon')} color="action" src={item.icon}></img> */}
          </Tooltip>
        </Box>
      )}
      <ListItemText
        primary={<IntlMessages id={item.messageId} />}
        classes={Array.isArray(item.url) && item?.url.some(a => path.includes(a)) ? { primary: 'nav-item-text1' } : { primary: 'nav-item-text2' }}
      />

      {/*----- Notification Count - All Roles -----*/}
      {notificationMenuData?.length != 0 && notificationMenuData?.[index - indexNoFlag]?.noNotification != '0' && navCollapsed === true &&
        <Box component="span" className={clsx('notification-bg')}>
          <Box className={clsx('notification-count')}>{notificationMenuData?.[index - indexNoFlag]?.noNotification}</Box>
        </Box>
      }

      {notificationMenuData?.length != 0 && notificationMenuData?.[index - indexNoFlag]?.noNotification != '0' && navCollapsed === false && openDrawer === true &&
        <Box component="span" className={clsx('notification-bg')}>
          <Box className={clsx('notification-count')}>{notificationMenuData?.[index - indexNoFlag]?.noNotification}</Box>
        </Box>
      }

      {/*----- Notification Count - FF Admin -----*/}
      {/* {profile?.role === 'FF Admin' && notificationMenuData?.length != 0 && notificationMenuData?.[index - indexNoFlag]?.noNotification != '0' &&
        <Box component="span" className={clsx('notification-bg')}>
          <Box className={clsx('notification-count')}>{notificationMenuData?.[index - indexNoFlag]?.noNotification}</Box>
        </Box>
      } */}

      {/*----- Notification Count - FF Agent -----*/}
      {/* {profile?.role === 'FF Agent' && notificationMenuData?.length != 0 && notificationMenuData?.[index - indexNoFlag]?.noNotification != '0' &&
        <Box component="span" className={clsx('notification-bg')}>
          <Box className={clsx('notification-count')}>{notificationMenuData?.[index - indexNoFlag]?.noNotification}</Box>
        </Box>
      } */}

      {/*----- Notification Count - Client (Freight Forwarder) -----*/}
      {/* {profile?.role === 'Client (Freight Forwarder)' && notificationMenuData?.length != 0 && notificationMenuData?.[index - indexNoFlag]?.noNotification != '0' &&
        <Box component="span" className={clsx('notification-bg')}>
          <Box className={clsx('notification-count')}>{notificationMenuData?.[index - indexNoFlag]?.noNotification}</Box>
        </Box>
      } */}

      {/*----- Notification Count - Client (Consignee/Shipper) -----*/}
      {/* {profile?.role === 'Client (Consignee/Shipper)' && notificationMenuData?.length != 0 && notificationMenuData?.[index - indexNoFlag]?.noNotification != '0' &&
        <Box component="span" className={clsx('notification-bg')}>
          <Box className={clsx('notification-count')}>{notificationMenuData?.[index - indexNoFlag]?.noNotification}</Box>
        </Box>
      } */}


      {item.count && (
        <Box mr={1} clone>
          <Badge count={item.count} color={item.color} />
        </Box>
      )}
    </ListItem>
  );
};

VerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    // icon: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.string),
    // url: PropTypes.string,
    url: PropTypes.arrayOf(PropTypes.string),
  }),
};

VerticalItem.defaultProps = {};

export default withRouter(React.memo(VerticalItem));
