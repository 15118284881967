import React, { lazy } from 'react';

export const ffAdminRouteConfig = [
  {
    auth: ['ROLE_FFADMIN'],
    routes: [
      // AGENT  ROUTES
      {
        path: '/freight-forwarder/admin/profile/update',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Profile/UserProfile/UserProfile.js'),
        ),
      },
      // Disclaimer Setting
      {
        path: '/freight-forwarder/admin/profile/disclaimer/setting',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Profile/DisclaimerSetting/DisclaimerSetting.js'),
        ),
      },
      // PDF Design
      {
        path: '/freight-forwarder/admin/profile/pdf/design',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Profile/Design/PdfDesign'),
        ),
      },
      // Quote Email Message Design
      {
        path: '/freight-forwarder/admin/profile/quote/email/msg/setting',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Profile/QuoteEmailMessage/QuoteEmailMessage'),
        ),
      },
      // Measurements System
      {
        path: '/freight-forwarder/admin/profile/measurements/system',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Profile/MeasurementsSystem/MeasurementsSystem'),
        ),
      },
      // Freight Forwarder Airport
      {
        path: '/freight-forwarder/admin/profile/freight/forwarder/airport',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Profile/FreightForwarderAirport/Create/index'),
        ),
      },
      // Preferred Currency
      // {
      //   path: '/freight-forwarder/admin/profile/preferred/currency',
      //   component: lazy(() =>
      //     import('pages/mainPages/FreightForwarder/Admin/Profile/PreferredCurrency/PreferredCurrency'),
      //   ),
      // },

      //manaul setting
      {
        path:'/freight-forwarder/admin/profile/manual/setting',
        component: lazy(() =>
        import('pages/mainPages/FreightForwarder/Admin/Profile/ManualSetting/ManualSetting'),
      ),
      },

      {
        path: '/freight-forwarder/admin/agents/list/view',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/Agents/AgentsList')),
      },
      {
        path: '/freight-forwarder/transaction-list/freightforwarder/view',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/AccountManagement/Transactiondetail/TransactionList')),
      },
      {
        path: '/freight-forwarder/transaction/details/view',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/AccountManagement/Transactiondetail/TransactionDetail')),
      },
      {
        path: '/freight-forwarder/admin/agents/add',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/Agents/AddAgent')),
      },

      {
        path: '/freight-forwarder/admin/agent/key/generate',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Agents/GenerateAgentKey'),
        ),
      },
      {
        path: `/freight-forwarder/admin/agents/:agent_id`,
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/Agents/AgentDetails')),
      },
      {
        path: '/freight-forwarder/admin/agents/unassign-key/shipper-reassign',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/shipperReassign'),
        ),
      },

      // subscriptions routings
      {
        path: '/freight-forwarder/admin/subscriptions',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/AccountManagement/SubscriptionDetails/SubscriptionDetails'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/approveAddonsRequest',
        component: lazy(() =>
          import(
            // 'pages/mainPages/FreightForwarder/Admin/AccountManagement/ApproveAddonsRequest/ApproveAddonsRequest'
            'pages/mainPages/FreightForwarder/Admin/AccountManagement/index'
          ),
        ),
      },

      {
        path: '/freight-forwarder/admin/addonsrequest/details',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/AccountManagement/AddonsRequest/AddonsDetails')
        ),
      },

      // Shipper Routes
      {
        path: '/freight-forwarder/admin/shippers/add',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/Shipper/addShipper')),
      },
      {
        path: '/freight-forwarder/admin/shippers/requests/list/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Shipper/RequestFromShippers'),
        ),
      },
      {
        path: '/freight-forwarder/admin/Client/List',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/ClientListDetails'),
        ),
      },

      {
        path: '/freight-forwarder/admin/shippers/message/list/view',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/Shipper/Messages/index')),
      },


      // ECONOMICS ROUTES
      {
        path: '/freight-forwarder/admin/economics/create',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Economics/EconomicsList/AddNewEconomics'),
        ),
        // import('pages/mainPages/FreightForwarder/Admin/Economics/CreateEconomics/CreateEconomics')),
      },
      {
        path: '/freight-forwarder/admin/economics/list/view',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Admin/Economics/EconomicsList/EconomicsList'),
          import(
            'pages/mainPages/FreightForwarder/Admin/Economics/CreateEconomics/CreateEconomics'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/preset/margin/setup',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Economics/PresetMarginSetup/index'),
        ),
      },
      // {
      //   path: "/freight-forwarder/admin/economics/ranges/:id",
      //   component: lazy(() =>
      //     import(
      //       "pages/mainPages/FreightForwarder/Admin/Economics/EconomicsRanges"
      //     )
      //   ),
      // },
      {
        path: '/freight-forwarder/admin/economics',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Economics/CreateEconomics/CreateEconomics'
          ),
        ),
      },

      // ECONOMICS RANGES ROUTES
      {
        path: '/freight-forwarder/admin/trucking-ranges/create',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/EconomicsRanges/TruckingRange/CreateRange'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/trucking-ranges/list',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/EconomicsRanges/TruckingRange/UpdateRange'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/airline-ranges/create',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/EconomicsRanges/AirlineRange/CreateRange'),
        ),
      },
      {
        path: '/freight-forwarder/admin/airline-ranges/list',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/EconomicsRanges/AirlineRange/UpdateRange'),
        ),
      },
      {
        path: '/freight-forwarder/admin/insurance-ranges/create',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/EconomicsRanges/InsuranceRange/CreateRange'
          ),
        ),
      },
      //contract rates for ffadmin
      {
        path: '/freightForwarder/admin/contract-rate/create',
        component: lazy(() => import('pages/mainPages/Airline/Admin/PriorityRate/Create')),
      },
      {
        path: '/freightForwarder/admin/contract-rate/manual-rate',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/ContractRates/Create')),
      },
      {
        path: '/freightForwarder/admin/contract-rate/manual-rate-list/view',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/ContractRates/List/index')),
      },
      {
        path: '/freightForwarder/admin/contract-rate/update-manual-rate/:id',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/ContractRates/Update/index')),
      },
      {
        path: '/freightForwarder/admin/contract-rate/upload-rates',
        component: lazy(() => import('pages/mainPages/Airline/Admin/PriorityRate/UploadRates/index')),
      },
      {
        path: '/freightForwarder/admin/contract-rate/list/view',
        component: lazy(() => import('pages/mainPages/Airline/Admin/PriorityRate/List')),
      },
      {
        path: '/freightForwarder/admin/contract-rate/update-surcharge',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/ContractRates/Updatesurcharge')),
      },
      {
        path: '/freightForwarder/admin/contract-rate/edit/:airfare_id/:airlineId',
        component: lazy(() => import('pages/mainPages/Airline/Admin/PriorityRate/Update')),
      },
      {
        path: '/freight-forwarder/admin/insurance-ranges/list',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/EconomicsRanges/InsuranceRange/UpdateRange'
          ),
        ),
      },

      // WAREHOUSE ROUTES
      {
        path: '/freight-forwarder/admin/warehouse/create',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/WareHouse/AddWareHouse'),
        ),
      },
      {
        path: '/freight-forwarder/admin/warehouse/list/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/WareHouse/WareHouseList'),
        ),
      },
      {
        path: '/freight-forwarder/admin/warehouse/edit/:warehouseId',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/WareHouse/EditWareHouse'),
        ),
      },

      // FLIGHT SCHEDULE ROUTES
      {
        path: '/freight-forwarder/admin/flight/schedule',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/FlightInfo/FlightSchedule'),
        ),
      },

      // QUOTATION ROUTES
      {
        path: '/freight-forwarder/admin/quotation/create',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationCreate'),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/list/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationList'),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/analysis/list/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuoteList/Quotations'),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/analysis/all-company/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuoteList/QuoteAnalystQuote/AllCompanyData'),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/inquerier/list/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuoteInquerier'),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/manual/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/ManualQuote/ManualQuote'),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/manual/details',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/ManualQuote/ManualQuoteDetails/index'),
        ),
      },
      // {
      //   path: "/freight-forwarder/admin/quotation/output",
      //   component: lazy(() =>
      //     import("pages/mainPages/FreightForwarder/Admin/Quotation/QuoteOutput")
      //   ),
      // },
      {
        path: '/freight-forwarder/admin/quotation/details/:id',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationDetails'),
          import('pages/mainPages/FreightForwarder/Admin/Quotation/PreviousQuote/QuickQuoteOutPut'),
        ),
      },
      // {
      //   path: '/freight-forwarder/admin/quotation/update',
      //   component: lazy(() =>
      //     import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationUpdate'),
      //   ),
      // },
      {
        path: '/freight-forwarder/admin/quotation/update/:departairport/:destairport',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationUpdate'),
        ),
      },

      //Leave Create Quote Modal
      {
        path: '/freight-forwarder/admin/quotation/CreateQuoteYesNoModal',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/CreateQuoteLeaveModal'),
        ),
      },

      {
        path: '/freight-forwarder/admin/add/reassign/:reassignId',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationDetails'),
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/AssignReassign'),
        ),
      },

      {
        path: '/freight-forwarder/admin/add/reassignfromkeymanagement/:reassignId',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationDetails'),
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/AssignReassign'),
        ),
      },

      {
        path: '/freight-forwarder/admin/add/reassignfromClientList/:reassignId',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationDetails'),
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/AssignReassign'),
        ),
      },

      // Key Management Reassign
      {
        path: '/freight-forwarder/admin/key-management/add/reassign/:reassignId',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationDetails'),
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/AssignReassign'),
        ),
      },

      {
        path: '/freight-forwarder/admin/quotation/previousquote',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/PreviousQuote/QuickQuoteOutPut'),
        ),
      },

      {
        path: '/freight-forwarder/admin/quotation/goods/details/:id',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/GoodsQuotationDetails'),
        ),
      },

      {
        path: '/freight-forwarder/admin/quotation/price/quickQuote',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/QuickQuote/QuickQuoteOutPut/index'
          ),
        ),
      },
 
      {
        path: '/freight-forwarder/admin/quotation/price/quickQuotePlus',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/QuickQuote/QuickQuoteOutPut/index'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/Airfreight-quote/price',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/Airfreight/AirfreightOutPut/index'
          ),
        ),
      },

      //FreightForwardeQuotationList
      {
        path: '/freight-forwarder/admin/quotations/list/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuoteList/QuotationsList'),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotationanlyst/quotaionlist/view',
        component: React.lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/QuoteList/Quoteanalystquotelist'
          ),
        ),
      },

      //FreightForwardeQuotationDetails
      {
        path: '/freight-forwarder/admin/incoming-quotes/quotation/details',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/QuoteList/PreviousQuote/QuickQuoteOutPut'
          ),
        ),
      },

      {
        path: '/freight-forwarder/admin/quote-analyst/quotation/details',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuoteList/QuoteAnalystQuote'),
        ),
      },

      // Quotation Shippers
      {
        path: '/freight-forwarder/admin/quotation/shippers/requests/list/view',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/ShipperRequestList'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/shippers/request/details/:quoteId',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/ShipperRequestDetails'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/shippers/incoming/quote/details/view/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/ShipperRequestDetails'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/shippers/request/goods/details/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/GoodShipperRequestDetails'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/shippers/request/generatedquote/details/',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/GeneratedQuoteRequestDetails'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/shippers/request/quickquote/price/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/QuickQuote/QuickQuoteOutPut/index'
          ),
        ),
      },

      {
        path: '/freight-forwarder/admin/shipperRequest/quotation/price',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/RecalculateQuickQuote/index'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/shipperRequest/Airfreight/price',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/RecalculateAirfreightOutput/index'
          ),
        ),
      },

      // Incoming Quote List - Incoming
      {
        path: '/freight-forwarder/admin/quotation/shippers/incoming/quote/list/view',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/IncomingQuotesIncoming/QuotesIncoming'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/shippers/incoming/quote/details/manualquotation/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/ShipperRequestManualquoteDetails/'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/shippers/incoming-quotes-analysis/quote/details/manualquotation/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/ShipperRequestManualquoteDetails/'
          ),
        ),
      },

      // Generated Quote List
      {
        path: '/freight-forwarder/admin/quotation/shippers/generated/quote/list/view',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/IncomingQuoteList'
          ),
        ),
      },
      {
        path: '/freight-forwarder/admin/quotation/shippers/generated/quote/view/details/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/GeneratedQuoteRequestDetails'

            // src\pages\mainPages\FreightForwarder\Admin\Quotation\ShipperRequest\GeneratedQuoteRequestDetails.js
            // ''
            // src\pages\mainPages\FreightForwarder\Admin\Quotation\ShipperRequest\GeneratedQuoteRequestDetails.js
          ),
        ),
      },

      //Directory ROUTES
      {
        path: '/freight-forwarder/admin/directory/address/new',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Address/AddressAdd'),
        ),
      },
      {
        path: '/freight-forwarder/admin/directory/address/list/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Address/AddressList'),
        ),
      },
      {
        path: '/freight-forwarder/admin/directory/address/edit/:id',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Address/AddressEdit'),
        ),
      },
      // STATISTICS ROUTES
      {
        path: '/freight-forwarder/admin/statistics',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Statistics/Statistics'),
        ),
      },

      // ASSIGN KEY ROUTES
      {
        path: '/freight-forwarder/admin/assign-key',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/AssignKeyList'),
        ),
      },

      // UNASSIGN KEY ROUTES
      {
        path: '/freight-forwarder/admin/unassign-key',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/UnassignKeyList'),
        ),
      },

      //Freight Forwarder
      {
        path: '/freight-forwarder/key-management/freightforwarder/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/FreightForwarder'),
        ),
      },

      //Freight Forwarder Details
      {
        path: '/freight-forwarder/key-management/freightforwarder/details',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/FreightForwarderDetails'),
        ),
      },

      //Action Key Details
      {
        path: '/freight-forwarder/key-management/freightforwarder/actionkey/details',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/ActionKeyDetails'),
        ),
      },

      //Assign Admin 
      {
        path: '/freight-forwarder/key-management/assign/admin',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/AssignAdmin/AssignAdmin'),
        ),
      },

      //Client List Details
      {
        path: '/freight-forwarder/client/clientlist/freightforwarder/actionkey/details',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/ActionKeyDetails'),
        ),
      },

      //Action Key Management Payment Method
      {
        path: '/freight-forwarder/admin/key-management/checkout_payment',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentMethod'),
        ),
      },
      {
        path: '/freight-forwarder/admin/client-list/checkout_payment',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentMethod'),
        ),
      },
      {
        path: '/freight-forwarder/admin/client/clientlist/key-management/checkout_payment',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentMethod'),
        ),
      },
      {
        path: '/freight-forwarder/admin/agent-list/checkout_payment',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentMethod'),
        ),
      },

      //Action Key Management Payment Success Thank You Page
      {
        path: '/freight-forwarder/admin/key-management/payment-success',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentSuccess'),
        ),
      },
      {
        path: '/freight-forwarder/admin/client/clientlist/payment/paymentsuccess',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentSuccess'),
        ),
      },
      {
        path: '/freight-forwarder/admin/agent/agent-list/payment/paymentsuccess',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentSuccess'),
        ),
      },

      //Action Key Details Client List 
      {
        path: '/freight-forwarder/client/Clientlists/freightforwarder/actionkey/details',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/ActionKeyDetailsClientList'),
        ),
      },

      //Action Key Details Agent List 
      {
        path: '/freight-forwarder/admin/agent/agentslists/freightforwarder/actionkey/details',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Agents/ActionKeyDetailsAgentList'),
        ),
      },

      //Feedback List
      {
        path: '/freight-forwarder/admin/feedback/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Feedback/FeedbackList'),
        ),
      },
      {
        path: '/freight-forwarder/admin/addfeedback',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Feedback/Addfeedback'),
        ),
      },

      //Tutorial View
      {
        path: '/freight-forwarder/admin/tutorial/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Tutorial/ViewTutorial'),
        ),
      },

      //Feedback List View
      {
        path: '/freight-forwarder/admin/feedback/feedbacklist/view/:id',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Feedback/FeedbackListView'),
        ),
      },

      // TRACKING ROUTES
      {
        path: '/freight-forwarder/admin/tracking',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/Tracking')),
      },

      {
        path: '/freight-forwarder/admin/subscription/payment',
        component: lazy(() => import('pages/paymentPage/BuySubscription/index')),
      },
      {
        path: '/freight-forwarder/admin/checkout_payment',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/AccountManagement/SubscriptionDetails/PaymentMethod')),
      },

      {
        path: '/freight-forwarders/admin/subscription/payment/buyplan/:buyplanid',
        component: lazy(() => import('pages/paymentPage/BuySubscription/BuyPaymentMethod/index')),
      },

      //Payment Success
      {
        path: '/freight-forwarders/admin/subscription/payment/success',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/AccountManagement/SubscriptionDetails/PaymentSuccess')),
      },

      //Buy Plan Payment Success 
      {
        path: '/freight-forwarders/admin/subscription/buyplan/payment/success',
        component: lazy(() => import('pages/paymentPage/BuySubscription/BuyPaymentMethod/PaymentSuccess')),
      },

      // Notification
      {
        path: '/freight-forwarder/admin/notification',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Notification/Notification'),
        ),
      },

      {
        path: '/freight-forwarder/admin/key-management/unassign-key/reassign-key',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/UnassignReassignKey'),
        ),
      },

      {
        path: '/freight-forwarder/admin/agent/list/assign-key/reassign-key',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/UnassignReassignKey'),
        ),
      },

      {
        path: '/freight-forwarder/admin/key-management/unassign-key/add-shipper',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/addShipper'),
        ),
      },

      {
        path: '/freight-forwarder/admin/client/clientlist/assign-key/add-shipper',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/addShipper'),
        ),
      },

      {
        path: '/freight-forwarder/admin/key-management/unassign-key/shipper-reassign',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/shipperReassign'),
        ),
      },

      //----- Abouts -----//
      {
        path: '/freight-forwarder/admin/about/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/About/About'),
        ),
      },
      {
        path: '/freight-forwarder/admin/about/create/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/About/AddAbout'),
        ),
      },
    ],
  },
];
