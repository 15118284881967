const initialState = {
  requesting: true,
  approvedCompanies: {},
  unapprovedCompanies: {},
  approvedCompaniesNameDetails: {},

  activeStatus: {},
  ffadminStatistic: {},
  revenueCost: {},
  topAirportList: [],
  ffadminGraph: {},
  quoteCount: [],
  amountCount: [],
  quoteMargin: [],
  countChart: [],
  totalRevenueGraphData: [],
  totalProfitGraphData: [],
  totalQuotesGraphData: []
};

export const freightForwarderCompaniesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@@superAdmin/freightForwarder/approvedCompanies':
      return {
        ...state,
        requesting: false,
        approvedCompanies: {
          ...payload,
        },
      };
    case 'GET_TOTAL_REVENUE':
      return {
        ...state,
        totalRevenueGraphData:payload
      }
    case 'GET_TOTAL_PROFIT':
      return {
        ...state,
        totalProfitGraphData:payload
      }
    case 'GET_TOTAL_QUOTES':
      return {
        ...state,
        totalQuotesGraphData:payload
      }

    case '@@superAdmin/freightForwarder/approvedCompanies/companyNameDetails':
      return {
        ...state,
        requesting: false,
        approvedCompaniesNameDetails: {
          ...payload,
        },
      };

    case '@@superAdmin/freightForwarder/unapprovedCompanies':
      return {
        ...state,
        requesting: false,
        unapprovedCompanies: {
          ...payload,
        },
      };

    case '@@superAdmin/freightForwarder/approveCompany':
      return {
        ...state,
        requesting: false,
        approvalStatus: payload,
      };

    case '@@activateFreightForwarder/super-admin/freightForwarder/companies':
      return {
        ...state,
        requesting: false,
        activeStatus: {
          ...payload,
        },
      };

    case '@@deactivateFreightForwarder/super-admin/freightForwarder/companies':
      return {
        ...state,
        requesting: false,
        activeStatus: {
          ...payload,
        },
      };
    case 'GET_FFADMIN_STATISTIC_ROLE_WISE_DATA':
      return {
        ...state,
        ffadminStatistic: payload
      }
    case 'GET_TOP_AIRPORT_LIST':
      return {
        ...state,
        topAirportList: payload
      }

    case 'GET_FFADMIN_REVENUE_COST':
      return {
        ...state,
        revenueCost: payload
      }
    case 'GET_BAR_CHART_FFADMIN_DATA':
      return {
        ...state,
        countChart: payload.chart
      }

    case "GET_QUOTE_COUNT":
      return {
        ...state,
        quoteCount: payload
      }

    case 'GET_QUOTE_MARGIN':
      return {
        ...state,
        quoteMargin: payload
      }

    case 'GET_QUOTE_AMOUNT':
      return {
        ...state,
        amountCount: payload
      }



    case 'GET_GRAPH_CHART_FFADMIN':
      return {
        ...state,
        ffadminGraph: payload
      }

    default:
      return state;
  }
};
