export const GET_FFADMIN_QUOTATIONS_REQ = 'GET_FFADMIN_QUOTATIONS_REQ';
export const GET_FFADMIN_QUOTATIONS_SUCCESS = 'GET_FFADMIN_QUOTATIONS_SUCCESS';
export const GET_FFADMIN_QUOTATIONS_FAIL = 'GET_FFADMIN_QUOTATIONS_FAIL';

export const GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_REQ = 'GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_REQ';
export const GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_SUCCESS = 'GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_SUCCESS';
export const GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_FAIL = 'GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_FAIL';

export const POST_FFADMIN_QUOTATIONS_PRICE_REQ = 'POST_FFADMIN_QUOTATIONS_PRICE_REQ';
export const POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS = 'POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS';
export const POST_FFADMIN_QUOTATIONS_PRICE_FAIL = 'POST_FFADMIN_QUOTATION_PRICE_FAIL';

export const GET_FFADMIN_QUOTATION_REQ = 'GET_FFADMIN_QUOTATION_REQ';
export const GET_FFADMIN_QUOTATION_SUCCESS = 'GET_FFADMIN_QUOTATION_SUCCESS';
export const GET_FFADMIN_QUOTATION_FAIL = 'GET_FFADMIN_QUOTATION_FAIL';

export const UPDATE_FFADMIN_QUOTATION_REQ = 'UPDATE_FFADMIN_QUOTATION_REQ';
export const UPDATE_FFADMIN_QUOTATION_SUCCESS = 'UPDATE_FFADMIN_QUOTATION_SUCCESS';
export const UPDATE_FFADMIN_QUOTATION_FAIL = 'UPDATE_FFADMIN_QUOTATION_FAIL';

export const DELETE_FFADMIN_QUOTATION_REQ = 'DELETE_FFADMIN_QUOTATION_REQ';
export const DELETE_FFADMIN_QUOTATION_SUCCESS = 'DELETE_FFADMIN_QUOTATION_SUCCESS';
export const DELETE_FFADMIN_QUOTATION_FAIL = 'DELETE_FFADMIN_QUOTATION_FAIL';

export const CREATE_FFADMIN_QUOTATION_REQ = 'CREATE_FFADMIN_QUOTATION_REQ';
export const CREATE_FFADMIN_QUOTATION_SUCCESS = 'CREATE_FFADMIN_QUOTATION_SUCCESS';
export const CREATE_FFADMIN_QUOTATION_FAIL = 'CREATE_FFADMIN_QUOTATION_FAIL';

export const GET_FFADMIN_SEARCH_ADDRESS_REQ = 'GET_FFADMIN_SEARCH_ADDRESS_REQ';
export const GET_FFADMIN_SEARCH_ADDRESS_SUCCESS = 'GET_FFADMIN_SEARCH_ADDRESS_SUCCESS';
export const GET_FFADMIN_SEARCH_ADDRESS_FAIL = 'GET_FFADMIN_SEARCH_ADDRESS_FAIL';

export const GET_FFADMIN_SEARCH_ADDRESS2_REQ = 'GET_FFADMIN_SEARCH_ADDRESS2_REQ';
export const GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS = 'GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS';
export const GET_FFADMIN_SEARCH_ADDRESS2_FAIL = 'GET_FFADMIN_SEARCH_ADDRESS2_FAIL';

export const GET_REQUEST_QUOTE_REQ = 'GET_REQUEST_QUOTE_REQ';
export const GET_REQUEST_QUOTE_SUCCESS = 'GET_REQUEST_QUOTE_SUCCESS';
export const GET_REQUEST_QUOTE_FAIL = 'GET_REQUEST_QUOTE_FAIL';

export const GET_SHIP_FROM_AIRPORT_REQ = 'GET_SHIP_FROM_AIRPORT_REQ';
export const GET_SHIP_FROM_AIRPORT_SUCCESS = 'GET_SHIP_FROM_AIRPORT_SUCCESS';
export const GET_SHIP_FROM_AIRPORT_FAIL = 'GET_SHIP_FROM_AIRPORT_FAIL';

export const GET_SHIP_TO_AIRPORT_REQ = 'GET_SHIP_TO_AIRPORT_REQ';
export const GET_SHIP_TO_AIRPORT_SUCCESS = 'GET_SHIP_TO_AIRPORT_SUCCESS';
export const GET_SHIP_TO_AIRPORT_FAIL = 'GET_SHIP_TO_AIRPORT_FAIL';

export const GET_SHIP_TO_QUICK_AIRPORT_REQ = 'GET_SHIP_TO_QUICK_AIRPORT_REQ';
export const GET_SHIP_TO_QUICK_AIRPORT_SUCCESS = 'GET_SHIP_TO_QUICK_AIRPORT_SUCCESS';
export const GET_SHIP_TO_QUICK_AIRPORT_FAIL = 'GET_SHIP_TO_QUICK_AIRPORT_FAIL';

export const GET_SEARCH_BY_ZIP_REQ = 'GET_SEARCH_BY_ZIP_REQ';
export const GET_SEARCH_BY_ZIP_SUCCESS = 'GET_SEARCH_BY_ZIP_SUCCESS';
export const GET_SEARCH_BY_ZIP_FAIL = 'GET_SEARCH_BY_ZIP_FAIL';

export const GET_COUNTRY_REQ = 'GET_COUNTRY_REQ';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL';

export const CHECK_QUOTE_AVAILABLE_REQ = 'CHECK_QUOTE_AVAILABLE_REQ';
export const CHECK_QUOTE_AVAILABLE_SUCCESS = 'CHECK_QUOTE_AVAILABLE_SUCCESS';
export const CHECK_QUOTE_AVAILABLE_FAIL = 'CHECK_QUOTE_AVAILABLE_FAIL';

export const SEND_QUOTE_BY_EMAIL_REQ = 'SEND_QUOTE_BY_EMAIL_REQ';
export const SEND_QUOTE_BY_EMAIL_SUCCESS = 'SEND_QUOTE_BY_EMAIL_SUCCESS';
export const SEND_QUOTE_BY_EMAIL_FAIL = 'SEND_QUOTE_BY_EMAIL_FAIL';

export const SET_QUOTATION_DATA = 'SET_QUOTATION_DATA';

export const GET_AIRPORT_DEPATURE_LIST = 'GET_AIRPORT_DEPATURE_LIST'; // added by vishal
export const STORE_RADIO_OPTION = 'STORE_RADIO_OPTION'; //added by vishal
export const GET_EMPTY_COUNTRY_LIST = 'GET_EMPTY_COUNTRY_LIST'; //added by vishal

export const POST_FFADMIN_QUOTATION_PDF_REQ = 'POST_FFADMIN_QUOTATION_PDF_REQ';
export const POST_FFADMIN_QUOTATION_PDF_SUCCESS = 'POST_FFADMIN_QUOTATION_PDF_SUCCESS';
export const POST_FFADMIN_QUOTATION_PDF_FAIL = 'POST_FFADMIN_QUOTATION_PDF_FAIL';

export const GET_UPDATED_QUOTATION_LIST = 'GET_UPDATED_QUOTATION_LIST';

export const GET_SHIPPER_LIST_REQ = 'GET_SHIPPER_LIST_REQ';
export const GET_SHIPPER_LIST_SUCCESS = 'GET_SHIPPER_LIST_SUCCESS';
export const GET_SHIPPER_LIST_FAIL = 'GET_SHIPPER_LIST_FAIL';

export const SAVE_QUOTATION_DATA = 'SAVE_QUOTATION_DATA';
export const SAVE_CHECKBOX_DATA = 'SAVE_CHECKBOX_DATA';

export const EDIT_SAVE_QUOTATION = 'EDIT_SAVE_QUOTATION';
export const EDIT_QUOTATION_REQ = 'EDIT_QUOTATION_REQ';
export const EDIT_QUOTATION_SUCCESS = 'EDIT_QUOTATION_SUCCESS';
export const EDIT_QUOTATION_FAIL = 'EDIT_QUOTATION_FAIL';

export const SET_PREVIOUS_STATE = 'SET_PREVIOUS_STATE';
export const SET_UPDATE_PREVIOUS_STATE = 'SET_UPDATE_PREVIOUS_STATE';
export const SET_QUOTATION_UPDATE_UNIT = 'SET_QUOTATION_UPDATE_UNIT';

export const SET_SHIPPER_QUOTATION_REQ = 'SET_SHIPPER_QUOTATION_REQ';
export const SET_SHIPPER_QUOTATION_SUCCESS = 'SET_SHIPPER_QUOTATION_SUCCESS';
export const SET_SHIPPER_QUOTATION_FAIL = 'SET_SHIPPER_QUOTATION_FAIL';

export const SET_CREATE_SHIPPER_QUOTATION_REQ = 'SET_CREATE_SHIPPER_QUOTATION_REQ';
export const SET_CREATE_SHIPPER_QUOTATION_SUCCESS = 'SET_CREATE_SHIPPER_QUOTATION_SUCCESS';
export const SET_CREATE_SHIPPER_QUOTATION_FAIL = 'SET_CREATE_SHIPPER_QUOTATION_FAIL';

export const GET_TRUKING_DATA = 'GET_TRUKING_DATA';
export const GET_RECALCULATE_VALUE = 'GET_RECALCULATE_VALUE';

export const GET_DELIVERY_ZIPCODE_REQ = 'GET_DELIVERY_ZIPCODE_REQ';
export const GET_DELIVERY_ZIPCODE_SUCCESS = 'GET_DELIVERY_ZIPCODE_SUCESS';
export const GET_DELIVERY_ZIPCODE_FAIL = 'GET_DELIVERY_ZIPCODE_FAIL';

export const GET_UPDATE_DELIVERY_ZIPCODE_REQ = 'GET_UPDATE_DELIVERY_ZIPCODE_REQ';
export const GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS = 'GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS';
export const GET_UPDATE_DELIVERY_ZIPCODE_FAIL = 'GET_UPDATE_DELIVERY_ZIPCODE_FAIL';

export const GET_UPDATED_PICKUP_ZIPCODE_REQ = 'GET_UPDATED_PICKUP_ZIPCODE_REQ';
export const GET_UPDATED_PICKUP_ZIPCODE_SUCCESS = 'GET_UPDATED_PICKUP_ZIPCODE_SUCCESS';
export const GET_UPDATED_PICKUP_ZIPCODE_FAIL = 'GET_UPDATED_PICKUP_ZIPCODE_FAIL';

export const GET_UNASSIGNED_LIST_REQ = 'GET_UNASSIGNED_LIST_REQ';
export const GET_UNASSIGNED_LIST_SUCCESS = 'GET_UNASSIGNED_LIST_SUCCESS';
export const GET_UNASSIGNED_LIST_FAIL = 'GET_UNASSIGNED_LIST_FAIL';

export const GET_ASSIGNED_LIST_REQ = 'GET_ASSIGNED_LIST_REQ';
export const GET_ASSIGNED_LIST_SUCCESS = 'GET_ASSIGNED_LIST_SUCCESS';
export const GET_ASSIGNED_LIST_FAIL = 'GET_ASSIGNED_LIST_FAIL';

export const GET_PREVIOUS_QUOTE_DETAIL = 'GET_PREVIOUS_QUOTE_DETAIL';

export const SET_INCOMING_SHIPPER_QUOTATION_REQ = 'SET_INCOMING_SHIPPER_QUOTATION_REQ';
export const SET_INCOMING_SHIPPER_QUOTATION_SUCCESS = 'SET_INCOMING_SHIPPER_QUOTATION_SUCCESS';
export const SET_INCOMING_SHIPPER_QUOTATION_FAIL = 'SET_INCOMING_SHIPPER_QUOTATION_FAIL';


export const GET_USER_KEY_MANAGEMENT_SUCCESS = 'GET_USER_KEY_MANAGEMENT_SUCCESS';
export const GET_KEY_COUNT_SUCCESS = 'GET_KEY_COUNT_SUCCESS';


export const GET_KEY_USER_ROLE_SELECT_REQ = 'GET_KEY_USER_ROLE_SELECT_REQ'
export const GET_KEY_USER_ROLE_SELECT_SUCCESS = 'GET_KEY_USER_ROLE_SELECT_SUCCESS'
export const GET_KEY_USER_ROLE_SELECT_FAIL = 'GET_KEY_USER_ROLE_SELECT_FAIL'

export const GET_NO_0F_QUOTATION = 'GET_NO_0F_QUOTATION';

export const GET_KEY_MANAGEMENT_ASSIGNED_KEY_REQ = 'GET_KEY_MANAGEMENT_ASSIGNED_KEY_REQ';
export const GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS = 'GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS';
export const GET_KEY_MANAGEMENT_ASSIGNED_KEY_FAIL = 'GET_KEY_MANAGEMENT_ASSIGNED_KEY_FAIL';

export const GET_KEY_MANAGEMENT_UNASSIGNED_KEY_REQ = 'GET_KEY_MANAGEMENT_UNASSIGNED_KEY_REQ';
export const GET_KEY_MANAGEMENT_UNASSIGNED_KEY_SUCCESS = 'GET_KEY_MANAGEMENT_UNASSIGNED_KEY_SUCCESS';
export const GET_KEY_MANAGEMENT_UNASSIGNED_KEY_FAIL = 'GET_KEY_MANAGEMENT_UNASSIGNED_KEY_FAIL';

export const GET_INCOMING_COUNT = 'GET_INCOMING_COUNT';
export const GET_CLIENT_QUOTATION = 'GET_CLIENT_QUOTATION';

export const GET_INCOMING_LIST_REQ = 'GET_INCOMING_LIST_REQ';
export const GET_INCOMING_LIST_SUCCESS = 'GET_INCOMING_LIST_SUCCESS';
export const GET_INCOMING_LIST_FAIL = 'GET_INCOMING_LIST_FAIL';

export const GET_INCOMING_QUOTATIONS_DETAIL_REQ = 'GET_INCOMING_QUOTATIONS_DETAIL_REQ';
export const GET_INCOMING_QUOTATIONS_DETAIL_SUCCESS = 'GET_INCOMING_QUOTATIONS_DETAIL_SUCCESS';
export const GET_INCOMING_QUOTATIONS_DETAIL_FAIL = 'GET_INCOMING_QUOTATIONS_DETAIL_FAIL';

export const GET_QUOTEANALYS_QUOTAION_LIST_REQ = 'GET_QUOTEANALYS_QUOTAION_LIST_REQ';
export const GET_QUOTEANALYS_QUOTAION_LIST_SUCCESS = 'GET_QUOTEANALYS_QUOTAION_LIST_SUCCESS';
export const GET_QUOTEANALYS_QUOTAION_LIST_FAIL = 'GET_QUOTEANALYS_QUOTAION_LIST_FAIL';

export const GET_QUOTEANALYST_QUOTATION_DETAILS_REQ = 'GET_QUOTEANALYST_QUOTATION_DETAILS_REQ';
export const GET_QUOTEANALYST_QUOTATION_DETAILS_SUCCESS = 'GET_QUOTEANALYST_QUOTATION_DETAILS_SUCCESS';
export const GET_QUOTEANALYST_QUOTATION_DETAILS_FAIL = 'GET_QUOTEANALYST_QUOTATION_DETAILS_FAIL';

export const GET_ANALYST_USER_FILTER_VALUE = 'GET_ANALYST_USER_FILTER_VALUE';
export const GET_ADMIN_USER_PRFOILE = 'GET_ADMIN_USER_PRFOILE';

export const DOWNLOAD_STATUS_OF_IMAGE_REQ = 'DOWNLOAD_STATUS_OF_IMAGE_REQ';
export const DOWNLOAD_STATUS_OF_IMAGE_SUCCES = 'DOWNLOAD_STATUS_OF_IMAGE_SUCCES';
export const DOWNLOAD_STATUS_OF_IMAGE_FAIL = 'DOWNLOAD_STATUS_OF_IMAGE_FAIL'

export const GET_FEED_BACK_LIST_REQ = 'GET_FEED_BACK_LIST_REQ';
export const GET_FEEDBACK_LIST_SUCCESS = 'GET_FEEDBACK_LIST_SUCCESS';
export const GET_FEEDBACL_LIST_FAIL = 'GET_FEEDBACL_LIST_FAIL';

export const GET_DISCLAIMER_DATA = 'GET_DISCLAIMER_DATA';

export const GET_PRINT_LOADER_STATUS = 'GET_PRINT_LOADER_STATUS';
export const GET_EXPORT_LOADER_STATUS = 'GET_EXPORT_LOADER_STATUS';
export const GET_EXPORT_LOADER_STATUS_SUCCESS = 'GET_EXPORT_LOADER_STATUS_SUCCESS';
export const GET_EXPORT_LOADER_STATUS_FAILURE = 'GET_EXPORT_LOADER_STATUS_FAILURE';
export const RESET_STATE = 'RESET_STATE';

export const GET_FFADMIN_REQ_QUOTEFORMODEL_LIST = 'GET_FFADMIN_REQ_QUOTEFORMODEL_LIST';
export const GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST = 'GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST';
export const GET_FFADMIN_FAIL_QUOTEFORMODEL_LIST = 'GET_FFADMIN_FAIL_QUOTEFORMODEL_LIST';
export const GET_SAVE_AS_NEW_QUOTATION = 'GET_SAVE_AS_NEW_QUOTATION';
export const UPDATE_QUOTATION_RESULT_DATA = 'UPDATE_QUOTATION_RESULT_DATA';
export const GET_DEPARTURE_ZIPCODE = 'GET_DEPARTURE_ZIPCODE';
export const UPDATE_VALUE_FOR_STATUS = 'UPDATE_VALUE_FOR_STATUS';

export const GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ = 'GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ';
export const GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS = 'GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS';
export const GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL = 'GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL';

export const GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD = 'GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD';

export const GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_REQ = 'GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_REQ';
export const GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_SUCCESS = 'GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_SUCCESS';
export const GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_FAIL = 'GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_FAIL';

export const GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME = 'GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME';
export const GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME_SUCCESS = 'GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME_SUCCESS';
export const GET_ABORT_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME = 'GET_ABORT_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME';

export const SET_VERSION_NUMBER = 'SET_VERSION_NUMBER'
export const GET_VERSION_ID_DATA = 'GET_VERSION_ID_DATA';
export const GET_AIRLINE_LIST = 'GET_AIRLINE_LIST';
export const GET_AIRLINE_LIST1 = 'GET_AIRLINE_LIST1';

export const GET_MANUAL_QUOTATION_REQ = 'GET_MANUAL_QUOTATION_REQ';
export const GET_MANUAL_QUOTATION_SUCCESS = 'GET_MANUAL_QUOTATION_SUCCESS';
export const GET_MANUAL_QUOTATION_FAIL = 'GET_MANUAL_QUOTATION_FAIL';

export const SAVE_MANUAL_QUOTATION_REQ = 'SAVE_MANUAL_QUOTATION_REQ';
export const PRINT_MANUAL_QUOTATION_REQ = 'PRINT_MANUAL_QUOTATION_REQ';
export const SHARE_MANUAL_QUOTATION_REQ = 'SHARE_MANUAL_QUOTATION_REQ';

export const SAVE_MANUAL_QUOTATION_SUCCESS = 'SAVE_MANUAL_QUOTATION_SUCCESS';
export const PRINT_MANUAL_QUOTATION_SUCCESS = 'PRINT_MANUAL_QUOTATION_SUCCESS';
export const SHARE_MANUAL_QUOTATION_SUCCESS = 'SHARE_MANUAL_QUOTATION_SUCCESS';

export const SAVE_MANUAL_QUOTATION_FAIL = 'SAVE_MANUAL_QUOTATION_FAIL';
export const PRINT_MANUAL_QUOTATION_FAIL = 'PRINT_MANUAL_QUOTATION_FAIL';
export const SHARE_MANUAL_QUOTATION_FAIL = 'SHARE_MANUAL_QUOTATION_FAIL';





export const PRINT_MANUAL_QUOTATION_DETAIL_REQ = 'PRINT_MANUAL_QUOTATION_DETAIL_REQ'
export const SAVE_MANUAL_QUOTATION_DETAIL_REQ = 'SAVE_MANUAL_QUOTATION_DETAIL_REQ'
export const SHARE_MANUAL_QUOTATION_DETAIL_REQ = 'SHARE_MANUAL_QUOTATION_DETAIL_REQ'

export const PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS = 'PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS'
export const SAVE_MANUAL_QUOTATION_DETAIL_SUCCESS = 'SAVE_MANUAL_QUOTATION_DETAIL_SUCCESS'
export const SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS = 'SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS'

export const PRINT_MANUAL_QUOTATION_DETAIL_FAIL = 'PRINT_MANUAL_QUOTATION_DETAIL_FAIL'
export const SAVE_MANUAL_QUOTATION_DETAIL_FAIL = 'SAVE_MANUAL_QUOTATION_DETAIL_FAIL'
export const SHARE_MANUAL_QUOTATION_DETAIL_FAIL = 'SHARE_MANUAL_QUOTATION_DETAIL_FAIL'


export const SAVE_DATA_FROM_NEW_QUOTE = 'SAVE_DATA_FROM_NEW_QUOTE';
export const REMOVE_SAVE_DATA_FROM_NEW_QUOTE = 'REMOVE_SAVE_DATA_FROM_NEW_QUOTE';

export const GET_ABOUT_VERSION_CONTROL_ALL_DATA = 'GET_ABOUT_VERSION_CONTROL_ALL_DATA';

export const GET_ABOUT_VERSION_CONTROL_DATA_BY_ID = 'GET_ABOUT_VERSION_CONTROL_DATA_BY_ID';

export const GET_LOADER_STATUS = 'GET_LOADER_STATUS';

export const CREATE_TRUKCING_QUOTATION_REQ = 'CREATE_TRUKCING_QUOTATION_REQ';
export const CREATE_TRUKCING_QUOTATION_SUCCESS = 'CREATE_TRUKCING_QUOTATION_SUCCESS';
export const CREATE_TRUKCING_QUOTATION_FAIL = 'CREATE_TRUKCING_QUOTATION_FAIL';

export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_LOGGED_OUT = 'SET_LOGGED_OUT';

export const SAVE_AVAILABILITY_MODAL_ACCEPTED = 'SAVE_AVAILABILITY_MODAL_ACCEPTED';

export const SAVED_QUOTATION_ID = 'SAVED_QUOTATION_ID';

export const SAVE_AS_NEW_REQ = 'SAVE_AS_NEW_REQ';
export const SAVE_AS_NEW_SUCCESS = 'SAVE_AS_NEW_SUCCESS';
export const SAVE_AS_NEW_FAIL = 'SAVE_AS_NEW_FAIL';

export const GET_UNIT_MEASUREMENT = 'GET_UNIT_MEASUREMENT'

export const MEASUREMENTS_SYSYTEM_REQ = 'MEASUREMENTS_SYSYTEM_REQ';
export const MEASUREMENTS_SYSYTEM_SUCCESS = 'MEASUREMENTS_SYSYTEM_SUCCESS';
export const MEASUREMENTS_SYSYTEM_FAIL = 'MEASUREMENTS_SYSYTEM_FAIL';

export const SET_MEASUREMENTS_SYSYTEM_REQ = 'SET_MEASUREMENTS_SYSYTEM_REQ';
export const SET_MEASUREMENTS_SYSYTEM_SUCCESS = 'SET_MEASUREMENTS_SYSYTEM_SUCCESS';
export const SET_MEASUREMENTS_SYSYTEM_FAIL = 'SET_MEASUREMENTS_SYSYTEM_FAIL';

export const MEASUREMENTS_SYSYTEM_REQ_PDF = 'MEASUREMENTS_SYSYTEM_REQ_PDF';
export const MEASUREMENTS_SYSYTEM_SUCCESS_PDF = 'MEASUREMENTS_SYSYTEM_SUCCESS_PDF';
export const MEASUREMENTS_SYSYTEM_FAIL_PDF = 'MEASUREMENTS_SYSYTEM_FAIL_PDF';

export const SET_MEASUREMENTS_SYSYTEM_REQ_PDF = 'SET_MEASUREMENTS_SYSYTEM_REQ_PDF';
export const SET_MEASUREMENTS_SYSYTEM_SUCCESS_PDF = 'SET_MEASUREMENTS_SYSYTEM_SUCCESS_PDF';
export const SET_MEASUREMENTS_SYSYTEM_FAIL_PDF = 'SET_MEASUREMENTS_SYSYTEM_FAIL_PDF';

export const SET_FAVROUITE_AIRPORT_REQ = 'SET_FAVROUITE_AIRPORT_REQ';
export const SET_FAVROUITE_AIRPORT_SUCCESS = 'SET_FAVROUITE_AIRPORT_SUCCESS';
export const SET_FAVROUITE_AIRPORT_FAIL = 'SET_FAVROUITE_AIRPORT_FAIL';

export const GET_PREFERRED_AIRPORTS_LIST = 'GET_PREFERRED_AIRPORTS_LIST';


export const GET_INCOMING_MANAULQUOTE_DETAILS_REQ = 'GET_INCOMING_MANAULQUOTE_DETAILS_REQ'
export const GET_INCOMING_MANAULQUOTE_DETAILS_SUCCESS = 'GET_INCOMING_MANAULQUOTE_DETAILS_SUCCESS'
export const GET_INCOMING_MANAULQUOTE_DETAILS_FAIL = 'GET_INCOMING_MANAULQUOTE_DETAILS_FAIL'


export const GET_INCOMING_AGENT_LIST_REQ = 'GET_INCOMING_AGENT_LIST_REQ';
export const GET_INCOMING_AGENT_LIST_SUCCESS = 'GET_INCOMING_AGENT_LIST_SUCCESS';
export const GET_INCOMING_AGENT_LIST_FAIL = 'GET_INCOMING_AGENT_LIST_FAIL';


export const RENEW_ADD_ON_REQ = 'RENEW_ADD_ON_REQ';
export const RENEW_ADD_ON_SUCCESS = 'RENEW_ADD_ON_SUCCESS';
export const RENEW_ADD_ON_FAIL = 'RENEW_ADD_ON_FAIL';

export const SAVE_ADDONS_DATA = 'SAVE_ADDONS_DATA';
export const SAVE_TRANSACTION_ID = 'SAVE_TRANSACTION_ID';
export const PAYPAL_STATUS = 'PAYPAL_STATUS';

export const HANDLE_BACK_BUTTON_IN_KEYMANAGEMENT = 'HANDLE_BACK_BUTTON_IN_KEYMANAGEMENT';

export const GET_HOST_REASSIGN_DETAILS_REQ = 'GET_HOST_REASSIGN_DETAILS_REQ';
export const GET_HOST_REASSIGN_DETAILS_SUCCESS = 'GET_HOST_REASSIGN_DETAILS_SUCCESS';
export const GET_HOST_REASSIGN_DETAILS_FAIL = 'GET_HOST_REASSIGN_DETAILS_FAIL';

export const GET_QUOTE_SORT_STATUS = 'GET_QUOTE_SORT_STATUS';
export const GET_QUOTE_SORT_DATA = 'GET_QUOTE_SORT_DATA';

export const GET_QUOTE_SEARCH_RESULT = 'GET_QUOTE_SEARCH_RESULT';