import {
  GET_NOTIFICATIONS_REQ,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_COUNT_REQ,
  GET_NOTIFICATIONS_COUNT_SUCCESS,
  GET_NOTIFICATIONS_COUNT_FAIL,
  NOTIF_MARK_AS_READ_REQ,
  NOTIF_MARK_AS_READ_SUCCESS,
  NOTIF_MARK_AS_READ_FAIL,
  NOTIF_MAINMENU_COUNT_DATA_SUCCESS,
  NOTIF_MAINMENU_COUNT_DATA_FAIL,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import { toast } from 'react-toastify';

export const getAllNotifications = () => async (dispatch) => {
  try {
    dispatch({ type: GET_NOTIFICATIONS_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/notification/list`, config);

    if (response.status === 200) {
      dispatch({ type: GET_NOTIFICATIONS_SUCCESS, payload: response.data });
      dispatch(getNotificationsCount());
    }
  } catch (error) {
    dispatch({ type: GET_NOTIFICATIONS_FAIL });
    // toast.error('Something went wrong!');
    dispatch(getNotificationsCount());
  }
};

export const getNotificationsCount = () => async (dispatch) => {
  try {
    dispatch({ type: GET_NOTIFICATIONS_COUNT_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/notification/count`, config);

    if (response.status === 200) {
      dispatch({
        type: GET_NOTIFICATIONS_COUNT_SUCCESS,
        payload: response.data.notificationCount,
      });
    }
  } catch (error) {
    dispatch({ type: GET_NOTIFICATIONS_COUNT_FAIL });
    // toast.error('Something went wrong!');
  }
};

export const markAsReadNotification = (data) => async (dispatch) => {
  try {
    dispatch({ type: NOTIF_MARK_AS_READ_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(`/notification/mark-as-read`, data, config);

    if (response.status === 200) {
      dispatch({
        type: NOTIF_MARK_AS_READ_SUCCESS,
      });
      dispatch(getNotificationsCount());
      dispatch(getAllNotifications());
    }
  } catch (error) {
    dispatch({ type: NOTIF_MARK_AS_READ_FAIL });
    toast.error('Something went wrong!');
  }
};

export const markAsReadPerNotification = (data) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'PUT',
        url: `/notification/mark-as-read/${data}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (res.status === 200) {
        dispatch({
          type: NOTIF_MARK_AS_READ_SUCCESS,
        });
        dispatch(getNotificationsCount());
        dispatch(getAllNotifications());
        dispatch(notificationMenuCountData());
      }

    } catch (err) {
      dispatch({ type: NOTIF_MARK_AS_READ_FAIL });
      toast.error('Something went wrong!');
    }
  };
};

export const markAsNotificationListMenuSubMenu = (menu, subMenu, _ID, role) => {
  return async (dispatch, getState) => {
    // http://localhost:8080/api/updateStatus/{companyId}
    try {
      const res = await jwtAxios({
        method: 'PUT',
        url: `/notificationList/menu-sub-menu?menu=${menu}&subMenu=${subMenu}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (res.status === 200) {
        if (role != 'ffClient') {
          dispatch(markAsNotificationListMenuSubMenuGridUpdate(_ID));
        }
        else {
          dispatch(getNotificationsCount());
          dispatch(getAllNotifications());
          dispatch(notificationMenuCountData());
        }
      }

    } catch (err) {
      // toast.error('Something went wrong!');
    }
  };
};

export const markAsNotificationListMenuSubMenuGridUpdate = (companyId) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'PUT',
        url: `/updateStatus/${companyId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (res.status === 200) {
        dispatch(getNotificationsCount());
        dispatch(getAllNotifications());
        dispatch(notificationMenuCountData());
      }

    } catch (err) {
      toast.error('Something went wrong!');
    }
  };
};

export const notificationMenuCountData = () => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'GET',
        url: `notification/menu-count`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (res.status === 200) {
        dispatch({
          type: NOTIF_MAINMENU_COUNT_DATA_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (err) {
      dispatch({
        type: NOTIF_MAINMENU_COUNT_DATA_FAIL,
      });
    }
  };
};